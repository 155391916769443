import { Button, Flex, Radio, Row, Typography } from "antd"
import React, { FC, useContext, useState } from "react"
import { CampaignsContext } from "src/components/app/context/CampaignsContext"
import { AddNumberModal } from "src/components/pages/phone-calls"
import { ConfirmButtons, Modal, RadioContentWithTooltip, StatusTag } from "src/components/ui"
import { App } from "src/types"

type CallSetupModalProps = {
  open: boolean
  onCancel: VoidFunction
  data: App.Action[]
}

export const CallSetupModal: FC<CallSetupModalProps> = ({ open, onCancel, data }) => {
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(null)
  const [openAddNumberModal, setOpenAddNumberModal] = useState(false)
  const { setOpenParallelDialerModal } = useContext(CampaignsContext)
  const closeModal = () => {
    setSelectedPhoneNumber(null)
    onCancel()
  }

  const showAddNumberModal = () => {
    setOpenAddNumberModal(true)
  }

  const handleCloseAddNumberModal = () => {
    setOpenAddNumberModal(false)
  }

  const handleFormSubmit = () => {
    setOpenParallelDialerModal(true)
    closeModal()
  }

  const isButtonDisabled = !selectedPhoneNumber

  return (
    <Modal width={530} open={open} title={"Call Setup Options"} onCancel={closeModal}>
      <Flex vertical>
        <Typography.Title level={4} style={{ marginBottom: 16 }}>
          Select Phone Number
        </Typography.Title>
        <Typography.Title level={5} style={{ marginBottom: 4 }}>
          From Connected Phones numbers
        </Typography.Title>
        {data ? (
          <Radio.Group
            value={selectedPhoneNumber}
            onChange={e => setSelectedPhoneNumber(e.target.value)}
            style={{ display: "flex", flexDirection: "column" }}
          >
            {data.map((item, index) => {
              return (
                <Row key={`${index}-${item.id}`} justify={"space-between"} align={"middle"}>
                  <Radio value={item.contact}>{item.contact}</Radio>
                  {item.connected && (
                    <StatusTag value={"Used to this campaign"} color={"success"} style={{ margin: 0, padding: "6px 16px" }} />
                  )}
                </Row>
              )
            })}
          </Radio.Group>
        ) : (
          <Typography.Title level={5} style={{ fontWeight: 400 }}>
            No phone number connected.
          </Typography.Title>
        )}
        <Typography.Title level={4} style={{ margin: "16px 0" }}>
          Or
        </Typography.Title>
        <Button className={"link"} onClick={showAddNumberModal} style={{ textAlign: "start", marginBottom: 28 }}>
          Add Phone Number
        </Button>
        <Typography.Title level={4} style={{ marginBottom: 16 }}>
          Select call method
        </Typography.Title>
        <Radio.Group style={{ display: "flex", flexDirection: "column", marginBottom: 28 }}>
          <Radio value={"AI-Assisted Call Check"} style={{ marginBottom: 8 }}>
            <RadioContentWithTooltip
              radioTitle={"AI-Assisted Call Check"}
              tooltipTitle={"This option uses AI to determine if a human answered, with a slight delay to connect."}
            />
          </Radio>
          <Radio value={"Instant Call Connection"}>
            <RadioContentWithTooltip
              radioTitle={"Instant Call Connection"}
              tooltipTitle={"This option connects the call immediately once someone answers, without any delay."}
            />
          </Radio>
        </Radio.Group>
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Start Dialer"}
          onCancel={closeModal}
          handleSubmit={handleFormSubmit}
          disabled={isButtonDisabled}
        />
      </Flex>
      <AddNumberModal open={openAddNumberModal} onCancel={handleCloseAddNumberModal} />
    </Modal>
  )
}
