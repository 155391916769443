import { Row, Typography, message } from "antd"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import PageLayout from "src/components/layout/PageLayout"
import { ConfirmButtons } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { EditProspects } from "./ui/EditProspects"
import { EditSteps } from "./ui/EditSteps"

export const FromSaved: FC = () => {
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [showEditProspects, setShowEditProspects] = useState(true)

  const success = () => {
    void messageApi.open({
      type: "success",
      content: "New campaign created",
    })
  }

  const rightHeaderContent = (
    <ConfirmButtons
      width={showEditProspects ? 130 : 250}
      leftButtonTitle={showEditProspects ? null : "Back"}
      rightButtonTitle={showEditProspects ? "Create" : "Activate Campaign"}
      handleSubmit={showEditProspects ? () => setShowEditProspects(false) : () => success()}
      onCancel={() => setShowEditProspects(true)}
    />
  )

  return (
    <PageLayout
      pageTitle={
        <Row align={"middle"} style={{ gap: 16 }}>
          <SvgIcon type={"arrowBack"} onClick={() => navigate("/campaigns")} style={{ cursor: "pointer" }} />
          <Typography.Title level={2} className={"page-title"}>
            {"Edit Prospects"}
          </Typography.Title>
        </Row>
      }
      rightHeaderContent={rightHeaderContent}
      isTableContent={false}
    >
      {contextHolder}
      {showEditProspects ? <EditProspects /> : <EditSteps />}
    </PageLayout>
  )
}
