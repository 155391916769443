import { Row } from "antd"
import React, { FC } from "react"
import { StatusTag } from "src/components/ui"

interface HeaderTagsBarProps {
  totalAmount: number
  selectedAmount: number
}

export const HeaderTagsBar: FC<HeaderTagsBarProps> = ({ totalAmount, selectedAmount }) => {
  return (
    <Row style={{ gap: 16 }} wrap={false}>
      <StatusTag value={"Total amount of prospects"} color={"default"} count={totalAmount ?? "-"} />
      <StatusTag value={"Amount of prospects selected"} color={"processing"} count={selectedAmount ?? "-"} />
    </Row>
  )
}
