import { Flex, Pagination, Table as AntTable, TableProps, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC, useEffect, useState } from "react"
import SkeletonTable, { SkeletonTableColumnsType } from "./SkeletonWrapper"
import { SvgIcon } from "../../../shared/icons"
import { CustomSelect } from "../CustomSelect"
import "./index.less"

interface CustomTableProps extends TableProps {
  dataSource: any
  skeletonSelection?: boolean
  customClassName?: string
  loading?: boolean
  columns: ColumnsType<any>
  pageSize?: number
}

export const Table: FC<CustomTableProps> = ({
  dataSource,
  customClassName,
  columns,
  skeletonSelection,
  loading,
  pageSize: customPageSize,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(customPageSize || 10);
  const [totalPages, setTotalPages] = useState((dataSource?.length <= pageSize ? 1 : Math.ceil(dataSource?.length / pageSize)) || 1)
  const [paginatedData, setPaginatedData] = useState<any[]>([])

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    setPaginatedData(dataSource?.slice(startIndex, endIndex) || []);
  }, [currentPage, pageSize, dataSource]);

  useEffect(() => {
    setTotalPages((dataSource.length <= 10 ? 1 : Math.ceil(dataSource.length / pageSize)) || 1)
  }, [dataSource, pageSize])

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const handleSuperPrevBtnClick = () => {
    setCurrentPage(1)
  }

  const handleSuperNextBtnClick = () => {
    setCurrentPage(totalPages)
  }

  const pagesToJumpOptions = Array.from({ length: totalPages }, (_, index) => ({
    label: `${index + 1}`,
    value: `${index + 1}`,
  }));

  return (
    <SkeletonTable rowSelection={skeletonSelection} loading={loading} columns={columns as SkeletonTableColumnsType[]}>
      <AntTable
        className={`custom-table ${customClassName}`}
        rowKey={"id"}
        pagination={false}
        dataSource={paginatedData}
        columns={columns}
        {...props}
      />
      {dataSource?.length > 10 && (
        <Flex justify={"space-between"} align={"center"} className={"table-footer-wrapper"}>
          <Flex gap={5} align={"center"}>
            <Flex
              className={`pagination-super-arrow-wrapper ${currentPage === 1 && "disabled"}`}
              justify={"center"}
              align={"center"}
              onClick={handleSuperPrevBtnClick}
            >
              <SvgIcon type={"paginationSuperArrowLeft"} width={16} height={16} />
            </Flex>
            <Pagination
              showLessItems={true}
              showSizeChanger={false}
              onChange={handlePageChange}
              current={currentPage}
              pageSize={pageSize}
              total={dataSource.length || 0}
              prevIcon={(
                <Flex className={"pagination-arrow-wrapper"} justify={"center"} align={"center"}>
                  <SvgIcon type={"paginationArrowLeft"} width={16} height={16} />
                </Flex>
              )}
              nextIcon={(
                <Flex className={"pagination-arrow-wrapper"} justify={"center"} align={"center"}>
                  <SvgIcon type={"paginationArrowRight"} width={16} height={16} />
                </Flex>
              )}
              className={"pagination"}
            />
            <Flex
              className={`pagination-super-arrow-wrapper ${currentPage === totalPages && "disabled"}`}
              justify={"center"}
              align={"center"}
              onClick={handleSuperNextBtnClick}
            >
              <SvgIcon type={"paginationSuperArrowRight"} width={16} height={16} />
            </Flex>
          </Flex>
          <Flex className={"jump-to-page-wrapper"} align={"center"} gap={10}>
            <Typography.Paragraph className={"jump-to-page-text"}>Page</Typography.Paragraph>
            <CustomSelect
              className={"jump-to-page-select"}
              options={pagesToJumpOptions || []}
              value={currentPage.toString()}
              onChange={(value) => setCurrentPage(Number(value))}
            />
            <Typography.Paragraph className={"jump-to-page-text"}>of {totalPages}</Typography.Paragraph>
          </Flex>
        </Flex>
      )}
    </SkeletonTable>
  )
}
