import { Button, Checkbox, Flex, Input, Radio, Row, Typography } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, Modal, SignInWithButtons, StatusTag } from "src/components/ui"
import { App } from "src/types"
import "./index.less"
import TextArea from "antd/es/input/TextArea"

type SelectEmailModalProps = {
  open: boolean
  onCancel: VoidFunction
  data: App.Action[]
}

export const SelectEmailModal: FC<SelectEmailModalProps> = ({ open, onCancel, data }) => {
  const [currentStep, setCurrentStep] = useState<number>(1)

  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    console.log("Created")
    onCancel()
  }

  return currentStep === 1 ? (
    <Modal width={530} open={open} title={"Select email addresses"} className={"select-email-modal"} onCancel={closeModal}>
      <Flex vertical>
        <Row justify={"space-between"} style={{ marginBottom: 16 }}>
          <Typography.Title level={4}>Select from connected emails</Typography.Title>
          <Button className={"link"} style={{ fontSize: 12, marginRight: 8 }}>Manage</Button>
        </Row>
        <Radio.Group style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          {data.map(item => {
            return (
              <Row key={item.id} justify={"space-between"} align={"middle"}>
                <Radio value={item.contact}>{item.contact}</Radio>

                <StatusTag
                  value={item.connected ? "Connected" : "Disconnected"}
                  color={item.connected ? "success" : "error"}
                  style={{ margin: 0, padding: "6px 16px" }}
                />
              </Row>
            )
          })}
        </Radio.Group>
        <Typography.Title level={4} style={{ margin: "28px 0" }}>
          Or
        </Typography.Title>
        <SignInWithButtons title={"Connect Email With"} />
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Continue"}
          style={{ marginTop: 28, gap: 45 }}
          onCancel={closeModal}
          handleSubmit={() => setCurrentStep(2)}
        />
      </Flex>
    </Modal>
  ) : (
    <Modal width={530} open={open} title={"Send Email"} className={"send-email-modal"} onCancel={closeModal}>
      <Flex vertical>
        <Typography.Title level={3} className={"subtitle"}>Select the recipient</Typography.Title>
        <Typography.Title level={4} className={"label"} style={{ marginTop: 0 }}>From Existing Options</Typography.Title>
        <Checkbox.Group options={data.map(option => ({ label: option.contact, value: option.id }))} className={"emails-checkbox-group"}/>
        <Typography.Paragraph style={{ margin: "16px 0", fontSize: 12 }}>Or</Typography.Paragraph>
        <Flex justify={"space-between"}>
          <Typography.Title level={4} className={"label"} style={{ marginTop: 0 }}>Enter Email Address</Typography.Title>
          <Button className={"link"} style={{ fontSize: 12 }}>Add</Button>
        </Flex>
        <Input placeholder={"Enter Email"} />
        <Typography.Title level={3} className={"subtitle"} style={{ marginTop: 28 }}>Email Body</Typography.Title>
        <TextArea rows={6} className={"email-body"} placeholder={"Enter the message"} />
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Send Email"}
          className={"second-step-confirm-buttons"}
          style={{ marginTop: 28, gap: 45 }}
          onCancel={() => setCurrentStep(1)}
          handleSubmit={handleFormSubmit}
        />
      </Flex>
    </Modal>
  )
}
