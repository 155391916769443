import React, { ForwardedRef, forwardRef, useState } from "react"
import { Button, Col, Flex, Form, Row, Segmented, Typography } from "antd";
import PageLayout from "src/components/layout/PageLayout";
import { ExploreTable, ExportsTable, ProspectFilterCollapse } from "src/components/pages/prospect-data";
import { useBreakpoints } from "../../helpers/useBreakpoints"
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal"
import "./index.less";

interface ProspectDataProps {
  isTourStep?: boolean;
  ref?: ForwardedRef<HTMLDivElement>;
}

const ProspectData = forwardRef<HTMLDivElement, ProspectDataProps>(({ isTourStep }, ref) => {
  const { isDesktop } = useBreakpoints()
  const [activeTab, setActiveTab] = useState<string>("Explore");
  const [form] = Form.useForm();

  const isExplore = activeTab === "Explore";

  const bottomHeaderContent = (
    <>
      <Segmented options={["Explore", "My exports"]} value={activeTab} onChange={setActiveTab} />
      <Row align={"middle"} style={{ gap: 34 }}>
        <Typography.Paragraph className={"credits-left-title"}>Credits Left: 5</Typography.Paragraph>
        <Button className={"primary"}>Test</Button>
      </Row>
    </>
  );

  return isDesktop ? (
    <div>
      <PageLayout pageTitle={"Prospect Data"} bottomHeaderContent={bottomHeaderContent} isTableContent={false}>
        <Row style={{ gap: 24 }} wrap={false}>
          {isExplore && (
            <Col flex={"344px"}>
              <ProspectFilterCollapse ref={ref} form={form} isTourStep={isTourStep} />
            </Col>
          )}
          <Flex className={"table-container"}>
            <Col flex={isExplore ? "auto" : "100%"}>{isExplore ? <ExploreTable isTourStep={isTourStep} /> : <ExportsTable />}</Col>
          </Flex>
        </Row>
      </PageLayout>
    </div>
  ) : (
    <GoToDesktopModal />
  )
});

export default ProspectData;
