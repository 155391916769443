import { Button, Flex, Form, Input, TimePicker, Typography, notification } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { FC, useContext, useEffect, useState } from "react"
import { Collapse, Drawer } from "src/components/ui"
import { generateRandomDigits, saveToClipboard } from "../../../../../helpers"
import { SvgIcon } from "../../../../../shared/icons"
import { MeetingLinksContext } from "../../../../app/context/MeetingLinksContext"
import { CustomSelect } from "../../../../ui"
import { WeekTimePlannerWithTabs } from "../../../../ui/WeekTimePlannerWithTabs"
import { AddVariableModal } from "../../../campaigns/CreateCampaign/ui/AIGenerated/ui/StepThree/ui"
import "./index.less"

interface MeetingLinkDrawerProps {
  isNewMeeting?: boolean
  onClose: () => void
}

const reminderTimeOptions = [
  {
    label: "None",
    value: "None",
  },
  {
    label: "15 Min",
    value: "15 Min",
  },
  {
    label: "30 Min",
    value: "30 Min",
  },
  {
    label: "1 Hour",
    value: "1 Hour",
  },
  {
    label: "2 Hours",
    value: "2 Hours",
  },
  {
    label: "3 Hours",
    value: "3 Hours",
  },
  {
    label: "8 Hours",
    value: "8 Hours",
  },
  {
    label: "1 Day",
    value: "1 Day",
  },
  {
    label: "2 Days",
    value: "2 Days",
  },
]

const timezoneOptions = [
  {
    label: "American/New_York EST",
    value: "American/New_York EST",
  },
  {
    label: "Option 2",
    value: "Option 2",
  },
  {
    label: "Option 3",
    value: "Option 3",
  },
]

const maximumDaysToScheduleForwardOptions = [
  {
    label: "No Max Time",
    value: "No Max Time",
  },
  {
    label: "3 Days",
    value: "3 Days",
  },
  {
    label: "4 Days",
    value: "4 Days",
  },
  {
    label: "5 Days",
    value: "5 Days",
  },
  {
    label: "6 Days",
    value: "6 Days",
  },
  {
    label: "7 Days",
    value: "7 Days",
  },
  {
    label: "8 Days",
    value: "8 Days",
  },
  {
    label: "9 Days",
    value: "9 Days",
  },
  {
    label: "10 Days",
    value: "10 Days",
  },
  {
    label: "15 Days",
    value: "15 Days",
  },
  {
    label: "30 Days",
    value: "30 Days",
  },
  {
    label: "60 Days",
    value: "60 Days",
  },
  {
    label: "120 Days",
    value: "120 Days",
  },
  {
    label: "180 Days",
    value: "180 Days",
  },
  {
    label: "1 Year",
    value: "1 Year",
  },
]

const minimumTimeToBookInAdvanceOptions = [
  {
    label: "0 Min",
    value: "0 Min",
  },
  {
    label: "1 Min",
    value: "1 Min",
  },
  {
    label: "5 Min",
    value: "5 Min",
  },
  {
    label: "10 Min",
    value: "10 Min",
  },
  {
    label: "15 Min",
    value: "15 Min",
  },
  {
    label: "30 Min",
    value: "30 Min",
  },
  {
    label: "45 Min",
    value: "45 Min",
  },
  {
    label: "1 Hour",
    value: "1 Hour",
  },
  {
    label: "2 Hours",
    value: "2 Hours",
  },
  {
    label: "3 Hours",
    value: "3 Hours",
  },
  {
    label: "4 Hours",
    value: "4 Hours",
  },
  {
    label: "5 Hours",
    value: "5 Hours",
  },
  {
    label: "6 Hours",
    value: "6 Hours",
  },
  {
    label: "7 Hours",
    value: "7 Hours",
  },
  {
    label: "8 Hours",
    value: "8 Hours",
  },
  {
    label: "9 Hours",
    value: "9 Hours",
  },
  {
    label: "10 Hours",
    value: "10 Hours",
  },
  {
    label: "11 Hours",
    value: "11 Hours",
  },
  {
    label: "12 Hours",
    value: "12 Hours",
  },
  {
    label: "13 Hours",
    value: "13 Hours",
  },
  {
    label: "14 Hours",
    value: "14 Hours",
  },
  {
    label: "15 Hours",
    value: "15 Hours",
  },
  {
    label: "16 Hours",
    value: "16 Hours",
  },
  {
    label: "17 Hours",
    value: "17 Hours",
  },
  {
    label: "18 Hours",
    value: "18 Hours",
  },
  {
    label: "19 Hours",
    value: "19 Hours",
  },
  {
    label: "20 Hours",
    value: "20 Hours",
  },
  {
    label: "21 Hours",
    value: "21 Hours",
  },
  {
    label: "22 Hours",
    value: "22 Hours",
  },
  {
    label: "23 Hours",
    value: "23 Hours",
  },
  {
    label: "24 Hours",
    value: "24 Hours",
  },
]

const bufferTimeBetweenMeetings = [
  {
    label: "0 Min",
    value: "0 Min",
  },
  {
    label: "1 Min",
    value: "1 Min",
  },
  {
    label: "5 Min",
    value: "5 Min",
  },
  {
    label: "10 Min",
    value: "10 Min",
  },
  {
    label: "15 Min",
    value: "15 Min",
  },
  {
    label: "30 Min",
    value: "30 Min",
  },
  {
    label: "45 Min",
    value: "45 Min",
  },
  {
    label: "1 Hour",
    value: "1 Hour",
  },
  {
    label: "2 Hours",
    value: "2 Hours",
  },
  {
    label: "3 Hours",
    value: "3 Hours",
  },
]

const questionRequiredOptions = [
  {
    label: "Required",
    value: "Required",
  },
  {
    label: "Not Required",
    value: "Not Required",
  },
]

const questionTypeOptions = [
  {
    label: "Opened Question",
    value: "Opened Question",
  },
  {
    label: "Multiple Choice Question",
    value: "Multiple Choice Question",
  },
]

export const MeetingLinkDrawer: FC<MeetingLinkDrawerProps> = ({ isNewMeeting, onClose }) => {
  const { meetingLinks, selectedMeetingLinkId, addMeetingLink, editMeetingLink } = useContext(MeetingLinksContext)
  const [meetingLinkData, setMeetingLinkData] = useState<any>(
    isNewMeeting
      ? {
          id: generateRandomDigits(10),
          name: "Intro Meeting",
          description: "Meeting powered by Meetz.Ai",
          tags: [],
          links: [
            {
              text: "",
              href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
            },
          ],
          reminders: {},
          meetingLength: "15 Min",
          timezone: "American/New_York EST",
          maximumDaysToScheduleForward: "15 Days",
          minimumTimeToBookInAdvance: "0 Min",
          bufferTimeBetweenMeetings: "0 Min",
          isOutlined: false,
        }
      : meetingLinks.find(el => el.id === selectedMeetingLinkId)
  )
  const [questionsForGuests, setQuestionsForGuests] = useState(
    meetingLinkData.questionsForGuests || [
      {
        id: generateRandomDigits(10),
        text: "",
        type: "Opened Question",
        choices: [
          {
            id: generateRandomDigits(10),
            text: "",
          },
        ],
        isRequired: false,
      },
    ]
  )
  const [form] = Form.useForm()
  const [isAddVariableModalOpened, setIsAddVariableModalOpened] = useState(false)

  useEffect(() => {
    if (meetingLinks && !isNewMeeting) {
      const selectedMeetingLink = meetingLinks.find(el => el.id === selectedMeetingLinkId)
      if (selectedMeetingLink) {
        setMeetingLinkData(selectedMeetingLink)
      }
    }
  }, [meetingLinks, selectedMeetingLinkId])

  const openAddVariableModal = () => {
    setIsAddVariableModalOpened(true)
  }

  const closeAddVariableModal = () => {
    setIsAddVariableModalOpened(false)
  }

  const reminderCollapseItemMarkup = (index: number) => (
    <Flex vertical gap={11} key={generateRandomDigits(10)}>
      <Form.Item name={["reminders", `reminder${index}`, "time"]} initialValue={"15 Min"}>
        <CustomSelect options={reminderTimeOptions} />
      </Form.Item>
      <Flex vertical gap={8}>
        <Flex justify={"space-between"} align={"center"}>
          <Typography.Paragraph className={"label"}>Text of Reminders</Typography.Paragraph>
          <Typography.Paragraph className={"variable"} onClick={openAddVariableModal}>
            Variable
          </Typography.Paragraph>
        </Flex>
        <Form.Item name={["reminders", `reminder${index}`, "text"]} initialValue={""}>
          <TextArea placeholder={"Enter the text of reminder"} />
        </Form.Item>
      </Flex>
      <Flex vertical gap={8}>
        <Typography.Paragraph className={"label"}>Example</Typography.Paragraph>
        <TextArea
          value={
            "Hi Iron, our meeting is coming up in 2 hours here is the meeting link: App.Meetz.Ai/Cal/Tom-reactivelink-2282."
          }
          disabled
        />
      </Flex>
    </Flex>
  )

  const defaultReminders = [
    {
      key: "1",
      label: "Reminder 1",
      children: reminderCollapseItemMarkup(1),
    },
    {
      key: "2",
      label: "Reminder 2",
      children: reminderCollapseItemMarkup(2),
    },
  ]

  const [reminders, setReminders] = useState(defaultReminders)
  const [remindersActiveKeys, setRemindersActiveKeys] = useState<string[]>(defaultReminders.map(item => item.key))

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then(values => {
        if (!isNewMeeting) {
          editMeetingLink({
            ...meetingLinkData,
            ...values,
            questionsForGuests,
            tags: meetingLinkData.tags.map((tag: any) =>
              tag.type === "Meeting Length"
                ? { text: values.meetingLength, color: "Green", type: "Meeting Length" }
                : tag
            ),
          })
        } else {
          addMeetingLink({
            ...meetingLinkData,
            ...values,
            questionsForGuests,
            tags: [{ text: values.meetingLength, color: "Green", type: "Meeting Length" }],
            links: [{ text: "Copy link", href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}` }],
          })
        }
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
    onClose()
  }

  const handleResetBtnClick = () => {
    form.resetFields()
  }

  const handleAddVariableSubmit = (values: any) => {
    console.log(values)
  }

  const handleCopyMeetingLinkBtnClick = () => {
    if (meetingLinkData) {
      saveToClipboard(form.getFieldValue("link"))
      notification.info({
        message: "Copied",
        description: false,
        duration: 5,
        icon: <SvgIcon type={"infoFilled"} />,
        closeIcon: false,
        placement: "bottomLeft",
      })
    }
  }

  const handleAddReminder = () => {
    const newReminderIndex = reminders.length + 1
    const newReminder = {
      key: newReminderIndex.toString(),
      label: `Reminder ${newReminderIndex}`,
      children: reminderCollapseItemMarkup(newReminderIndex),
    }
    setReminders(prevReminders => [...prevReminders, newReminder])
    setRemindersActiveKeys(prevActiveKeys => [...prevActiveKeys, newReminder.key])
  }

  const handleQuestionInputChange = (questionId: string, e: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? {
            ...question,
            text: e.target.value,
          }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleAddQuestionBtnClick = () => {
    setQuestionsForGuests((prevState: any) => [
      ...prevState,
      {
        id: generateRandomDigits(10),
        text: "",
        type: "Opened Question",
        choices: [{ id: generateRandomDigits(10), text: "" }],
        isRequired: false,
      },
    ])
  }

  const handleIsRequiredQuestionChange = (questionId: string, value: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId ? { ...question, isRequired: value === "Required" } : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleQuestionTypeChange = (questionId: string, value: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId ? { ...question, type: value } : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleAddChoiceBtnClick = (questionId: string) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? { ...question, choices: [...question.choices, { id: generateRandomDigits(10), text: "" }] }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleDeleteChoiceBtnClick = (questionId: string, choiceId: string) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? { ...question, choices: question.choices.filter((choice: any) => choice.id !== choiceId) }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  const handleChoiceInputChange = (questionId: string, choiceId: string, e: any) => {
    const updatedQuestions = questionsForGuests.map((question: any) =>
      question.id === questionId
        ? {
            ...question,
            choices: question.choices.map((choice: any) =>
              choice.id === choiceId ? { ...choice, text: e.target.value } : choice
            ),
          }
        : question
    )
    setQuestionsForGuests(updatedQuestions)
  }

  return (
    <>
      <Drawer
        open
        title={isNewMeeting ? "Add Meeting Link" : "Edit Meeting Link"}
        leftButtonTitle={"Reset all"}
        rightButtonTitle={"Save"}
        onBtnPrimaryClick={handleFormSubmit}
        onBtnSecondaryClick={handleResetBtnClick}
        onCancel={onClose}
        rootClassName={"MeetingLinkDrawer"}
      >
        <Form form={form} layout={"vertical"} className={"edit-meeting-link-form"} initialValues={meetingLinkData}>
          <Flex vertical gap={24}>
            <Flex vertical gap={8}>
              <Flex justify={"space-between"} align={"center"}>
                <Typography.Paragraph className={"label"}>Meeting Link</Typography.Paragraph>
                <Button
                  className={"copy-btn"}
                  icon={<SvgIcon type={"copy"} />}
                  onClick={handleCopyMeetingLinkBtnClick}
                />
              </Flex>
              <Flex align={"center"} gap={8}>
                <Typography.Paragraph className={"label"} style={{ flexShrink: 0 }}>App.Meetz.Ai/Cal/</Typography.Paragraph>
                <Form.Item
                  name={"link"}
                  initialValue={meetingLinkData.links[0].href.replace("App.Meetz.Ai/Cal/", "")}
                  style={{ width: "100%" }}
                >
                  <Input placeholder={"Enter the path"} />
                </Form.Item>
              </Flex>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Meeting name</Typography.Paragraph>
              <Form.Item name={"name"}>
                <Input placeholder={"Enter the meeting name"} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Meeting description</Typography.Paragraph>
              <Form.Item name={"description"}>
                <TextArea rows={1} placeholder={"Enter the meeting description"} />
              </Form.Item>
            </Flex>
            <Flex vertical>
              <Form.Item name={"reminders"}>
                <Collapse
                  items={reminders}
                  activeKey={remindersActiveKeys}
                  onChange={keys => setRemindersActiveKeys(keys as string[])}
                />
              </Form.Item>
              <Button className={"link"} onClick={handleAddReminder}>
                Add Remind
              </Button>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Meeting Length</Typography.Paragraph>
              <Form.Item name={"meetingLength"}>
                <CustomSelect options={reminderTimeOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Timezone</Typography.Paragraph>
              <Form.Item name={"timezone"}>
                <CustomSelect options={timezoneOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <WeekTimePlannerWithTabs formItemName={["availability"]} className={"meeting-link-week-planner"} />
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Maximum Timeslots to offer per day</Typography.Paragraph>
              <Form.Item name={"maximumTimeslotsToOfferPerDay"}>
                <TimePicker.RangePicker
                  className={"time-picker"}
                  format={"h:mm"}
                  placeholder={["No Max time", "No Max time"]}
                />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Maximum Days To Schedule Forward</Typography.Paragraph>
              <Form.Item name={"maximumDaysToScheduleForward"}>
                <CustomSelect options={maximumDaysToScheduleForwardOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Minimum Time To Book In Advance</Typography.Paragraph>
              <Form.Item name={"minimumTimeToBookInAdvance"}>
                <CustomSelect options={minimumTimeToBookInAdvanceOptions} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Buffer Time Between Meetings</Typography.Paragraph>
              <Form.Item name={"bufferTimeBetweenMeetings"}>
                <CustomSelect options={bufferTimeBetweenMeetings} />
              </Form.Item>
            </Flex>
            <Flex vertical gap={8}>
              <Typography.Paragraph className={"label"}>Questions for guests</Typography.Paragraph>
              <Flex vertical={true} gap={16}>
                {questionsForGuests.map((question: any, index: number) => (
                  <Flex vertical={true} gap={8}>
                    <Flex align={"center"}>
                      <Typography.Paragraph className={"question-count"}>{index + 1} Question</Typography.Paragraph>
                      <Flex gap={8} style={{ marginLeft: "auto" }}>
                        <CustomSelect
                          options={questionRequiredOptions}
                          defaultValue={question.isRequired ? "Required" : "Not Required"}
                          className={"question-select"}
                          onChange={value => handleIsRequiredQuestionChange(question.id, value)}
                        />
                        <CustomSelect
                          options={questionTypeOptions}
                          defaultValue={question.type || "Opened Question"}
                          className={"question-select"}
                          onChange={value => handleQuestionTypeChange(question.id, value)}
                        />
                      </Flex>
                    </Flex>
                    {question.type === "Opened Question" && (
                      <Input defaultValue={question.text || ""} placeholder={"Enter the question"} />
                    )}
                    {question.type === "Multiple Choice Question" && (
                      <>
                        <Flex vertical={true} gap={4}>
                          <Typography.Paragraph className={"small-label"}>Title</Typography.Paragraph>
                          <Input
                            defaultValue={question.text || ""}
                            placeholder={"Title"}
                            onChange={e => handleQuestionInputChange(question.id, e)}
                          />
                        </Flex>
                        <Flex vertical={true} gap={4} style={{ width: "100%" }}>
                          <Flex justify={"space-between"} align={"center"}>
                            <Typography.Paragraph className={"small-label"}>Choices</Typography.Paragraph>
                            <Button className={"question-btn"} onClick={() => handleAddChoiceBtnClick(question.id)}>
                              Add Choice
                            </Button>
                          </Flex>
                          {question.choices.map((choice: any) => (
                            <Flex align={"center"} gap={20}>
                              <Input
                                defaultValue={choice.text || ""}
                                placeholder={"Choice"}
                                onChange={e => handleChoiceInputChange(question.id, choice.id, e)}
                              />
                              <SvgIcon
                                type={"close"}
                                className={"delete-choice-close-btn"}
                                onClick={() => handleDeleteChoiceBtnClick(question.id, choice.id)}
                              />
                            </Flex>
                          ))}
                        </Flex>
                      </>
                    )}
                  </Flex>
                ))}
              </Flex>
              <Button className={"link"} style={{ marginTop: 8 }} onClick={handleAddQuestionBtnClick}>
                Add
              </Button>
            </Flex>
          </Flex>
        </Form>
      </Drawer>
      {isAddVariableModalOpened && (
        <AddVariableModal open={true} onSubmit={handleAddVariableSubmit} onCancel={closeAddVariableModal} />
      )}
    </>
  )
}
