import Link from "@tiptap/extension-link"
import ListItem from "@tiptap/extension-list-item"
import Paragraph from "@tiptap/extension-paragraph"
import Text from "@tiptap/extension-text"
import TextAlign from "@tiptap/extension-text-align"
import TextStyle from "@tiptap/extension-text-style"
import Underline from "@tiptap/extension-underline"
import { EditorProvider } from "@tiptap/react"
import StarterKit from "@tiptap/starter-kit"
import React, { FC } from "react"
import { MenuBar } from "./MenuBar"
import "./index.less"

const extensions = [
  Underline,
  TextStyle,
  ListItem,
  Paragraph,
  Text,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
  }),
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
]

export const TextEditor: FC<{ content: any, isMenuBar?: boolean }> = ({ content, isMenuBar = true }) => {
  return (
    <EditorProvider slotAfter={isMenuBar ? <MenuBar /> : null} extensions={extensions} content={content}>
      {""}
    </EditorProvider>
  )
}