import { FormInstance, Row, Tag, Typography } from "antd"
import React, { FC, PropsWithChildren, useContext, useEffect, useState } from "react"
import { SvgIcon } from "src/shared/icons"
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext"
import "./index.less"

const FilterTag: FC<PropsWithChildren<{ onClose: VoidFunction }>> = ({ onClose, children }) => {
  return (
    <Tag closable bordered={false} closeIcon={<SvgIcon type={"closeTag"} />} onClose={onClose}>
      {children}
    </Tag>
  )
}

interface FilterTagsListProps {
  data: string[] | undefined
  fieldToUpdate?: any
  form?: FormInstance
  onTagRemove?: (tag: string) => void
  onChange?: (newTagsList: string[]) => void
}

export const FilterTagsList: FC<FilterTagsListProps> = ({ onTagRemove, data, form, fieldToUpdate, onChange }) => {
  const { setFilterFormData} = useContext(ProspectDataContext)
  const [tags, setTags] = useState<string[]>([])
  const [deletedTags, setDeletedTags] = useState<string[]>([]);

  const handleClose = (removedTag: string) => {
    if (onTagRemove) {
      onTagRemove(removedTag);
      form?.setFieldsValue({ ["firstName"]: "" })
    } else {
      const newTags = tags.filter(tag => tag !== removedTag);
      setDeletedTags(prevDeletedTags => [...prevDeletedTags, removedTag]);
      form?.setFieldValue(fieldToUpdate, newTags);
      setFilterFormData && setFilterFormData(form?.getFieldsValue());

      if (onChange) {
        onChange(newTags)
      }
    }
  };

  useEffect(() => {
    const updatedTags = data ? data.filter(tag => !deletedTags.includes(tag)) : [];
    setTags(updatedTags);
  }, [data, deletedTags]);

  return (
    <Row className={"filter-tags-list"}>
      {data && tags.map((tag, index) => {
        return !deletedTags.includes(tag) ? (
          tag ? (
            <FilterTag key={`${tag}-${index}`} onClose={() => handleClose(tag)}>
              <Typography.Paragraph ellipsis style={{maxWidth: "233px"}}>{tag}</Typography.Paragraph>
            </FilterTag>
          ) : null
        ) : null;
      })}
    </Row>
  );
}
