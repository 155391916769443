import { Button, Row, RowProps } from "antd"
import React, { FC, ForwardedRef } from "react"

interface ConfirmButtonsProps extends RowProps {
  width?: number | string
  leftButtonTitle?: string | null
  rightButtonTitle: string
  disabled?: boolean
  onCancel?: VoidFunction
  handleSubmit?: VoidFunction | undefined
  leftBtnRef?: ForwardedRef<HTMLDivElement | any>;
  rightBtnRef?: ForwardedRef<HTMLDivElement | any>;
}

export const ConfirmButtons: FC<ConfirmButtonsProps> = ({
                                                          width,
                                                          leftButtonTitle,
                                                          rightButtonTitle,
                                                          onCancel,
                                                          handleSubmit,
                                                          disabled = false,
                                                          leftBtnRef,
                                                          rightBtnRef,
                                                          ...props
                                                        }) => {
  return (
    <Row align={"middle"} style={{ width: width ? width : "100%" }} wrap={false} {...props}>
      {leftButtonTitle ? (
        <Button ref={leftBtnRef} className={"link"} onClick={onCancel} style={{ width: "100%" }}>
          {leftButtonTitle}
        </Button>
      ) : null}
      <Button
        ref={rightBtnRef}
        className={"primary"}
        htmlType={"submit"}
        style={{ width: "100%" }}
        onClick={handleSubmit}
        disabled={disabled}
      >
        {rightButtonTitle}
      </Button>
    </Row>
  )
}
