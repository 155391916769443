import { Form, FormInstance, FormItemProps, Input, message } from "antd"
import { FC, useContext } from "react"
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext"
import { FilterTagsList } from "src/components/ui"

interface InputFilterItem extends FormItemProps {
  form: FormInstance
  fieldName: string
  placeholder?: string
}

const error = () => {
  message.error("This filter is already in the list");
};

export const InputFilterItem: FC<InputFilterItem> = ({ placeholder, form, fieldName }) => {
  const { filterFormData, setFilterFormData } = useContext(ProspectDataContext)

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const trimmedValue = e.currentTarget.value.trim()
    if (e.key === "Enter" && trimmedValue !== "") {
      if (!filterFormData.firstName.includes(trimmedValue)) {
        setFilterFormData((prevFormData:any) => ({
          ...prevFormData,
          firstName: [...(prevFormData.firstName || []), trimmedValue]
        }))

        form.setFieldsValue({ [fieldName]: "" })
      } else error()
    }
  }

  return (
    <>
      <Form.Item name={fieldName}>
        <Input placeholder={placeholder} onKeyDown={handleKeyPress}/>
      </Form.Item>
      <FilterTagsList data={filterFormData?.firstName} form={form} fieldToUpdate={fieldName} />
    </>
  )
}
