import { Button, Divider, Row, Typography } from "antd"
import React, { FC } from "react"
import { SvgIcon } from "src/shared/icons"

interface ProspectFilterHeaderProps {
  openSaveModal: VoidFunction
  openFromSavedModal: VoidFunction
  totalTagsLength: any
}

export const ProspectFilterHeader: FC<ProspectFilterHeaderProps> = ({
  openSaveModal,
  openFromSavedModal,
  totalTagsLength,
}) => {
  return (
    <Row className={"prospect-filter-header"} justify={"space-between"}>
      <Row align={"middle"} style={{ gap: 8 }}>
        <SvgIcon type={"filter"} />
        <Typography.Paragraph className={"filter-header-title"}>Filters</Typography.Paragraph>
      </Row>
      <Row align={"middle"}>
        {totalTagsLength !== 0 && (
          <>
            <Button className={"link"} onClick={openSaveModal}>
              Save
            </Button>
            <Divider type={"vertical"} />
          </>
        )}
        <Button className={"link"} onClick={openFromSavedModal}>
          Apply From Saved
        </Button>
      </Row>
    </Row>
  )
}
