import React, { useState, useEffect, ReactNode } from 'react';
import Error from "src/shared/images/error.webp"
import { Flex, Typography } from "antd"
import "./index.less"

function ErrorBoundary({ children }: { children?: ReactNode }) {
  const [hasError, setHasError] = useState(false)

  useEffect(() => {
    const errorHandler = () => setHasError(true)
    window.addEventListener('error', errorHandler)
    return () => {
      window.removeEventListener('error', errorHandler)
    }
  }, [])

  useEffect(() => {
    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      setHasError(true)
      console.error('Unhandled promise rejection:', event.reason)
    }
    window.addEventListener('unhandledrejection', unhandledRejectionHandler)
    return () => {
      window.removeEventListener('unhandledrejection', unhandledRejectionHandler)
    }
  }, [])

  if (hasError) {
    return (
      <Flex className={"page-wrapper"}>
      <Flex className={"error-wrapper"} justify={"center"} style={{ padding: "40px 48px" }}>
        <Flex vertical={true} justify={"center"} align={"center"} className={"result-wrapper"}>
          <img
            src={Error}
            alt={"Error"}
            width={371}
            height={349}
          />
          <Typography.Title level={2} className={"result-title"}>Something Went Wrong!</Typography.Title>
        </Flex>
      </Flex>
      </Flex>
    )
  }

  return children
}

export default ErrorBoundary