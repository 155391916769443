import { Form, TabsProps } from "antd"
import React, { FC } from "react"
import { Drawer } from "src/components/ui/Drawer"
import { emailsAddressesData } from "../../info"
import { EmailAddressesTab, EmailSchedulingTab } from "./ui"

type ExploreFilterProps = {
  open: boolean
  onCancel: VoidFunction
}

export const ManageEmailDrawer: FC<ExploreFilterProps> = ({ open, onCancel }) => {
  const [form] = Form.useForm()

  const closeModal = () => {
    onCancel()
    form.resetFields()
  }

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Email Addresses",
      children: <EmailAddressesTab data={emailsAddressesData} />,
    },
    {
      key: "2",
      label: "Email Scheduling",
      children: <EmailSchedulingTab />,
    },
  ]

  return (
    <Drawer
      open={open}
      onCancel={closeModal}
      leftButtonTitle={"Reset all"}
      rightButtonTitle={"Save"}
      tabItems={items}
    />
  )
}
