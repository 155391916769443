import type { GetProp } from "antd"
import { Button, Checkbox, Flex, Form, Input, message, Select, Space, Tag } from "antd"
import React, { FC } from "react"
import { CustomLabel, Modal } from "src/components/ui"
import { saveToClipboard } from "src/helpers"

type AddVariableModalProps = {
  open: boolean
  onSubmit?: (values: any) => void
  onCancel: VoidFunction
}

const variableValue="{{var:first_name;alt:NaN;nat:2}}"

export const AddVariableModal: FC<AddVariableModalProps> = ({ open, onSubmit, onCancel }) => {
  const [form] = Form.useForm()

  const variableOptions = [
    {
      label: "First Name",
      value: "First Name",
    },
    {
      label: "Last Name",
      value: "Last Name",
    },
    {
      label: "Company Name",
      value: "Company Name",
    },
    {
      label: "Job Title",
      value: "Job Title",
    },
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "Company's URL",
      value: "Company's URL",
    },
  ]

  const closeModal = () => {
    onCancel()
    form.resetFields()
  }

  const handleCopyVariable=() => {
    saveToClipboard(variableValue)
    message.success("Copied successfully!")
  }

  const handleFormSubmit = () => {
    console.log("Added")
    form
      .validateFields()
      .then(values => {
        if (onSubmit) {
          onSubmit(values)
        }
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
    onCancel()
    form.resetFields()
  }

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = checkedValues => {
    console.log("checked = ", checkedValues)
  }

  return (
    <Modal width={530} open={open} title={"Add Variable"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Form.Item name={"variable"} label={"1.Select Variable"} initialValue={"First Name"}>
          <Select options={variableOptions} />
        </Form.Item>
        <Form.Item
          className={"large-label-item"}
          name={"alternativeVariable"}
          label={"2. If there's no variable value for the contact, what word should be written instead? (Optional)"}
        >
          <Input placeholder={"Alternative"} />
        </Form.Item>
        <CustomLabel
          title={"3. Naturalize Variable? (Optional)"}
          subtitle={"Select how to naturalize the variable to make it more personalized:"}
          isFontBold={true}
        />

        <Form.Item className={"large-label-item"} name={"naturalizeVariable"}>
          <Checkbox.Group onChange={onChange}>
            <Space direction={"vertical"}>
              <Checkbox value={"No Special Characters"}>No Special Characters</Checkbox>
              <Checkbox value={"No Company Initials Ex: Inc. .com"}>No Company Initials Ex: Inc. .com</Checkbox>
              <Checkbox value={"1 Spelling Mistake"}>1 Spelling Mistake</Checkbox>
              <Checkbox value={"1 Lower Case"}>1 Lower Case</Checkbox>
            </Space>
          </Checkbox.Group>
        </Form.Item>
        <CustomLabel title={"Copy Variable Into The Follow Up Subject Or Body Template:"} isFontBold={true} />
        <Flex gap={16} align={"center"} >
        <Tag color={"processing"} bordered={false} style={{ padding: "10px 16px", borderRadius: 79, fontSize: 14 }}>
          {variableValue}
        </Tag>
          <Button className={"link"} onClick={handleCopyVariable}>Copy</Button>
      </Flex>
      </Form>
    </Modal>
  )
}
