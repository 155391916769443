import { Button, Flex, Input, Typography } from "antd"
import React, { FC, useState } from "react"
import { ProspectsTable } from "src/components/pages/campaigns/ui"
import { ContentCard, CustomLabel } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import "./index.less"
import { ExtractionFileModal } from "./ui/ExtractionFileModal"
import { UploadProspectsModal } from "./ui/UploadProspectsModal"

const { Text } = Typography

export const StepTwo: FC = () => {
  const [openExtractionFileModal, setOpenExtractionFileModal] = useState(false)
  const [openUploadProspectsModal, setOpenUploadProspectsModal] = useState(false)

  const showExtractionFileModal = () => {
    setOpenExtractionFileModal(true)
  }

  const closeExtractionFileModal = () => {
    setOpenExtractionFileModal(false)
  }

  const showUploadProspectsModal = () => {
    setOpenUploadProspectsModal(true)
  }

  const closeUploadProspectsModal = () => {
    setOpenUploadProspectsModal(false)
  }

  return (
    <Flex vertical gap={25}>
      <ExtractionFileModal open={openExtractionFileModal} onCancel={closeExtractionFileModal} />
      <UploadProspectsModal onCancel={closeUploadProspectsModal} open={openUploadProspectsModal} />
      <Flex className={"card-container"} gap={30}>
        <ContentCard headerIcon={<SvgIcon type={"fire"} />} cardTitle={"Prospects"}>
          <Flex className={"step-two-card"} align={"flex-start"} vertical gap={15}>
            <Flex vertical>
              <Text>Upload prospects to campaign</Text>
              <small>Add the relevant prospects inside CSV</small>
            </Flex>
            <Button onClick={showUploadProspectsModal} className={"link"}>
              Upload prospects
            </Button>
            <Text>or</Text>
            <Text>Add prospects from Meetz database</Text>
            <Button onClick={showExtractionFileModal} className={"link"}>
              Select extraction file
            </Button>
          </Flex>
        </ContentCard>
        <ContentCard headerIcon={<SvgIcon type={"fire"} />} cardTitle={"Offer"}>
          <Flex className={"step-two-card"} vertical gap={20}>
            <Flex vertical gap={5}>
              <CustomLabel
                title={"Value Proposition"}
                subtitle={"Describe why these prospects would take interest in your value proposition."}
              />
              <Input.Search className={"search-input"} placeholder={"Keep It Brief"} allowClear enterButton={false} />
            </Flex>
            <Flex vertical gap={5}>
              <CustomLabel
                title={"Unique benefits"}
                subtitle={"Expand on what makes your offer valuable to the typical prospect uploaded."}
              />
              <Input.Search className={"search-input"} placeholder={"Keep It Brief"} allowClear enterButton={false} />
            </Flex>
          </Flex>
        </ContentCard>
      </Flex>
      <Flex vertical className={"explore-wrapper step-two-table-container"}>
        <ProspectsTable />
      </Flex>
    </Flex>
  )
}
