import { Menu, MenuProps, Row, Space } from "antd"
import { FC, Key, ReactNode, useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { Logo } from "src/components/logo"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

type MenuItem = Required<MenuProps>["items"][number] & {route: string}

const SideBarMenu: FC<{ isLogoLetter?: boolean, onSelect?: () => void, selectedItem?: string }> = ({isLogoLetter, onSelect, selectedItem }) => {
  const [selectedKey, setSelectedKey] = useState(selectedItem || "")
  const location = useLocation()

  const getIcon=(itemKey: string, itemRoute: string)=>{
    return(
      <NavLink to={itemRoute} className={"icons-wrapper"}>
        {selectedKey === itemKey && !isLogoLetter ? <SvgIcon className={"active-bar"} type={"activeBar"} /> : null}
        <SvgIcon type={selectedKey === itemKey ? `${itemKey}Active` : `${itemKey}`} />
      </NavLink>
    )
  }

  function getItem(
    label: ReactNode,
    key: Key,
    route?: string,
    icon?: ReactNode,
    children?: MenuItem[],
    type?: 'group',
  ): MenuItem | null{
    return {
      label,
      key,
      route,
      icon,
      children,
      type,
    } as MenuItem
  }

  const dividerItem = {
    type: 'divider',
  };

  const items: MenuItem[] = [
    getItem(
      "DASHBOARD",
      "1",
      "",
      null,
      [getItem(
        "Dashboard",
        "dashboard",
        "dashboard",
        getIcon("dashboard", "dashboard"),
      ),
        getItem(
          "Deals",
          "deals",
          "deals",
          getIcon("deals", "deals"),
        ),
      ]?.filter(Boolean) as MenuItem[],   'group'
    ),
    dividerItem,
    getItem(
      "DATABASE",
      "2",
      "",
      null,
      [getItem(
        "Prospect Data",
        "prospectData",
        "prospect-data",
        getIcon("prospectData", "prospect-data"),
      ),]?.filter(Boolean) as MenuItem[],   'group'
    ),
    dividerItem,
    getItem(
      "ENGAGE",
      "3",
      "",
      null,
      [getItem(
        "Campaigns",
        "campaigns",
        "campaigns",
        getIcon("campaigns", "campaigns"),
      ),
        getItem(
          "Emails",
          "emails",
          "mailing",
          getIcon("emails", "mailing"),
        ),
        getItem(
          "Phone calls",
          "phoneCalls",
          "phone-calls",
          getIcon("phoneCalls", "phone-calls"),
        ),]?.filter(Boolean) as MenuItem[],   'group'
    ),
    dividerItem,
    getItem(
      "MEETINGS",
      "4",
      "",
      null,
      [getItem(
        "Meeting Links",
        "meetingLinks",
        "meeting-links",
        getIcon("meetingLinks", "meeting-links"),
      ),]?.filter(Boolean) as MenuItem[],   'group'
    ),
    dividerItem,
    getItem(
      "OTHER",
      "5",
      "",
      null,
      [getItem(
        "Team members",
        "teamMembers",
        "team-members",
        getIcon("teamMembers", "team-members"),
      ),]?.filter(Boolean) as MenuItem[],   'group'
    ),
  ]?.filter(Boolean) as MenuItem[]

  useEffect(() => {
    if (selectedItem) {
      setSelectedKey(selectedItem);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!selectedItem) {
      const pathname = location.pathname
      let foundKey = ""

      const findKeyByPath = (items: any) => {
        for (const item of items) {
          if (`/${item.route}` === pathname) {
            foundKey = item.key.toString()
            break;
          }
          if (item.children) {
            findKeyByPath(item.children)
          }
        }
      };

      findKeyByPath(items)

      if (foundKey) setSelectedKey(foundKey.toString())
    }
  }, [location.pathname, items]);

  return (
    <Space
      direction={"vertical"}
      size={74}
    >
      <Row className={"logo-wrapper"} justify={isLogoLetter ? "center" : "start"}>
        <Logo isLogoLetter={isLogoLetter}/>
      </Row>
      <Menu theme={"light"} onSelect={onSelect} mode={"inline"} items={items} selectedKeys={[selectedKey]} />
    </Space>
  )
}

export { SideBarMenu }
