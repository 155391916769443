import { Line } from "@ant-design/plots"
import { FC } from "react"
import { overviewData, overviewPhoneAndSmsData } from "./OverviewChartData"

export const OverviewChart: FC<{ selectedChartType: string }> = ({ selectedChartType }) => {
  const config = {
    height: 236,
    forceFit: true,
    data: selectedChartType === "Emails" ? overviewData : overviewPhoneAndSmsData,
    xField: "date",
    yField: "value",
    legend: { position: "right-top" },
    colorField: "type",
    shapeField: "smooth",
    scale: {
      color:
        selectedChartType === "Emails"
          ? { range: ["#6048F3", "#01AEEF", "#0FCA7A", "#2B8F82", "#DA1818"] }
          : { range: ["#6048F3", "#01AEEF", "#AF1EAA", "#37B9A8"] },
    },
    responsive: true,
  }
  return <Line {...config} />
}
