import { Flex, Typography } from "antd"
import React, { FC } from "react"
import { useNavigate } from "react-router"
import { ConfirmButtons } from "src/components/ui"
import { overviewChart } from "src/shared/images"
import "./index.less"

export const LockedOverviewCard: FC<{ setIsDashboardLocked: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setIsDashboardLocked,
}) => {
  const navigate = useNavigate()
  return (
    <Flex className={"locked-overview-card"} gap={14}>
      <img src={overviewChart} alt={"overview chart"} width={298} />
      <Flex vertical gap={8} style={{ padding: "20px 0" }}>
        <Typography.Paragraph className={"locked-title"}>Overview Emails and SMS locked!</Typography.Paragraph>
        <Typography.Paragraph className={"locked-subtitle"}>
          To unlock it, you need to update plan or activate trial.
        </Typography.Paragraph>
        <ConfirmButtons
          rightButtonTitle={"Update Plan"}
          leftButtonTitle={"Activate trial"}
          onCancel={() => setIsDashboardLocked(false)}
          handleSubmit={() => navigate("/billing")}
        />
      </Flex>
    </Flex>
  )
}
