import { Button, Flex } from "antd"
import { SvgIcon } from "../../../../../../shared/icons"
import "./index.less"

export const Player = () => {
  const handleMenuClick = (e: any) => {
    e.stopPropagation()
  }

  return (
    <Flex justify={"space-between"} align={"center"} className={"player"} onClick={handleMenuClick}>
      <Button className={"player-btn"}>
        <SvgIcon type={"shuffle"} width={20} height={20} />
      </Button>
      <Flex align={"center"} gap={24} className={"controls"}>
        <Button className={"player-btn"}>
          <SvgIcon type={"rewindBack"} width={14} height={14} />
        </Button>
        <Button className={"player-btn"}>
          <SvgIcon type={"play"} width={40} height={40} />
        </Button>
        <Button className={"player-btn"}>
          <SvgIcon type={"rewindForward"} width={14} height={14} />
        </Button>
      </Flex>
      <Button className={"player-btn"}>
        <SvgIcon type={"undo"} width={20} height={20} />
      </Button>
    </Flex>
  )
}
