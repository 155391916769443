export const getActionItemButtonTitle = (item: string) => {
  switch (true) {
    case item === "Emails" || item === "SMS":
      return "Send"
    case item === "Phone Call":
      return "Call"
    default:
      return "Completed"
  }
}
