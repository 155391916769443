import { FC, useContext, useState } from "react"
import { CommonContext } from "../../../app/context/CommonContext"
import { Flex, List, Typography } from "antd"
import { ConfirmButtons, Modal } from "../../../ui"
import { SvgIcon } from "../../../../shared/icons"
import "./index.less"

interface GuidModalProps {
  open: boolean
  onCancel: VoidFunction
}

export const GuidModal: FC<GuidModalProps> = ({ open, onCancel }) => {
  const { setIsTourOpened, setCurrentStep } = useContext(CommonContext)
  const [selectedMenuOption, setSelectedMenuOption] = useState("Get prospect data")

  const handleStartTour = () => {
    setCurrentStep(1)
    setIsTourOpened(true)
    onCancel()
  }

  const guidMenuOptions = [
    {
      title: "Get prospect data",
      onClick: () => setSelectedMenuOption("Get prospect data")
    },
    {
      title: "Create campaign",
      onClick: () => setSelectedMenuOption("Create campaign")
    },
    {
      title: "Analytics",
      onClick: () => setSelectedMenuOption("Analytics")
    },
    {
      title: "Scheduler",
      onClick: () => setSelectedMenuOption("Scheduler")
    },
    {
      title: "Team Members",
      onClick: () => setSelectedMenuOption("Team Members")
    },
  ]

  return (
    <Modal rootClassName={"guid-modal"} width={"fit-content"} hasCloseIcon={false} open={open} onCancel={onCancel}>
      <Typography.Title level={2} className={"main-title"}>Welcome to Meetz!</Typography.Title>
      <Typography.Paragraph className={"description"}>Let's get you started with a quick tutorial and a step-by-step walkthrough.</Typography.Paragraph>
      <Flex className={"main-block"} gap={32} align={"center"}>
        <List
          className={"menu-list"}
          dataSource={guidMenuOptions}
          renderItem={(item) => (
            <List.Item
              style={{ color: item.title === selectedMenuOption ? "#01AEEF" : "#464A57"}}
              onClick={item.onClick}
            >
              {item.title}
            </List.Item>
          )}
        />
        <Flex className={"video-wrapper"} justify={"center"} align={"center"}>
          <SvgIcon type={"play2"} width={24} height={24} />
        </Flex>
      </Flex>
      <ConfirmButtons className={"confirm-buttons"} leftButtonTitle={"Remind me next time"} rightButtonTitle={"Start Exploring"} handleSubmit={handleStartTour} onCancel={onCancel} />
    </Modal>
  )
}