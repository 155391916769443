import { Steps, StepsProps } from "antd"
import { FC } from "react"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

export const CreateCampaignsStepsProgress: FC<{ currentStep: number }> = ({ currentStep }) => {
  const customDot: StepsProps["progressDot"] = (_, { status }) =>
    status === "wait" ? <SvgIcon type={"waitingDot"} /> : <SvgIcon type={"progressDot"} />

  return (
    <Steps
      className={"steps-wrapper"}
      current={currentStep}
      progressDot={customDot}
      items={[
        {
          title: "General settings",
        },
        {
          title: "Manage prospects",
        },
        {
          title: "Features of Sequence",
        },
      ]}
    />
  )
}
