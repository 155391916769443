import { Button, Checkbox, CollapseProps, Divider, Flex, Input, Row, Select, Slider, Switch, Typography } from "antd"
import TextArea from "antd/es/input/TextArea"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import PageLayout from "src/components/layout/PageLayout"
import { Collapse, ConfirmButtons, ContentCard } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { SaveValueModal, SelectFromSavedModal } from "src/components/pages/campaigns/CreateCampaign/ui/AIGenerated/ui/StepThree/ui"
import "./index.less"
import {
  callToActionsOptions,
  companyNameOptions,
  discussingPainOptions,
  helpingHandOptions,
  languageOptions,
  providingValueOptions,
  reactiveMeetingLinkOptions,
  salesMethodologyOptions,
  shortenEmailOptions,
  savedValueProposition,
} from "./infoData"


const { Text } = Typography

export const TestAiSettings: FC = () => {
  const [openSaveValueModal, setOpenSaveValueModal] = useState(false)
  const [openSelectFromSavedModal, setOpenSelectFromSavedModal] = useState(false)
  const [shortenEmailCurrent, setShortenEmailCurrent] = useState(25)
  const [helpingHandCurrent, setHelpingHandCurrent] = useState(50)
  const [providingValueCurrent, setProvidingValueCurrent] = useState(75)
  const [discussingPainCurrent, setDiscussingPainCurrent] = useState(100)
  const navigate = useNavigate()

  const showSaveValueModal = () => {
    setOpenSaveValueModal(true)
  }

  const showSelectFromSavedModal = () => {
    setOpenSelectFromSavedModal(true)
  }

  const closeModal = () => {
    setOpenSaveValueModal(false)
    setOpenSelectFromSavedModal(false)
  }
  const onShortenEmailCurrent = (value: number) => {
    setShortenEmailCurrent(value)
  }
  const onHelpingHandCurrent = (value: number) => {
    setHelpingHandCurrent(value)
  }
  const onProvidingValueCurrent = (value: number) => {
    setProvidingValueCurrent(value)
  }
  const onDiscussingPainCurrent = (value: number) => {
    setDiscussingPainCurrent(value)
  }

  const rightHeaderAiTest = (
    <ConfirmButtons
      width={"131px"}
      leftButtonTitle={null}
      rightButtonTitle={"Save AI Settings"}
      handleSubmit={() => console.log("Save")}
    />
  )

  const aiTestPageTitle = (
    <Row align={"middle"} style={{ gap: 16 }}>
      <SvgIcon type={"arrowBack"} onClick={() => navigate("/campaign/ai-generated")} style={{ cursor: "pointer" }} />
      <Typography.Title level={2} className={"page-title"}>
        {"Test AI Settings"}
      </Typography.Title>
    </Row>
  )

  const items: CollapseProps["items"] =[
    {
      key: "1",
      label: "Messaging Style",
      children:
        <>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>Shorten Email</Text>
            <Slider
              tooltip={{ formatter: null }}
              className={"options-bar"}
              onChange={onShortenEmailCurrent}
              marks={shortenEmailOptions}
              step={null}
              defaultValue={shortenEmailCurrent}
            />
          </Flex>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>Helping Hand</Text>
            <Slider
              tooltip={{ formatter: null }}
              className={"options-bar"}
              onChange={onHelpingHandCurrent}
              marks={helpingHandOptions}
              step={null}
              defaultValue={helpingHandCurrent}
            />
          </Flex>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>Providing Value</Text>
            <Slider
              tooltip={{ formatter: null }}
              className={"options-bar"}
              onChange={onProvidingValueCurrent}
              marks={providingValueOptions}
              step={null}
              defaultValue={providingValueCurrent}
            />
          </Flex>
          <Flex className={"options-bar-container"} vertical>
            <Text className={"options-bar-title"}>Discussing Pain</Text>
            <Slider
              tooltip={{ formatter: null }}
              className={"options-bar"}
              onChange={onDiscussingPainCurrent}
              marks={discussingPainOptions}
              step={null}
              defaultValue={discussingPainCurrent}
            />
          </Flex>
        </>
    },
    {
      key: "2",
      label: "Call To Action",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Input className={"content-input"} placeholder={"Would you like to try a free trial?"} />
          <Text>or</Text>
          <Checkbox.Group className={"content-checkbox-group"} options={callToActionsOptions} />
        </Flex>
    },
    {
      key: "3",
      label: "Reactive Meeting Link",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Text>If the CTA is "Book Meeting With Meetz Link"</Text>
          <Checkbox.Group className={"content-checkbox-group"} options={reactiveMeetingLinkOptions} />
        </Flex>
    },
    {
      key: "4",
      label: "Sales Methodology",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Text>Style of email template. Not required to select one.</Text>
          <Checkbox.Group className={"content-checkbox-group"} options={salesMethodologyOptions} />
        </Flex>
    },
    {
      key: "5",
      label: "Naturalize Company Name",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Text>Select how to naturalize the company name to make it more personalized:</Text>
          <Checkbox.Group className={"content-checkbox-group"} options={companyNameOptions} />
        </Flex>
    },
    {
      key: "6",
      label: "Custom Subject Line Instructions",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Input className={"content-input"} placeholder={"Enter reference text"} />
        </Flex>
    },
    {
      key: "7",
      label: "Custom Email Body Instructions",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Input className={"content-input"} placeholder={"Enter reference text"} />
          <Text className={"content-subtitle"}>or</Text>
          <Flex gap={15}>
            <Switch defaultChecked />
            <Text>Include emojis in the email Subject Line</Text>
          </Flex>
          <Flex gap={15}>
            <Switch />
            <Text>Include emojis in the email body</Text>
          </Flex>
        </Flex>
    },
    {
      key: "8",
      label: "Beta: Email Language",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Text className={"content-description"}>*Non-English Is 5 Credits Extra Per Email</Text>
          <Select options={languageOptions} className={"content-input"} defaultValue={"English"} />
        </Flex>
    },
    {
      key: "9",
      label: "Add Your Business Address To Add Into The Email Footer",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <TextArea className={"content-input"} placeholder={"123 Sesame Street - 10001 NYC, New York, USA"} />
        </Flex>
    },
    {
      key: "10",
      label: "Unsubscribe Sentence:Between the Startlink and Endlink the unsubscribe link will appear.",
      children:
        <Flex className={"collapse-card-content"} gap={10} vertical>
          <Text className={"content-description"}>*Non-English Is 5 Credits Extra Per Email</Text>
          <Input className={"content-input"} placeholder={"{{startlink}} Unsubscribe {{endlink}} here"} />
        </Flex>
    },
  ]

  return (
    <PageLayout pageTitle={aiTestPageTitle} rightHeaderContent={rightHeaderAiTest} isTableContent={false}>
      <Flex className={"test-ai-container"} wrap={"wrap"} gap={20}>
        <ContentCard headerIcon={<SvgIcon type={"starShine"}/>} cardTitle={"Your Value Proposition"}>
          <Flex gap={10} className={"card-content"} vertical>
            <Row justify={"space-between"}>
            <Text className={"content-subtitle"}>Value Proposition</Text>
              <Row align={"middle"}>
                <Button
                  className={"link"}
                  onClick={showSaveValueModal}
                >
                  Save
                </Button>
                <Divider type={"vertical"} />
                <Button
                  className={"link"}
                  onClick={showSelectFromSavedModal}
                >
                  Select From Saved
                </Button>
              </Row>
           </Row>
            <Text className={"content-description"}>
              Add The One Main Key Benefit Clients Would Get By Using Your Company And The Specific Feature That
              Delivers It.
            </Text>
            <Input className={"content-input"} placeholder={"Keep It Brief"} />
          </Flex>
          <Flex gap={10} className={"card-content"} vertical>
            <Text className={"content-subtitle"}>Keep It Brief</Text>
            <Text className={"content-description"}>
              Add A Quote Your Customers Gave With "" Or Add The ROI Your Customer Got From Your Product Or Services,
              include Your Customers Company Name.
            </Text>
            <TextArea
              className={"content-input"}
              autoSize
              placeholder={
                '"We felt how powerful Meetz is within 5 minutes of signing up! In the first 10 emails we already got a great lead." - Rob, VP of Sales at Acmef"\n' +
                "\n"
              }
            />
          </Flex>
          <Flex gap={10} className={"card-content"} vertical>
            <Text className={"content-subtitle"}>Your Job Title</Text>
            <Input className={"content-input"} placeholder={"SDR/ Account Executive/ VP Of Sales/ Founder"} />
          </Flex>
          <Flex gap={10} className={"card-content"} vertical>
            <Text className={"content-subtitle"}>Your Company's Name</Text>
            <Input className={"content-input"} placeholder={"Meetz"} />
          </Flex>
        </ContentCard>
        <ContentCard headerIcon={<SvgIcon type={"sendEmail"}/>} cardTitle={"Personalized Email To Send"}>
          <Flex className={"card-content"} gap={10} vertical>
            <Text className={"content-subtitle"}>Subject Line</Text>
            <Input className={"content-input"} placeholder={"Meeting John <> Robert"} />
          </Flex>
          <Flex className={"card-content"} gap={10} vertical>
            <Text className={"content-subtitle"}>Email Body</Text>
            <TextArea
              className={"content-input"}
              autoSize
              placeholder={
                "Fill Out The Above And Then Click On The Button To Generate The Personalized Email.\n" +
                "\n" +
                "NOTE: Don't forget to fill out the top blue bar with your value proposition and ICP + Add your email to send the messages from.\n" +
                "​"
              }
            />
          </Flex>
        </ContentCard>
        <ContentCard headerIcon={<SvgIcon type={"flash"}/> } cardTitle={"Messaging Tone and Advanced Options"}>
          <Collapse customClassName={"collapse-card-content"} items={items}/>
        </ContentCard>
      </Flex>
      <SaveValueModal open={openSaveValueModal} onCancel={closeModal}/>
      <SelectFromSavedModal open={openSelectFromSavedModal} onCancel={closeModal} data={savedValueProposition}/>
    </PageLayout>
  )
}
