import { Button, Divider, Flex, List, MenuProps, Row, Select, Space, Switch, Typography } from "antd";
import React, { FC, useContext, useRef, useState } from "react";
import { useDrag,useDrop } from "react-dnd";
import { useLocation } from "react-router-dom";
import { CampaignsContext } from "src/components/app/context/CampaignsContext";
import { DeleteStepModal, SelectStepModal, StepSequenceIcon } from "src/components/pages/campaigns/ui/StepsSequence/ui";
import { ContentCard, Loader, StatusTag } from "src/components/ui"
import { SvgIcon } from "src/shared/icons";
import { TagStatusInfoModal } from "../TagStatusInfoModal";
import { ChangeStepModal } from "./ui/ChangeStepModal"
import { useNavigate } from "react-router"
import { ViewAnalyticsModal } from "./ui/ViewAnalyticsModal"
import { DotsMenuDropdown } from "../../../../ui"
import "./index.less";

interface StepsSequenceProps {
  setMode?: React.Dispatch<React.SetStateAction<string>>;
  loading?: boolean;
  isEdit?: boolean;
  data: any[];
}

export const statusColors: any = {
  success: "success",
  finished: "processing",
  completed: "purple",
  skipped: "magenta",
};

export const StepsSequence: FC<StepsSequenceProps> = ({ setMode, data, loading, isEdit }) => {
  const [prevData, setPrevData] = useState(data);
  const [currentData, setCurrentData] = useState(data);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddStepModal, setOpenAddStepModal] = useState(false);
  const [openStatusTagInfoModal, setOpenStatusTagInfoModal] = useState(false);
  const [openChangeStepModal, setOpenChangeStepModal] = useState(false);
  const [openViewAnalyticsModal, setOpenViewAnalyticsModal] = useState(false);
  const [selectedStatusTag, setSelectedStatusTag] = useState("");
  const { selectedStep } = useContext(CampaignsContext);
  const { pathname } = useLocation();
  const navigate = useNavigate()

  const showModal = () => {
    setOpenDeleteModal(true);
  };

  const showAddStepModal = () => {
    setOpenAddStepModal(true);
  };

  const showChangeStepModal = () => {
    setOpenChangeStepModal(true)
  }

  const closeChangeStepModal = () => {
    setOpenChangeStepModal(false)
  }

  const showViewAnalyticsModal = () => {
    setOpenViewAnalyticsModal(true)
  }

  const closeViewAnalyticsModal = () => {
    setOpenViewAnalyticsModal(false)
  }

  const showStatusModal = (key: string) => {
    setOpenStatusTagInfoModal(true);
    setSelectedStatusTag(key);
  };

  const onCancel = () => {
    setOpenDeleteModal(false);
    setOpenAddStepModal(false);
    setOpenStatusTagInfoModal(false);
  };

  const updateStepNumbers = (steps: any) => {
    steps.forEach((step: any, index: number) => {
      step.stepNumber = index + 1;
    });
  };

  const handleChangeStepConfirmed = () => {
    setPrevData(currentData)
    setOpenChangeStepModal(false)
  }

  const handleChangeStepRejected = () => {
    setCurrentData(prevData);
    updateStepNumbers(prevData);
    setOpenChangeStepModal(false);
  };

  const handleEditSequenceBtnClick = () => {
    navigate(`${pathname}/edit-sequence`)
  }

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "View Analytics for Variants",
      onClick: () => showViewAnalyticsModal(),
    },
    {
      key: "2",
      label: "Delete step",
      onClick: () => showModal(),
    },
  ];

  const stepOptions = [
    {
      label: "Immediately",
      value: "Immediately",
    },
    {
      label: "Delay for 1 Day",
      value: "Delay for 1 Day",
    },
    {
      label: "Delay for 2 Days",
      value: "Delay for 2 Days",
    },
    {
      label: "Delay for 4 Days",
      value: "Delay for 4 Days",
    },
    {
      label: "Delay for 6 Days",
      value: "Delay for 6 Days",
    },
  ];

  const onChange = (checked: boolean) => {
    console.log(`switch to ${checked}`);
  };

  const isPersonalizedAiUsed = (): boolean => {
    if (pathname.endsWith("from-scratch") && selectedStep === "AI Personalized Email") {
      return true;
    } else {
      return (
        currentData &&
        currentData.some((item: any) => item.mode === "AI Personalized Email")
      );
    }
  };

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const draggedItem = currentData[dragIndex];
    const newData = [...currentData];

    newData.splice(dragIndex, 1);
    newData.splice(hoverIndex, 0, draggedItem);
    newData.forEach((item, index) => {
      item.stepNumber = index + 1;
    });

    setCurrentData(newData);
  };

  const DraggableItem: FC<any> = ({ item, index }) => {
    const iconRef = useRef<HTMLDivElement>(null);
    const listItemRef = useRef<HTMLDivElement>(null);

    const [, drag] = useDrag({
      type: "card",
      item: () => {
        return { item, index };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        opacity: monitor.isDragging() ? 0.8 : 1,
      }),
    });

    const [, drop] = useDrop({
      accept: "card",
      hover(item: any, monitor) {
        if (!listItemRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        const isOver = monitor.isOver({ shallow: true });

        if (dragIndex === hoverIndex || !isOver) {
          return;
        }

        moveCard(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop(item: any, monitor) {
        const isOrderChanged = !currentData.every((item, index) => item === prevData[index]);

        if (isOrderChanged) {
          showChangeStepModal()
        }
      }
    });

    drag(iconRef);
    drop(listItemRef);

    return (
      <List.Item ref={listItemRef} key={item.id} className={"steps-sequence-item"}>
        <Row align={"middle"} justify={"space-between"} style={{ width: "100%" }} wrap={false}>
          <Space size={6}>
            <Row align={"top"} style={{ gap: 8 }}>
              {isEdit && (
                <div ref={iconRef} style={{ cursor: "grab" }}>
                  <SvgIcon type={"stepPrefix"} />
                </div>
              )}
              <Typography.Paragraph className={"step-number-title"}>{`Step ${item.stepNumber}`}</Typography.Paragraph>
            </Row>
            {item.mode !== "Phone Call" ? (
              <Row align={"middle"} style={{ gap: 12 }}>
                <Typography.Paragraph>Automatic Start</Typography.Paragraph>
                <Switch defaultChecked size={"small"} onChange={onChange} />
              </Row>
            ) : null}
          </Space>
          <DotsMenuDropdown menuItems={items} />
        </Row>
        <Space>
          <StepSequenceIcon mode={item.mode} />
          <Space direction={"vertical"} size={4}>
            <Flex align={"center"}>
              <Typography.Paragraph>{`Day ${item.day}`}</Typography.Paragraph>
              <SvgIcon type={"dividerDot"} style={{ margin: "0 6px" }} />
              {item.delay === "Scenario Started" ? (
                <Typography.Paragraph className={"select-input-title"}>Scenario Started</Typography.Paragraph>
              ) : (
                <Select defaultValue={item.delay} size={"small"} options={stepOptions} style={{ width: 139 }} />
              )}
              {item.additional.length ? <SvgIcon type={"dividerDot"} style={{ margin: "0 6px" }} /> : null}
              {item.mode !== "Phone Call" && item.additional.length ? (
                <React.Fragment>
                  {item.additional.map((it: any, index: number) => {
                    return (
                      <Row key={`${index}-${it}`} align={"middle"} style={{ marginRight: "6px" }}>
                        <Typography.Paragraph className={"step-additional-value"}>{it}</Typography.Paragraph>
                        {index === 0 ? <SvgIcon type={"person"} /> : <SvgIcon type={"watch"} />}
                      </Row>
                    )
                  })}
                </React.Fragment>
              ) : item.additional.length ? (
                <React.Fragment>
                  <Typography.Paragraph className={"step-additional-value"}>
                    {item.additional[0]}
                  </Typography.Paragraph>
                  <SvgIcon type={"voiceMail"} />
                </React.Fragment>
              ) : null}
            </Flex>
            <Typography.Paragraph
              className={"step-mode-title"}
              onClick={() => setMode && setMode(item.mode.split(" ")[0].toLowerCase())}
            >
              {item.mode}
            </Typography.Paragraph>
          </Space>
        </Space>

        {item.status && (
          <>
            <Divider style={{ margin: "6px 0" }} />
            <Row justify={"space-between"} style={{ width: "100%" }}>
              {Object.entries(item.status).map(([key, value]) => (
                <StatusTag
                  key={key}
                  value={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}
                  color={statusColors[key]}
                  style={{ padding: "7px 14px" }}
                  onClick={() => showStatusModal(key)}
                />
              ))}
            </Row>
          </>
        )}
      </List.Item>
    );
  };

  return (
    <ContentCard
      headerIcon={<SvgIcon type={"statistic"} />}
      cardTitle={"Sequence"}
      headerRightContent={!loading ?
        !isEdit && <Button className={"link plus-btn"} onClick={handleEditSequenceBtnClick}>
          Edit
        </Button> : null
      }
    >
      <Flex vertical className={"steps-sequence-container"} justify={"center"}>
        {loading ?
          <Loader className={"loader-wrapper"} tip={"Loading... AI is selecting the best sequence for your campaign."}/>
            :
          <>
            <div className={"steps-sequence-wrapper"}>
              {currentData && currentData.map((item, index) => (
                <DraggableItem
                  key={item.id}
                  item={item}
                  index={index}
                />
              ))}
            </div>
            <Button className={"link"} onClick={showAddStepModal}>Add Step</Button>
            {openDeleteModal && <DeleteStepModal open={openDeleteModal} onCancel={onCancel} />}
            {openAddStepModal && (
            <SelectStepModal open={openAddStepModal} onCancel={onCancel} isPersonalizedAiUsed={isPersonalizedAiUsed()} />
            )}
            {openStatusTagInfoModal && (
            <TagStatusInfoModal open={openStatusTagInfoModal} onCancel={onCancel} status={selectedStatusTag} />
            )}
            {openChangeStepModal && (
            <ChangeStepModal onSubmit={handleChangeStepConfirmed} onCancel={handleChangeStepRejected} onClose={closeChangeStepModal} />
            )}
            {openViewAnalyticsModal && <ViewAnalyticsModal onClose={closeViewAnalyticsModal} />}
          </>
        }
      </Flex>
    </ContentCard>
  );
};
