import { Flex, Typography } from "antd"
import React, { FC } from "react"
import { virtualLady } from "src/shared/images"

export const EmptyFilterData: FC = () => {
  return (
    <Flex vertical style={{ margin: "150px 0" }}>
      <img src={virtualLady} alt={"lady with VR glasses"} width={208} />
      <Typography.Paragraph style={{ marginTop: 24, color: "#5D6279", textAlign: "center" }}>
        Apply Filters To See Prospects
      </Typography.Paragraph>
    </Flex>
  )
}
