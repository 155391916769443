import { Col, Flex, Space } from "antd"
import React, { FC, useState } from "react"
import PageLayout from "src/components/layout/PageLayout"
import { StatisticsBar } from "src/components/ui"
import {
  ActionsToTakeCard,
  ActivitiesCard,
  AiInitiatedEmailCard,
  ExclusiveOfferBanner,
  OverviewCard,
  UnlockAccessModal,
} from "../index"
import "./index.less"
import { LockedActionsToTake, LockedAiInitiatedEmail, LockedOverviewCard } from "./ui"
import { useBreakpoints } from "../../../../helpers/useBreakpoints"
import { GoToDesktopModal } from "../../../ui/GoToDesktopModal"

const emptyDashboardStatistic = [
  {
    title: "Active Campaigns",
    value: 0,
  },
  {
    title: "Total Campaigns",
    value: 0,
  },
]

const dashboardStatistic = [
  {
    title: "Active Campaigns",
    value: 20,
  },
  {
    title: "Total Campaigns",
    value: 32,
  },
]

const hasAccess = false

interface DashboardLayoutProps {
  isTourStep?: boolean
}

export const DashboardLayout: FC<DashboardLayoutProps> = ({ isTourStep }) => {
  const { isDesktop } = useBreakpoints()
  const [openUnlockAccessModal, setOpenUnlockAccessModal] = useState(!hasAccess)
  const [isDashboardLocked, setIsDashboardLocked] = useState(false)

  const handleUnlockAccessModalClose = () => {
    setOpenUnlockAccessModal(false)
  }

  const handleSubmit = () => {
    handleUnlockAccessModalClose()
    setIsDashboardLocked(true)
  }

  return isDesktop ? (
    <PageLayout
      pageTitle={"Dashboard"}
      isTableContent={false}
      bottomHeaderContent={isDashboardLocked && <ExclusiveOfferBanner setIsDashboardLocked={setIsDashboardLocked} />}
    >
      <Flex style={{ gap: 24 }}>
        <Space direction={"vertical"} size={24}>
          <div className={"overview-wrapper"}>
            <OverviewCard data={!isDashboardLocked && !openUnlockAccessModal} />
            {isDashboardLocked && (
              <div className={isDashboardLocked ? "blur-mask" : ""}>
                <LockedOverviewCard setIsDashboardLocked={setIsDashboardLocked} />
              </div>
            )}
          </div>
          <div className={"overview-wrapper"}>
            <AiInitiatedEmailCard data={!isDashboardLocked && !openUnlockAccessModal} />
            {isDashboardLocked && (
              <div className={isDashboardLocked ? "blur-mask" : ""}>
                <LockedAiInitiatedEmail />
              </div>
            )}
          </div>
        </Space>
        <Col style={{ maxWidth: 360 }}>
          <Space direction={"vertical"} size={24}>
            <StatisticsBar data={isDashboardLocked ? emptyDashboardStatistic : dashboardStatistic} />
            <div className={"actions-wrapper"}>
              <ActionsToTakeCard data={!isDashboardLocked && !openUnlockAccessModal} />
              {isDashboardLocked && (
                <div className={isDashboardLocked ? "blur-mask" : ""}>
                  <LockedActionsToTake />
                </div>
              )}
            </div>
            <ActivitiesCard data={!isDashboardLocked && !openUnlockAccessModal} />
          </Space>
        </Col>
      </Flex>
      {!isTourStep && (
        <UnlockAccessModal
          open={openUnlockAccessModal}
          onCancel={handleUnlockAccessModalClose}
          handleSubmit={handleSubmit}
        />
      )}
    </PageLayout>
  ) : (
    <>
      {openUnlockAccessModal ? (
        <UnlockAccessModal
          open={openUnlockAccessModal}
          onCancel={handleUnlockAccessModalClose}
          handleSubmit={handleSubmit}
        />
      ) : (
        <GoToDesktopModal />
      )}
    </>
  )
}
