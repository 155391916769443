import { Flex } from "antd"
import React, { FC } from "react"
import { Modal } from "src/components/ui"
import { CreateCampaignMethodSelect } from "./ui/CreateCampaignMethodSelect"
import { UpdatePlanBanner } from "../../CreateCampaign/ui"
import "./index.less"

type CreateCampaignsModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const CreateCampaignsModal: FC<CreateCampaignsModalProps> = ({ open, onCancel }) => {
  const closeMainModal = () => {
    onCancel()
  }

  return (
    <Modal open={open} title={"Create Campaign"} width={530} onCancel={closeMainModal}>
      <Flex vertical gap={28}>
        <UpdatePlanBanner
          title={"Create Campaign locked!"}
          subtitle={"To unlock this feature, you need to update plan."}
        />
        <CreateCampaignMethodSelect onCancel={onCancel} />
      </Flex>
    </Modal>
  )
}
