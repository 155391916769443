import { Avatar, Col, Form, Row, TimePicker, Typography } from "antd"
import dayjs from "dayjs"
import React, { FC, useState } from "react"

interface WeekTimePlannerProps {
  formItemName?: string[]
  className?: string
  onChange?: (value: any) => void
  timeFormat?: string
}

const daysList = [
  {
    value: "Monday",
    label: "M",
  },
  {
    value: "Tuesday",
    label: "T",
  },
  {
    value: "Wednesday",
    label: "W",
  },
  {
    value: "Thursday",
    label: "T",
  },
  {
    value: "Friday",
    label: "F",
  },
  {
    value: "Saturday",
    label: "S",
  },
  {
    value: "Sunday",
    label: "S",
  },
]

export const WeekTimePlanner: FC<WeekTimePlannerProps> = ({
  formItemName = [],
  className,
  onChange,
  timeFormat,
  ...props
}) => {
  const [selectedWeekdays, setSelectedWeekdays] = useState<string[]>([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
  ])

  const sortDaysByWeekday = (weekdays: string[]): string[] => {
    return weekdays.sort(
      (a, b) => daysList.findIndex(day => day.value === a) - daysList.findIndex(day => day.value === b)
    )
  }

  const handleWeekdayClick = (weekdayValue: string) => {
    let updatedSelectedWeekdays: string[]
    if (selectedWeekdays.includes(weekdayValue)) {
      updatedSelectedWeekdays = selectedWeekdays.filter(day => day !== weekdayValue)
    } else {
      updatedSelectedWeekdays = sortDaysByWeekday([...selectedWeekdays, weekdayValue])
    }
    setSelectedWeekdays(updatedSelectedWeekdays)
  }

  const isDaySelected = (day: string): boolean => {
    return selectedWeekdays.some(item => item === day)
  }

  return (
    <Col className={className ? `week-time-planner ${className}` : "week-time-planner"} {...props}>
      <Row justify={"space-between"} style={{ padding: "6px 0", marginBottom: 8 }}>
        {daysList.map((weekday, index) => (
          <Avatar
            key={`${index}-${weekday.value}`}
            style={{ backgroundColor: isDaySelected(weekday.value) ? "#01AEEF" : "#01AEEF4D", cursor: "pointer" }}
            onClick={() => {
              handleWeekdayClick(weekday.value)
            }}
          >
            {weekday.label}
          </Avatar>
        ))}
      </Row>
      {selectedWeekdays.map((weekDay, index) => (
        <Row
          key={`${index}-${weekDay}`}
          justify={"space-between"}
          align={"middle"}
          style={{ marginBottom: 8 }}
          wrap={false}
        >
          <Typography.Paragraph style={{ color: "#757A89", lineHeight: 1.43 }}>{weekDay}</Typography.Paragraph>
          <Form.Item
            name={[...(formItemName || []), weekDay.toLowerCase()]}
            initialValue={[dayjs("09:00", "HH:mm"), dayjs("16:00", "HH:mm")]}
            style={{ marginBottom: 0 }}
          >
            <TimePicker.RangePicker format={timeFormat || "HH:mm"} />
          </Form.Item>
        </Row>
      ))}
    </Col>
  )
}
