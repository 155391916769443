import { Flex, MenuProps, Row, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router"
import { CustomSelect, Table, TableHeader } from "src/components/ui"
import { App } from "src/types"
import { campaignData } from "../campaignsData"
import { CampaignsEmptyTable } from "../CampaignsEmptyTable"
import { DotsMenuDropdown } from "../../../ui"
import "./index.less"

interface CampaignsTableProps {
  isTourStep?: boolean
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: "Pause",
  },
  {
    key: "2",
    label: "Rename",
  },
  {
    key: "3",
    label: "Delete",
  },
]

const headerSelectOptions = [
  {
    label: "General",
    value: "General",
  },
  {
    label: "Emails",
    value: "Emails",
  },
  {
    label: "Phones",
    value: "Phones",
  },
  {
    label: "Analytics",
    value: "Analytics",
  },
]

export const CampaignsTable: FC<CampaignsTableProps> = ({ isTourStep }) => {
  const [tourStepData, setTourStepData] = useState(campaignData)
  const [tableData, setTableData] = useState(campaignData)
  const [loading, setLoading] = useState(true);
  const memoizedTourStepData = useMemo(() => tourStepData, [tourStepData]);
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000)
  }, []);

  useEffect(() => {
    setTableData(campaignData)
  }, [campaignData])

  const columns: ColumnsType<App.CampaignEntity> = [
    {
      title: "Campaign Name",
      className: "clickable-table-title",
      render: record => (
        <Typography.Paragraph
          onClick={() => {
            navigate(`/campaign/${btoa(`${record?.id}`)}`)
          }}
        >
          {record.campaign}
        </Typography.Paragraph>
      ),
      key: "campaign",
    },
    {
      title: "Contacts",
      dataIndex: "contacts",
      key: "contacts",
      align: "right",
    },
    {
      title: "Contacts in campaign",
      dataIndex: "contactsInCampaign",
      key: "contactsInCampaign",
      align: "right",
    },
    {
      title: "Actions for today",
      dataIndex: "actionsForToday",
      key: "actionsForToday",
      align: "right",
    },
    {
      title: "Prospects",
      dataIndex: "prospects",
      key: "prospects",
      align: "right",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, record) => (
        <Flex align={"center"} gap={8}>
          <Row className={"circle"} style={{ backgroundColor: value === "Active" ? "#0FCA7A" : "#FAAD14"}} />
          <Typography.Paragraph className={"table-text"}>{value}</Typography.Paragraph>
        </Flex>
      )
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <DotsMenuDropdown menuItems={items} />
      ),
    },
  ]

  return (
    <div className={"campaign-wrapper"}>
      {campaignData.length === 0 ? (
        <CampaignsEmptyTable />
      ) : (
        <>
          <TableHeader searchPlaceholder={"Search Campaigns..."} rightHeaderContent={<CustomSelect options={headerSelectOptions} defaultValue={headerSelectOptions[0].value} style={{ width: "fit-content", minWidth: 192 }}/>} />
          {isTourStep ? (
            <Table columns={columns} dataSource={memoizedTourStepData} />
          ) : (
            <Table loading={loading} columns={columns} dataSource={tableData}/>
          )}
        </>
      )}
    </div>
  )
}
