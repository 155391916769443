import { Button } from "antd"
import React, { FC, RefObject, useState } from "react"
import { useLocation } from "react-router-dom"
import PageLayout from "../../../layout/PageLayout"
import { CampaignsTable } from "../CampaignsTable"
import { CreateCampaignsModal } from "../ui"
import { AIGeneratedSequence, FromSaved, FromScratch } from "./ui"
import { TestAiSettings } from "./ui/AIGenerated/ui/StepThree/ui/AiPersonalizedEmail/ui/TestAiSettings"

interface CreateCampaignProps {
  isTourStep?: boolean
  createCampaignRef?: RefObject<HTMLButtonElement>;
}

export const CreateCampaign: FC<CreateCampaignProps> = ({ createCampaignRef, isTourStep }) => {
  const [openModal, setOpenModal] = useState(false)
  const { pathname } = useLocation()

  const showModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const rightHeaderContent = (
    <Button onClick={showModal} className={"primary"} ref={createCampaignRef}>
      Create Campaign
    </Button>
  )

  switch (true) {
    case pathname.endsWith("ai-generated"):
      return <AIGeneratedSequence />
    case pathname.endsWith("from-saved"):
      return <FromSaved />
    case pathname.endsWith("from-scratch"):
      return <FromScratch />
    case pathname.endsWith("test-ai-settings"):
      return <TestAiSettings />
    default:
      return (
        <PageLayout pageTitle={"Campaigns"} rightHeaderContent={rightHeaderContent}>
          <CreateCampaignsModal onCancel={closeModal} open={openModal} />
          <CampaignsTable isTourStep={isTourStep}/>
        </PageLayout>
      )
  }
}
