export const getHeadcountRange = (headcount: number) => {
  switch (true) {
    case headcount < 25:
      return "0 - 25"
    case headcount < 100:
      return "25 - 100"
    case headcount < 250:
      return "100 - 250"
    case headcount < 1000:
      return "250 - 1000"
    case headcount < 10000:
      return "1K - 10K"
    case headcount < 50000:
      return "10K - 50K"
    case headcount < 100000:
      return "50K - 100K"
    default:
      return "> 100K"
  }
}
