import { Button, Flex, MenuProps, Row, Typography } from "antd";
import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ColumnsType } from "antd/es/table";
import { App } from "src/types";
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext";
import { prospectData } from "../../info";
import { ActivateForAccessModal, ExportProspectsModal, PhonesCell } from "src/components/pages/prospect-data";
import { Table } from "src/components/ui";
import { getHeadcountRange, getRevenueRange } from "src/helpers";
import { SvgIcon } from "src/shared/icons";
import { EmptyFilterData } from "./ui/EmptyFilterData";
import { HeaderTagsBar } from "./ui/HeaderTagsBar";
import "./index.less";

export const items: MenuProps["items"] = [
  {
    key: "1",
    label: "View details",
  },
  {
    key: "2",
    label: "Add member",
  },
  {
    key: "3",
    danger: true,
    label: "Delete",
  },
];

interface ExploreTableProps {
  isTourStep?: boolean;
}

export const ExploreTable: FC<ExploreTableProps> = ({ isTourStep }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [openActivateModal, setOpenActivateModal] = useState(true);
  const [loading, setLoading] = useState(true);
  const { filterFormData } = useContext(ProspectDataContext);
  const [tableData, setTableData] = useState<App.ProspectEntity[]>(prospectData);
  const [tourStepTableData, setTourStepTableData] = useState<App.ProspectEntity[]>(prospectData);

  const isObjectEmpty = (obj: any) => {
    return Object.keys(obj).every(key => Array.isArray(obj[key]) && obj[key].length === 0);
  };
  const isFilterSelected = filterFormData && !isObjectEmpty(filterFormData);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setTableData(prospectData);
  }, [prospectData])

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleSelectAll = useCallback(() => {
    if (prospectData && Array.isArray(prospectData)) {
      const selectedKeys = prospectData.map(item => item["id"]);
      rowSelection.onChange(selectedKeys);
    }
  }, [prospectData]);

  const handleDeselectAll = () => {
    setSelectedRowKeys([]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const showModal = () => {
    setOpenModal(true);
  };

  const onCancel = () => {
    setOpenModal(false);
    setOpenActivateModal(false);
  };

  const columns: ColumnsType<App.ProspectEntity> = [
    {
      title: "First Name",
      dataIndex: "firstName",
      fixed: "left",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      fixed: "left",
      key: "lastName",
    },
    {
      title: "Company",
      dataIndex: "company",
      fixed: "left",
      key: "company",
    },
    {
      title: "Job title",
      width: "auto",
      dataIndex: "jobTitle",
      key: "jobTitle",
    },
    {
      title: "Email",
      width: "auto",
      dataIndex: "email",
      render: value => (value ? <SvgIcon type={"checked"} /> : <SvgIcon type={"minus"} />),
      key: "email",
    },
    {
      title: "Phone Numbers Available",
      dataIndex: "phoneNumbers",
      render: value => <PhonesCell phonesData={value} />,
      key: "phoneNumbers",
    },
    {
      title: "Country",
      width: "auto",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "LinkedIn",
      width: "auto",
      dataIndex: "linkedin",
      render: value => (value ? <SvgIcon type={"checked"} /> : <SvgIcon type={"minus"} />),
      key: "linkedin",
    },
    {
      title: "Twitter",
      width: "auto",
      dataIndex: "twitter",
      render: value => (value ? <SvgIcon type={"checked"} /> : <SvgIcon type={"minus"} />),
      key: "twitter",
    },
    {
      title: "Industry",
      dataIndex: "industry",
      key: "industry",
    },
    {
      title: "Company's Location",
      className: "wrapped-header-cell",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Company Headcount",
      className: "wrapped-header-cell",
      dataIndex: "headcount",
      render: value => getHeadcountRange(value),
      key: "headcount",
    },
    {
      title: "Company Revenue",
      className: "wrapped-header-cell",
      dataIndex: "revenue",
      render: value => getRevenueRange(value),
      key: "revenue",
    },
  ];

  const leftTableHeaderContent = (
    <Row style={{ gap: 16 }}>
      <Typography.Paragraph className={"explore-table-title"}>Prospects</Typography.Paragraph>
      {isFilterSelected && (
        <Button className={"link"} onClick={selectedRowKeys.length > 0 ? handleDeselectAll : handleSelectAll}>
          {selectedRowKeys.length > 0 ? "Deselect all" : "Select all"}
        </Button>
      )}
    </Row>
  );

  const rightTableHeaderContent = (
    <Row style={{ gap: 20 }}>
      <Row align={"middle"} style={{ gap: 8, cursor: "pointer" }} onClick={showModal}>
        <SvgIcon type={"export"} />
        <Typography.Paragraph>Export</Typography.Paragraph>
      </Row>
    </Row>
  );

  const bottomTableHeaderContent = (
    <HeaderTagsBar
      totalAmount={isFilterSelected ? prospectData.length : 0}
      selectedAmount={isFilterSelected ? selectedRowKeys.length : 0}
    />
  );

  const memoizedTourStepTableData = useMemo(() => tourStepTableData, [tourStepTableData]);

  return (
    <div className={"explore-wrapper"}>
      <Flex vertical gap={16} style={{ marginBottom: 12 }}>
        <Row justify={"space-between"}>
          {leftTableHeaderContent}
          {rightTableHeaderContent}
        </Row>
        {bottomTableHeaderContent}
      </Flex>
      <Flex vertical={!!isFilterSelected || isTourStep} justify={isFilterSelected || isTourStep ? "unset" : "center"} align={isFilterSelected || isTourStep ? "unset" : "center"}>
        {isTourStep ? (
          <Table
            columns={columns}
            dataSource={memoizedTourStepTableData}
            scroll={{ x: "calc(1200px + 50%)" }}
            rowSelection={{ ...rowSelection }}
            pageSize={5}
            skeletonSelection
          />
        ) : isFilterSelected ? (
          <Table
            loading={loading}
            columns={columns}
            dataSource={tableData}
            scroll={{ x: "calc(1200px + 50%)" }}
            rowSelection={{ ...rowSelection }}
            pageSize={5}
            skeletonSelection
          />
        ) : (
          <EmptyFilterData />
        )}
        {!isTourStep && (
          <>
            <ExportProspectsModal
              open={openModal}
              onCancel={onCancel}
              totalSelectedProspects={selectedRowKeys.length}
              setTotalSelectedProspects={setSelectedRowKeys}
              selectedRowKeys={selectedRowKeys}
            />
            <ActivateForAccessModal open={openActivateModal} onCancel={onCancel} />
          </>
        )}
      </Flex>
    </div>
  );
};
