import { Button, Select, Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC, useEffect, useState } from "react"
import { ConfirmButtons, Modal, StatusTag } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

type ConnectedEmailsProps = {
  open: boolean
  showWarmUpModal: (item: any) => void
  showModal: (item: any) => void
  onCancel: VoidFunction
  data: any
}

export const ConnectedEmailsModal: FC<ConnectedEmailsProps> = ({ data, open, onCancel, showWarmUpModal, showModal }) => {
  const [emailsData, setEmailsData] = useState<any>()

  useEffect(() => {
    if(data) setEmailsData(data)
  }, [data, !open])
  const closeModal = () => {
    onCancel()
  }

  const handleSubmit = () => {
    console.log("Saved")
    onCancel()
  }

  const timePeriod = ["30 Seconds", "1 Minute", "1.5 Minutes", "2 Minutes", "5 Minutes", "10 Minutes", "20 Minutes"]

  const timeBetweenSendsOptions = timePeriod.map(time => {
    return {
      label: time,
      value: time,
    }
  })

  const handleDelete = (itemId: string) => {
    const newData = emailsData?.filter((item: any) => item.id !== itemId)
    setEmailsData(newData)
  }

  const columns: ColumnsType<any> = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Campaigns Connected to",
      dataIndex: "connected",
      render: value =>
        value ? <StatusTag value={"Enable"} color={"success"} /> : <StatusTag value={"None"} color={"cyan"} />,
      key: "connected",
    },
    {
      title: "Email Sends Per Day Limit",
      dataIndex: "warmUpMode",
      render: (value, record) =>
        value ? (
          <Button
            className={"link"}
            style={{ padding: 0 }}
            onClick={() => showWarmUpModal(record)}
          >
            Warm Up Mode
          </Button>
        ) : (
          <Button
            className={"link"}
            style={{ padding: 0 }}
            onClick={() => showModal(record)}
          >
            100 Emails
          </Button>
        ),
      key: "warmUpMode",
    },
    {
      title: "Time Between Sends",
      render: () => <Select options={timeBetweenSendsOptions} style={{ width: 92 }} defaultValue={"1.5 Minutes"} />,
      key: "contents",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: value =>
        value ? (
          <StatusTag value={"Connected"} color={"success"} />
        ) : (
          <StatusTag value={"Disconnected"} color={"error"} />
        ),
      key: "status",
    },
    {
      title: "Remove",
      render: (render) => <SvgIcon type={"closeTag"} style={{ cursor: "pointer" }} onClick={() => handleDelete(render.id)}/>,
      key: "remove",
    },
  ]

  return (
    <Modal
      customClassName={"emails-connected-modal"}
      width={"954px"}
      open={open}
      title={"Connected Emails"}
      onCancel={closeModal}
    >
      <Typography.Paragraph
        className={"tag-counter"}
        style={{ marginBottom: 28 }}
      >{`${emailsData?.length} Emails`}</Typography.Paragraph>
      <Table rowKey={"id"} columns={columns} dataSource={emailsData} pagination={false} />
      <div style={{ width: "50%", margin: " 28px 0 0 auto" }}>
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Save"}
          onCancel={closeModal}
          handleSubmit={handleSubmit}
        />
      </div>
    </Modal>
  )
}
