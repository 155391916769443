import { Flex, Row, Typography } from "antd"
import { FC } from "react"
import { Modal } from "../../../../ui"
import "./index.less"

interface SuccessfulActivationModalProps {
  currentPlanName: string
  onClose: () => void
}

export const SuccessfulActivationModal: FC<SuccessfulActivationModalProps> = ({ currentPlanName, onClose }) => {
  return (
    <Modal rootClassName={"successful-activation-modal"} open={true} closeIcon={false} onCancel={onClose}>
      <Row className={"banner"} />
      <Flex className={"info-wrapper"} vertical={true} gap={16}>
        <Typography.Title level={2} className={"title"}>
          Your Current Plan: {currentPlanName}!
        </Typography.Title>
        <Typography.Paragraph className={"description"}>
          Update to a team plan by reaching out to support. If you are on a team plan now, contact your customer success
          manager for help and optimization.
        </Typography.Paragraph>
      </Flex>
    </Modal>
  )
}
