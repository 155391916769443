export const getRevenueRange = (revenue: number) => {
  switch (true) {
    case revenue < 1000000:
      return "$0 - 1M"
    case revenue < 10000000:
      return "$1M - 10M"
    case revenue < 50000000:
      return "$10M - 50M"
    case revenue < 100000000:
      return "$50M - 100M"
    case revenue < 250000000:
      return "$100M - 250M"
    case revenue < 500000000:
      return "$250M - 500M"
    case revenue < 1000000000:
      return "$500M - 1B"
    default:
      return "> $1B"
  }
}
