import { Form,Input,Row,Space,Typography,message } from "antd"
import React, { FC, useEffect } from "react"
import { ConfirmButtons,FilterTagsList,Modal } from "src/components/ui"
import { totalTagsList } from "../../index"

type SaveFiltersModalProps = {
  data: any
  open: boolean
  onCancel: VoidFunction
  isEditModal?: boolean
}



export const SaveFiltersModal: FC<SaveFiltersModalProps> = ({isEditModal=false, data, open, onCancel }) => {
  const [form] = Form.useForm()

  useEffect(() => {
    if(data && isEditModal) form.setFieldValue(["filterName"], data.name)
  }, [data])
  const info = () => {
    message.success(`${isEditModal ? "Filter Edited!": "New Saved Filters!"}`)
  }
  
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    info()
    onCancel()
  }

  return (
    <Modal
      width={553}
      customClassName={"save-filters-modal"}
      open={open}
      title={!isEditModal ? "Save Filters" : "Edit Saved Saved Filters"}
      onCancel={closeModal}
    >
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Form.Item name={"filterName"} label={"Name"} initialValue={isEditModal && data && data.name} >
          {/*<Input defaultValue={isEditModal && data ? data.name : undefined} required />*/}
          <Input required />
        </Form.Item>
          <Space direction={"vertical"}>
          <Row justify={"space-between"} align={"middle"}>
            <Typography.Paragraph>{`Selected filters (${totalTagsList(data)?.length})`}</Typography.Paragraph>
          </Row>
            <FilterTagsList data={isEditModal && data? data?.filterTags : totalTagsList(data)}/>
        </Space>
        <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Save"} onCancel={closeModal} />
      </Form>
    </Modal>
  )
}
