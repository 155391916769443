import { Tag, TagProps } from "antd"
import { CSSProperties, FC } from "react"

interface CustomTagProps extends TagProps {
  value: string
  color: string
  count?: number
  onClick?: VoidFunction
  style?: CSSProperties
}

export const StatusTag: FC<CustomTagProps> = ({ color, value, count, style, onClick }) => {
  return (
    <Tag
      className={"table-tag"}
      bordered={false}
      color={color}
      onClick={onClick}
      style={{
        lineHeight: 1,
        textAlign: "center",
        // padding: "4px 10px",
        borderRadius: 79,
        cursor: onClick ? "pointer" : "unset",
        ...style
      }}
    >
      {value}
      {count ? `: ${count}` : null}
    </Tag>
  )
}
