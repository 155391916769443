import { Col, Divider, Form, Input, Row } from "antd"
import React, { FC } from "react"
import { ContentCard, CustomLabel } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { StepsTypesCheckboxes } from "./ui/StepsTypesCheckboxes"

export const StepOne: FC = () => {
  const [form] = Form.useForm()

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <ContentCard headerIcon={<SvgIcon type={"starShine"} />} cardTitle={"Your Value Proposition"}>
            <CustomLabel
              title={" Value Proposition"}
              subtitle={
                " Add The One Main Key Benefit Clients Would Get By Using Your Company And The Specific Feature That Delivers It."
              }
            />
            <Form.Item name={"valueProposition"}>
              <Input placeholder={"Keep It Brief"} />
            </Form.Item>
            <Divider />
            <CustomLabel
              title={"Testimonials To Include"}
              subtitle={
                "Add A Quote Your Customers Gave With Or Add The ROI Your Customer Got From Your Product Or Services, include Your Customers Company Name."
              }
            />
            <Form.Item name={"testimonials"}>
              <Input.TextArea
                placeholder={`"We felt how powerful Meetz is within 5 minutes of signing up! In the first 10 emails we already got a great lead." - Rob, VP of Sales at Acmef"`}
              />
            </Form.Item>
            <Divider />
            <CustomLabel title={"Your Job Title"} />
            <Form.Item name={"jobTitle"}>
              <Input placeholder={"SDR/ Account Executive/ VP Of Sales/ Founder"} />
            </Form.Item>
            <Divider />
            <CustomLabel title={"Your Company's Name"} />
            <Form.Item name={"companyName"}>
              <Input placeholder={"Meetz"} />
            </Form.Item>
          </ContentCard>
        </Col>
        <Col span={12}>
          <ContentCard headerIcon={<SvgIcon type={"fire"} />} cardTitle={"Types of steps in sequence"}>
            <Form.Item name={"stepTypes"}>
              <StepsTypesCheckboxes />
            </Form.Item>
          </ContentCard>
        </Col>
      </Row>
    </Form>
  )
}
