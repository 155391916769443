import { InboxOutlined } from "@ant-design/icons"
import type { GetProp, UploadProps } from "antd"
import { Button, Checkbox, Flex, Upload, message, Typography, Input } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"
import { UploadingFormModal } from "./ui/UploadingFormModal"
import { AddMembersManuallyModal } from "../../../../../../../../team-members/ui/AddMembersManually"

const { Dragger } = Upload

type ExtractionFileModalProps = {
  open: boolean
  onCancel: VoidFunction
}

interface CsvData {
  title: string
  info: string[]
}

const options = [
  {
    label: "Never Emailed and Called Before And Not Scheduled To Get An Email",
    value: "Never Emailed and Called Before And Not Scheduled To Get An Email",
  },
  {
    label: "Never booked Meeting or scheduled Meeting before",
    value: "Never booked Meeting or scheduled Meeting before",
  },
]

export const UploadProspectsModal: FC<ExtractionFileModalProps> = ({ open, onCancel }) => {
  const [csvData, setCsvData] = useState<CsvData[]>([])
  const [openUploadFormModal, setOpenUploadFormModal] = useState(false)
  const [prospectNameValue, setProspectNameValue] = useState("")
  const [isAddMembersManuallyModalOpened, setIsAddMembersManuallyModalOpened] = useState(false)
  const closeUploadFormModal = () => {
    setOpenUploadFormModal(false)
  }

  const closeModal = () => {
    onCancel()
  }

  const onInputChange=(value: string)=> {
    setProspectNameValue(value)
  }

  const showAddMembersManuallyModal = () => {
    setIsAddMembersManuallyModalOpened(true)
  }

  const closeAddMembersManuallyModal = ()=>{
    setIsAddMembersManuallyModalOpened(false)
  }

  const handleSubmit=()=>{
    setOpenUploadFormModal(true)
  }

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = checkedValues => {
    console.log("checked = ", checkedValues)
  }

  const parseCSV = (csvContent: string): CsvData[] => {
    const lines = csvContent.split('\n');
    const headers = lines[0].split(',');

    const dataMap: Record<string, string[]> = {};

    headers.forEach(header => {
      dataMap[header] = [];
    });

    for (let i = 1; i < lines.length; i++) {
      const line = lines[i].trim();
      if (line === '') continue;

      const values = line.split(',');

      for (let j = 0; j < headers.length; j++) {
        dataMap[headers[j]].push(values[j]);
      }
    }

    return Object.keys(dataMap).map(title => ({
      title,
      info: dataMap[title]
    }));
  };




  const props: UploadProps = {
    name: "file",
    multiple: true,
    accept: ".csv",
    beforeUpload(file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target?.result as string;
        const parsedData = parseCSV(fileContent);
        setCsvData(parsedData);
        setOpenUploadFormModal(true)
      };
      reader.readAsText(file);
      return false;
    },
    onChange(info) {
      const { status, response } = info.file
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`)
        // Assuming response contains data from the uploaded CSV file
        setCsvData(response.data)
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files)
    },
  }

  return (
    <>
      {isAddMembersManuallyModalOpened && <AddMembersManuallyModal isAddContact onClose={closeAddMembersManuallyModal}/>}
    <Modal width={550} open={open} title={"Upload a CSV for multiple leads"} onCancel={closeModal}>
      <UploadingFormModal open={openUploadFormModal} onCancel={closeUploadFormModal} csvData={csvData} />
      <Flex vertical>
        <Flex vertical align={"flex-start"}>
          <Dragger {...props}>
            <p className={"ant-upload-drag-icon"}>
              <InboxOutlined />
            </p>
            <p className={"ant-upload-text"}>Click or drag file to this area to upload</p>
            <p className={"ant-upload-hint"}>
              Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned
              files.
            </p>
          </Dragger>
          <Typography.Paragraph className={"secondary-label-subtitle"} style={{ marginTop: 8}}>
            *By Uploading You Agree to the T&C Policies.
          </Typography.Paragraph>
          <Typography.Title level={4} style={{ margin: "28px 0" }}>
            Or
          </Typography.Title>
          <Typography.Title level={4} style={{ marginTop: 0, marginBottom:8 }}>
            Manually Add Prospects
          </Typography.Title>
          <Button className={"link"} onClick={showAddMembersManuallyModal}>Add</Button>
          <Typography.Title level={4} style={{ marginTop: 28, marginBottom:8 }}>
            Name of prospect upload
          </Typography.Title>
          <Input placeholder={"Enter text"}  onChange={(e) => onInputChange(e.target.value)} required/>
        </Flex>
        <Checkbox.Group style={{ width: "100%", margin:"28px 0" }} onChange={onChange}>
          <Flex vertical gap={10}>
            {options.map(item => (
              <Checkbox value={item.value}>{item.label}</Checkbox>
            ))}
          </Flex>
        </Checkbox.Group>
        <ConfirmButtons onCancel={closeModal} leftButtonTitle={"Back"} rightButtonTitle={"Add"} disabled={!prospectNameValue} handleSubmit={handleSubmit} />
      </Flex>
    </Modal>
    </>
  )
}
