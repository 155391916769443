import { Button, message } from "antd"
import React, { FC, ReactNode, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { CampaignsContext } from "src/components/app/context/CampaignsContext"
import PageLayout from "src/components/layout/PageLayout"
import {StepTwo } from "../AIGenerated/ui"
import { SequenceCreatedModal, StepsInCampaign, AddStepPage, NewCampaign } from "./ui"
import { ConfirmButtons, PageTitle, TabsSwitch } from "src/components/ui"

type Page = {
  key: string
  page: number
  title: ReactNode
  component: ReactNode
  rightHeaderContent?: ReactNode
  bottomHeaderContent?: ReactNode
}

export const FromScratch: FC = () => {
  const [page, setPage] = useState<number>(0)
  const [openModal, setOpenModal] = useState(false)
  const [activeTab, setActiveTab] = useState("Overview");
  const { campaignName, isStepsInCampaignPage, setIsStepsInCampaignPage, selectedStep, setSelectedStep } = useContext(CampaignsContext)
  const navigate = useNavigate()

  useEffect(() => {
    isStepsInCampaignPage && onNextPage()
    setIsStepsInCampaignPage(false)
  }, [isStepsInCampaignPage])

  const handleBack = () => {
    navigate("/campaigns")
    setSelectedStep("")
  }

  const showModal = () => {
    setOpenModal(true)
  }

  const onNextPage = () => {
    if (page !== pages?.length) {
      setPage(prevState => prevState + 1)
    }
  }
  const onPreviousPage = () => {
    setPage(prevStep => prevStep - 1)
  }

  const pages: Page[] = [
    {
      key: "addStepPage",
      page: 0,
      title: <PageTitle title={campaignName} onClick={handleBack}/>,
      component: <AddStepPage />
    },
    { key: "stepsInCampaign",
      page: 1,
      title: <PageTitle title={`Steps in ${campaignName}`} onClick={onPreviousPage}/>,
      rightHeaderContent: isStepsInCampaignPage || selectedStep ? (
          <Button className={"primary"} onClick={showModal}>
            Create Sequence
          </Button>
        ) : null,
      component: <StepsInCampaign selectedStep={selectedStep} />
    },
    { key: "addProspectsPage",
      page: 2,
      title: <PageTitle title={"Add prospects"} onClick={onPreviousPage}/>,
      rightHeaderContent:
        <ConfirmButtons
          width={250}
          rightButtonTitle={"Activate Campaign"}
          leftButtonTitle={"Back"}
          onCancel={onPreviousPage}
          handleSubmit={()=>{{
            onNextPage()
            message.success("New campaign created")
          }
          }}
            />,
      component: <StepTwo/>
    },
    { key: "newCampaign",
      page: 3,
      title: <PageTitle title={campaignName} onClick={onPreviousPage} hasAction/>,
      bottomHeaderContent:
      <>
        <TabsSwitch firstTab={"Overview"} secondTab={"Emails"} thirdTab={"Phone"} activeTab={activeTab} setActiveTab={setActiveTab}/>
        <Button className={"primary"} onClick={()=> navigate("/prospects-data")}>
          Prospects
        </Button>
      </>
     ,
      component: <NewCampaign />
    },
  ]





  const onCancel = () => {
    setOpenModal(false)
  }



  return (
    <PageLayout
      pageTitle={pages[page].title}
      rightHeaderContent={pages[page].rightHeaderContent}
      bottomHeaderContent={pages[page].bottomHeaderContent}
      isTableContent={!selectedStep}
    >
      {pages[page].component}
      <SequenceCreatedModal open={openModal} onCancel={onCancel} onNextPage={onNextPage} />
    </PageLayout>
  )
}
