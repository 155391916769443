import { Select, SelectProps } from "antd"
import { SvgIcon } from "src/shared/icons"
import "./index.less"
import React from "react"

interface Option {
  value: string
  label: string | React.ReactNode
}

interface CustomSelectProps extends SelectProps<string> {
  customClassName?: string
  isSearchSelect?: boolean
  options: Option[]
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  isSearchSelect = false,
  customClassName,
  ...props
}) => {
  return (
    <Select
      options={options}
      suffixIcon={!isSearchSelect ? <SvgIcon type={"selectArrow"} /> : undefined}
      className={`custom-select ${isSearchSelect && "search-select"} ${customClassName}`}
      rootClassName={"custom-select-root"}
      {...props}
    />
  )
}
