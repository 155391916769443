import { Flex, Typography } from "antd"
import React, { FC } from "react"
import { aiChart } from "src/shared/images"
import "./index.less"

export const LockedAiInitiatedEmail: FC = () => {
  return (
    <Flex className={"locked-ai-wrapper"} gap={54} align={"center"}>
      <Flex vertical>
        <Typography.Paragraph className={"locked-title"}>AI-Initiated Email Scheduling locked!</Typography.Paragraph>
        <Typography.Paragraph className={"locked-subtitle"}>
          To unlock it, you need to update plan or activate trial.
        </Typography.Paragraph>
      </Flex>
      <img src={aiChart} alt={"overview chart"} width={218} />
    </Flex>
  )
}
