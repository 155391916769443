import { Row, Space, SpaceProps, Typography } from "antd"
import React, { FC } from "react"
import { SvgIcon } from "src/shared/icons"

interface SignInWithButtonsProps extends SpaceProps {
  title: string
  onGoogleClick?: VoidFunction
  onMicrosoftClick?: VoidFunction
}

export const SignInWithButtons: FC<SignInWithButtonsProps> = ({ title, onGoogleClick, onMicrosoftClick }) => {
  return (
    <Space direction={"vertical"}>
      <Typography.Title level={4}>{title}</Typography.Title>
      <Row align={"middle"} onClick={onGoogleClick} style={{ gap: 16, cursor: "pointer" }}>
        <SvgIcon type={"google"} />
        <Typography.Paragraph>Sign in with Google</Typography.Paragraph>
      </Row>
      <Row align={"middle"} onClick={onMicrosoftClick} style={{ gap: 16, cursor: "pointer" }}>
        <SvgIcon type={"microsoft"} />
        <Typography.Paragraph>Sign in with Microsoft</Typography.Paragraph>
      </Row>
    </Space>
  )
}
