import React, { FC, useState } from "react"
import { Modal, StatusTag } from "../../../../../../ui"
import { Flex, Row, Table,  Typography } from "antd"
import { statusColors } from "../../index"
import "./index.less"

type ViewAnalyticsModalProps = {
  onClose: VoidFunction
}

export const ViewAnalyticsModal: FC<ViewAnalyticsModalProps> = ({ onClose }) => {
  const [variants, setVariants] = useState([
    {
      id: "1",
      name: "Variant A",
      description: {
        title: "Maximize Your Design Power 🚀",
        text: "Hi, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?",
      },
      status: {
        success: 2,
        finished: 3,
        completed: 2,
        skipped: 2,
      },
      isBestSoFar: true,
    },
    {
      id: "2",
      name: "Variant B",
      description: {
        title: "Maximize Your Design Power 🚀",
        text: "Hi, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?",
      },
      status: {
        success: 2,
        finished: 3,
        completed: 2,
        skipped: 2,
      },
      isBestSoFar: false,
    }
  ])

  const columns = [
    {
      title: "Variants",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email Body",
      dataIndex: "description",
      key: "description",
      render: (value: any) => (
        <Row className={"description-wrapper"}>
          <Row className={"description-size-controller"}>
            <Typography.Paragraph className={"description-title"}>{value.title}</Typography.Paragraph>
            <Typography.Paragraph className={"description-text"}>{value.text}</Typography.Paragraph>
          </Row>
        </Row>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value: string, record: any) => (
        <>
          {record.isBestSoFar && (
            <StatusTag
              value={"Best So Far"}
              color={"rgba(43, 143, 130, 0.10)"}
              style={{ marginBottom: 8, padding: "7px 14px", color: "#2B8F82" }}
            />
          )}
          <Flex gap={8}>
            {Object.entries(value).map(([key, value]) => (
              <StatusTag
                key={key}
                value={`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}
                color={statusColors[key]}
                style={{ padding: "7px 14px" }}
              />
            ))}
          </Flex>
        </>
      ),
    },
  ]

  return (
    <Modal width={"fit-content"} rootClassName={"view-analytics-modal"} open={true} title={"All Analytics: Variants Overview"} onCancel={onClose}>
      <Table dataSource={variants} columns={columns} pagination={false} />
    </Modal>
  )
}