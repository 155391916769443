import { Button } from "antd"
import React, { FC, useState } from "react"
import PageLayout from "src/components/layout/PageLayout"
import { ManageEmailDrawer } from "src/components/pages/emails-data"
import { statisticEmailsData } from "src/components/pages/emails-data/info"
import { EmailsTable } from "src/components/pages/emails-data/ui/EmailsTable"
import { StatisticsBar } from "src/components/ui/StatisticsBar"
import { useBreakpoints } from "../../helpers/useBreakpoints"
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal"
import { EmptyState } from "../../components/ui/EmptyState"

interface EmailsProps {
  isTourStep?: boolean;
}

const Emails: FC<EmailsProps> = ({ isTourStep }) => {
  const { isDesktop } = useBreakpoints()
  const [openDrawer, setOpenDrawer] = useState(false)

  const showDrawer = () => {
    setOpenDrawer(true)
  }

  const onCancel = () => {
    setOpenDrawer(false)
  }

  const rightHeaderContent = (
    <Button className={"primary"} onClick={showDrawer}>
      Manage Email Addresses
    </Button>
  )
  const bottomHeaderContent = <StatisticsBar data={statisticEmailsData} />

  return isDesktop ? (
    <PageLayout pageTitle={"Emails"} bottomHeaderContent={statisticEmailsData.length !== 0 ? bottomHeaderContent : null} rightHeaderContent={rightHeaderContent}>
      {statisticEmailsData.length === 0 ? (
        <EmptyState text={"You have no emails created. Create one, and the data will appear here."} />
      ) : (
        <EmailsTable isTourStep={isTourStep} />
      )}
      <ManageEmailDrawer open={openDrawer} onCancel={onCancel} />
    </PageLayout>
  ) : (
    <GoToDesktopModal />
  )
}

export default Emails
