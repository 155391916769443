import { Col, Form, Row} from "antd"
import React, { FC, ReactNode, useEffect, useState } from "react"
import { StepsSequence } from "src/components/pages/campaigns/ui/StepsSequence"
import { sequenceData } from "src/components/pages/campaigns/campaignsData"
import { AiPersonalizedEmail, ManualEmails, PhoneCall, Sms, Task } from "./ui"

type Mode = {
  key: string
  component: ReactNode
}

export const StepThree: FC = () => {
  const [loading, setLoading] = useState(true)
  const [mode, setMode] = useState<string>("manual")
  const [form] = Form.useForm()

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const modes: Mode[] = [
    { key: "manual", component: <ManualEmails /> },
    { key: "ai", component: <AiPersonalizedEmail/> },
    { key: "task", component: <Task/> },
    { key: "sms", component: <Sms/> },
    { key: "phone", component: <PhoneCall/> },
  ]

  const currentMode = modes.find(item => item.key === mode)

  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <StepsSequence data={sequenceData} setMode={setMode} loading={loading}/>
        </Col>
        {!loading ? <Col span={12}>
          {currentMode?.component}
        </Col> : null}
      </Row>
    </Form>
  )
}
