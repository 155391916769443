import { Typography } from "antd"

export const plansData = [
  {
    id: "1",
    name: "Starter",
    iconType: "starter",
    description: "Starter package for individuals to start generating leads using Al outreach.",
    previousPrice: 49,
    currentPrice: 39,
    discountPercent: 20,
    advantages: [
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>250</Typography.Text>{" "}
        Total Prospects Data
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>250</Typography.Text>{" "}
        Total Al Personalized Emails
      </Typography.Paragraph>,
      <Typography.Paragraph>Calendar Scheduling Links</Typography.Paragraph>,
      <Typography.Paragraph>Connect Up To 2 Calendars For Availability</Typography.Paragraph>,
      <Typography.Paragraph>1,000 Prospects You Can Upload Per Monthl</Typography.Paragraph>,
      <Typography.Paragraph>Email Inbox Management + Responses + Analytics All In The Platform</Typography.Paragraph>,
      <Typography.Paragraph>Parallel Dialer (Up To 15 Calls At A Time)</Typography.Paragraph>,
      <Typography.Paragraph>
        Dialer &lt;&gt; <Typography.Text style={{ color: "#0FCA7A" }}> Spotify Integration </Typography.Text> - Stops
        Music/Podcast Once Someone Answers
      </Typography.Paragraph>,
    ],
    priority: 0,
  },
  {
    id: "2",
    name: "Growth",
    iconType: "growth",
    description: "The full power of Meetz to generate a significant amount of leads with ease.",
    previousPrice: 99,
    currentPrice: 79,
    discountPercent: 20,
    advantages: [
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Features In Starter Plus</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>👉 Ai Email Scheduler Plan Included</Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>500</Typography.Text>{" "}Total Prospects Data
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>500</Typography.Text>{" "}Total Al Personalized Emails
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Unlimited</Typography.Text>{" "}Prospects You Can Upload
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Unlimited</Typography.Text>{" "}Email Warm Ups 🔥
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>2000</Typography.Text>{" "}Manual Email Sends
      </Typography.Paragraph>,
      <Typography.Paragraph>Connect Up To 2 Email Addresses</Typography.Paragraph>,
      <Typography.Paragraph>Auto-Rotates Email Addresses In Campaigns</Typography.Paragraph>,
      <Typography.Paragraph>
        Dialer &lt;&gt; Ai Personalized Email Follow Up (Missed Call Feature)
      </Typography.Paragraph>,
      <Typography.Paragraph>Dialer Real Time Prospect Information</Typography.Paragraph>,
    ],
    priority: 1,
  },
  {
    id: "3",
    name: "Ultimate",
    iconType: "ultimate",
    description: "The plan to select if your business is ready to scale quickly.",
    previousPrice: 199,
    currentPrice: 159,
    discountPercent: 20,
    advantages: [
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Features In Growth Plus</Typography.Text>
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>Unlimited</Typography.Text>{" "}Manual Email Sends
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>1500</Typography.Text>{" "}Total Prospects Data
      </Typography.Paragraph>,
      <Typography.Paragraph>
        <Typography.Text style={{ fontWeight: 600 }}>1500</Typography.Text>{" "}Total Al Personalized Emails
      </Typography.Paragraph>,
      <Typography.Paragraph>
        Connect Up To <Typography.Text style={{ fontWeight: 600 }}>6</Typography.Text> Email Addresses
      </Typography.Paragraph>,
    ],
    priority: 2,
  },
]

export const enterprisePlan = {
  id: "0",
  name: "Enterprise",
  iconType: "people",
  description: "Have a team that needs more robust lead generation and cross team scheduling functionality?",
  advantages: [
    <Typography.Paragraph>Lead Generation Synchronizing</Typography.Paragraph>,
    <Typography.Paragraph>AI Deep Learning Optimization Across Team</Typography.Paragraph>,
    <Typography.Paragraph>Higher Limits</Typography.Paragraph>,
    <Typography.Paragraph>Customer Success To Advise</Typography.Paragraph>,
    <Typography.Paragraph>Cross Team Scheduling</Typography.Paragraph>,
    <Typography.Paragraph>More Customizations</Typography.Paragraph>,
  ],
}