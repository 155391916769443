import { App } from "../../../types"

export const prospectData: App.ProspectEntity[] = [
  {
    id: "1",
    firstName: "Aron",
    lastName: "Adams",
    company: "INRIX",
    jobTitle: "Chief Marketing Officer",
    email: "string",
    phoneNumbers: ["+1233453434", "", ""],
    country: "United States",
    state: "New York",
    linkedin: "",
    location: "United States",
    industry: "Software & Internet",
    value: "string",
    twitter: "string",
    headcount: 150,
    revenue: 1500000,
    companyUrl: "www.twitter.com",
    technologies: ["Biometrics", "Blockchain"],
  },
  {
    id: "2",
    firstName: "Nick",
    lastName: "Powills",
    company: "No Limit Agency",
    state: "Arizona",
    jobTitle: "Chief Brand Strategist",
    email: "string",
    phoneNumbers: ["+1233453434", "", "+1233453434"],
    country: "United States",
    linkedin: "string",
    location: "United States",
    industry: "Business Services",
    value: "string",
    twitter: "string",
    headcount: 10,
    revenue: 100000,
    companyUrl: "www.facebook.com",
    technologies: ["Machine vision", "Magnonics"],
  },
  {
    id: "3",
    firstName: "Catherine",
    lastName: "Lacour",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["+1233453434", "+1233453434", ""],
    country: "France",
    state: "Utah",
    linkedin: "",
    location: "France",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 50000,
    revenue: 550000000,
    companyUrl: "www.meetz.ai",
    technologies: ["Biometrics", "Blockchain", "Solid-state transformer"],
  },
  {
    id: "4",
    firstName: "Christa",
    lastName: "Crane",
    company: "Loeb & Loeb",
    jobTitle: "Chief Client Development and Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", ""],
    country: "United States",
    state: "Idaho",
    linkedin: "string",
    location: "United States",
    industry: "Retail",
    value: "string",
    twitter: "string",
    headcount: 1010,
    revenue: 120000000,
    companyUrl: "www.google.com",
    technologies: ["Fusion power", "Commercialization", "Solid-state transformer"],
  },
  {
    id: "5",
    firstName: "Jennifer",
    lastName: "Lopez",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", "1233453434"],
    country: "Moldova",
    state: "Florida",
    linkedin: "",
    location: "Moldova",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 600,
    revenue: 200000,
    companyUrl: "www.blackbaud.com",
    technologies: ["Fusion power", "Solid-state transformer"],
  },
  {
    id: "6",
    firstName: "Jenny",
    lastName: "Young",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", "1233453434"],
    country: "Moldova",
    state: "Florida",
    linkedin: "",
    location: "Moldova",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 600,
    revenue: 200000,
    companyUrl: "www.blackbaud.com",
    technologies: ["Fusion power", "Solid-state transformer"],
  },
  {
    id: "7",
    firstName: "Catherine",
    lastName: "Lacour",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["+1233453434", "+1233453434", ""],
    country: "France",
    state: "Utah",
    linkedin: "",
    location: "France",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 50000,
    revenue: 550000000,
    companyUrl: "www.meetz.ai",
    technologies: ["Biometrics", "Blockchain", "Solid-state transformer"],
  },
  {
    id: "8",
    firstName: "Catrine",
    lastName: "Black",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["+1233453434", "+1233453434", ""],
    country: "France",
    state: "Utah",
    linkedin: "",
    location: "France",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 50000,
    revenue: 550000000,
    companyUrl: "www.meetz.ai",
    technologies: ["Biometrics", "Blockchain", "Solid-state transformer"],
  },
  {
    id: "9",
    firstName: "Aron",
    lastName: "Adams",
    company: "INRIX",
    jobTitle: "Chief Marketing Officer",
    email: "string",
    phoneNumbers: ["+1233453434", "", ""],
    country: "United States",
    state: "New York",
    linkedin: "",
    location: "United States",
    industry: "Software & Internet",
    value: "string",
    twitter: "string",
    headcount: 150,
    revenue: 1500000,
    companyUrl: "www.twitter.com",
    technologies: ["Biometrics", "Blockchain"],
  },
  {
    id: "10",
    firstName: "Christa",
    lastName: "Crane",
    company: "Loeb & Loeb",
    jobTitle: "Chief Client Development and Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", ""],
    country: "United States",
    state: "Idaho",
    linkedin: "string",
    location: "United States",
    industry: "Retail",
    value: "string",
    twitter: "string",
    headcount: 1010,
    revenue: 120000000,
    companyUrl: "www.google.com",
    technologies: ["Fusion power", "Commercialization", "Solid-state transformer"],
  },
  {
    id: "11",
    firstName: "Jennifer",
    lastName: "Lopez",
    company: "Blackbaud",
    jobTitle: "Chief Marketing Officer",
    email: "",
    phoneNumbers: ["", "+1233453434", "1233453434"],
    country: "Moldova",
    state: "Florida",
    linkedin: "",
    location: "Moldova",
    industry: "Software & Internet",
    value: "string",
    twitter: "",
    headcount: 600,
    revenue: 200000,
    companyUrl: "www.blackbaud.com",
    technologies: ["Fusion power", "Solid-state transformer"],
  },
]

export const exportData: App.ExportEntity[] = [
  {
    id: "1",
    name: "Winter Wonderland Discounts",
    prospectsAmount: 100,
  },
  {
    id: "2",
    name: "Skylink Technologies",
    prospectsAmount: 2344,
  },
  {
    id: "3",
    name: "CrystalClear Communications",
    prospectsAmount: 45,
  },
  {
    id: "4",
    name: "SilverPeak Innovations 2.0",
    prospectsAmount: 140,
  },
  {
    id: "5",
    name: "BrightHorizon Solutions",
    prospectsAmount: 110,
  },
  {
    id: "6",
    name: "Starlight Consulting Group",
    prospectsAmount: 1220,
  },
  {
    id: "7",
    name: "Evergreen Enterprises",
    prospectsAmount: 50,
  },
  {
    id: "8",
    name: "Skylink Technologies",
    prospectsAmount: 10430,
  },
  {
    id: "9",
    name: "CrystalClear Communications",
    prospectsAmount: 45,
  },
  {
    id: "10",
    name: "Oceanic Dynamics Ltd.",
    prospectsAmount: 140,
  },
]
export const departmentOptions = [
  {
    key: "1",
    value: "Engineering",
    label: "Engineering",
  },
  {
    key: "2",
    value: "Finance & Administration",
    label: "Finance & Administration",
  },
  {
    key: "3",
    label: "Human Resources",
    value: "Human Resources",
  },
  {
    key: "4",
    label: "IT & IS",
    value: "IT & IS",
  },
  {
    key: "5",
    label: "Marketing",
    value: "Marketing",
  },
  {
    key: "6",
    label: "Operations",
    value: "Operations",
  },
  {
    key: "7",
    label: "Sales",
    value: "Sales",
  },
  {
    key: "8",
    label: "Support",
    value: "Support",
  },
  {
    key: "9",
    label: "Other",
    value: "Other",
  },
]

interface Option {
  value: string
  label: string
  children?: Option[]
}

export const industryOptions: Option[] = [
  {
    label: "Agriculture",
    value: "Agriculture",
    children: [
      {
        label: "Farming",
        value: "Farming",
      },
      {
        label: "Dairy",
        value: "Dairy",
      },
      {
        label: "Tobacco",
        value: "Tobacco",
      },
      {
        label: "Fishery",
        value: "Fishery",
      },
      {
        label: "Mining & Metals",
        value: "Mining & Metals",
      },
      {
        label: "Ranching",
        value: "Ranching",
      },
    ],
  },
  {
    label: "Business Services",
    value: "Business Services",
    children: [
      {
        label: "Design",
        value: "Design",
      },
      {
        label: "Events Services",
        value: "Events Services",
      },
      {
        label: "Human Resources",
        value: "Human Resources",
      },
      {
        label: "Accounting",
        value: "Accounting",
      },
      {
        label: "Environmental Services",
        value: "Environmental Services",
      },
      {
        label: "Market Research",
        value: "Market Research",
      },
      {
        label: "Business Supplies and Equipment",
        value: "Business Supplies and Equipment",
      },
      {
        label: "Public Safety",
        value: "Public Safety",
      },
      {
        label: "Think Tanks",
        value: "Think Tanks",
      },
      {
        label: "Law Enforcement",
        value: "Law Enforcement",
      },
      {
        label: "Facilities Services",
        value: "Facilities Services",
      },
      {
        label: "Import and Export",
        value: "Import and Export",
      },
      {
        label: "Graphic Design",
        value: "Graphic Design",
      },
      {
        label: "Executive Office",
        value: "Executive Office",
      },
      {
        label: "Writing and Editing",
        value: "Writing and Editing",
      },
      {
        label: "Program Development",
        value: "Program Development",
      },
      {
        label: "Animation",
        value: "Animation",
      },
      {
        label: "Alternative Dispute Resolution",
        value: "Alternative Dispute Resolution",
      },
      {
        label: "Translation and Localization",
        value: "Translation and Localization",
      },
      {
        label: "International Trade and Development",
        value: "International Trade and Development",
      },
      {
        label: "Law Practice",
        value: "Law Practice",
      },
      {
        label: "Legal Services",
        value: "Legal Services",
      },
      {
        label: "Management Consulting",
        value: "Management Consulting",
      },
      {
        label: "Marketing and Advertising",
        value: "Marketing and Advertising",
      },
      {
        label: "Printing",
        value: "Printing",
      },
      {
        label: "Professional Training & Coaching",
        value: "Professional Training & Coaching",
      },
      {
        label: "Public Relations and Communications",
        value: "Public Relations and Communications",
      },
      {
        label: "Security and Investigations",
        value: "Security and Investigations",
      },
      {
        label: "Staffing and Recruiting",
        value: "Staffing and Recruiting",
      },
    ],
  },
  {
    label: "Computers & Electronics",
    value: "Computers & Electronics",
    children: [
      {
        label: "Computer Hardware",
        value: "Computer Hardware",
      },
      {
        label: "Computer Networking",
        value: "Computer Networking",
      },
      {
        label: "Semiconductors",
        value: "Semiconductors",
      },
      {
        label: "Consumer Electronics",
        value: "Consumer Electronics",
      },
      {
        label: "Consumer Goods",
        value: "Consumer Goods",
      },
      {
        label: "Individual & Family Services",
        value: "Individual & Family Services",
      },
      {
        label: "Photography",
        value: "Photography",
      },
      {
        label: "Restaurants",
        value: "Restaurants",
      },
    ],
  },
  {
    label: "Education",
    value: "Education",
    children: [
      {
        label: "E-Learning",
        value: "E-Learning",
      },
      {
        label: "Education Management",
        value: "Education Management",
      },
      {
        label: "Higher Education",
        value: "Higher Education",
      },
      {
        label: "Primary/Secondary Education",
        value: "Primary/Secondary Education",
      },
    ],
  },
  {
    label: "Energy & Utilities",
    value: "Energy & Utilities",
    children: [
      {
        label: "Oil & Energy",
        value: "Oil & Energy",
      },
      {
        label: "Renewables & Environment",
        value: "Renewables & Environment",
      },
      {
        label: "Utilities",
        value: "Utilities",
      },
      {
        label: "Banking",
        value: "Banking",
      },
      {
        label: "Insurance",
        value: "Insurance",
      },
      {
        label: "Investment Banking",
        value: "Investment Banking",
      },
      {
        label: "Venture Capital & Private Equity",
        value: "Venture Capital & Private Equity",
      },
      {
        label: "Capital markets",
        value: "Capital markets",
      },
      {
        label: "Fund-Raising",
        value: "Fund-Raising",
      },
      {
        label: "Investment Management",
        value: "Investment Management",
      },
    ],
  },
  {
    label: "Government",
    value: "Government",
    children: [
      {
        label: "Government Administration",
        value: "Government Administration",
      },
      {
        label: "Defense & Space",
        value: "Defense & Space",
      },
      {
        label: "Military",
        value: "Military",
      },
      {
        label: "Museums and Institutions",
        value: "Museums and Institutions",
      },
      {
        label: "Maritime",
        value: "Maritime",
      },
      {
        label: "Government Relations",
        value: "Government Relations",
      },
      {
        label: "Judiciary",
        value: "Judiciary",
      },
      {
        label: "Legislative Office",
        value: "Legislative Office",
      },
    ],
  },
  {
    label: "Healthcare, Pharmaceuticals, & Biotech",
    value: "Healthcare, Pharmaceuticals, & Biotech",
    children: [
      {
        label: "Biotechnology",
        value: "Biotechnology",
      },
      {
        label: "Health, Wellness and Fitness",
        value: "Health, Wellness and Fitness",
      },
      {
        label: "Hospital & Health Care",
        value: "Hospital & Health Care",
      },
      {
        label: "Medical Devices",
        value: "Medical Devices",
      },
      {
        label: "Mental Health Care",
        value: "Mental Health Care",
      },
      {
        label: "Veterinary",
        value: "Veterinary",
      },
      {
        label: "Alternative Medicine",
        value: "Alternative Medicine",
      },
      {
        label: "Medical Practice",
        value: "Medical Practice",
      },
      {
        label: "Pharmaceuticals",
        value: "Pharmaceuticals",
      },
    ],
  },
  {
    label: "Manufacturing",
    value: "Manufacturing",
    children: [
      {
        label: "Automotive",
        value: "Automotive",
      },
      {
        label: "Aviation & Aerospace",
        value: "Aviation & Aerospace",
      },
      {
        label: "Chemicals",
        value: "Chemicals",
      },
      {
        label: "Food & Beverages",
        value: "Food & Beverages",
      },
      {
        label: "Food Production",
        value: "Food Production",
      },
      {
        label: "Electrical/Electronic Manufacturing",
        value: "Electrical/Electronic Manufacturing",
      },
      {
        label: "Packaging and Containers",
        value: "Packaging and Containers",
      },
      {
        label: "Nanotechnology",
        value: "Nanotechnology",
      },
      {
        label: "Shipbuilding",
        value: "Shipbuilding",
      },
      {
        label: "Railroad Manufacture",
        value: "Railroad Manufacture",
      },
      {
        label: "Furniture",
        value: "Furniture",
      },
      {
        label: "Industrial Automation",
        value: "Industrial Automation",
      },
      {
        label: "Machinery",
        value: "Machinery",
      },
      {
        label: "Mechanical or Industrial Engineering",
        value: "Mechanical or Industrial Engineering",
      },
      {
        label: "Plastics",
        value: "Plastics",
      },
      {
        label: "Textiles",
        value: "Textiles",
      },
    ],
  },
  {
    label: "Media & Entertainment",
    value: "Media & Entertainment",
    children: [
      {
        label: "Broadcast Media",
        value: "Broadcast Media",
      },
      {
        label: "Media Production",
        value: "Media Production",
      },
      {
        label: "Printing",
        value: "Printing",
      },
      {
        label: "Motion Pictures and Film",
        value: "Motion Pictures and Film",
      },
      {
        label: "Music",
        value: "Music",
      },
      {
        label: "Newspapers",
        value: "Newspapers",
      },
      {
        label: "Online Media",
        value: "Online Media",
      },
      {
        label: "Performing Arts",
        value: "Performing Arts",
      },
      {
        label: "Publishing",
        value: "Publishing",
      },
    ],
  },
  {
    label: "Real Estate & Construction",
    value: "Real Estate & Construction",
    children: [
      {
        label: "Building Materials",
        value: "Building Materials",
      },
      {
        label: "Civil Engineering",
        value: "Civil Engineering",
      },
      {
        label: "Architecture & Planning",
        value: "Architecture & Planning",
      },
      {
        label: "Commercial Real Estate",
        value: "Commercial Real Estate",
      },
      {
        label: "Glass, Ceramics & Concrete",
        value: "Glass, Ceramics & Concrete",
      },
      {
        label: "Construction",
        value: "Construction",
      },
      {
        label: "Real Estate",
        value: "Real Estate",
      },
    ],
  },
  {
    label: "Retail",
    value: "Retail",
    children: [
      {
        label: "Apparel & Fashion",
        value: "Apparel & Fashion",
      },
      {
        label: "Cosmetics",
        value: "Cosmetics",
      },
      {
        label: "E-Commerce",
        value: "E-Commerce",
      },
      {
        label: "Luxury Goods & Jewelry",
        value: "Luxury Goods & Jewelry",
      },
      {
        label: "Sporting Goods",
        value: "Sporting Goods",
      },
      {
        label: "Supermarkets",
        value: "Supermarkets",
      },
      {
        label: "Wine and Spirits",
        value: "Wine and Spirits",
      },
    ],
  },
  {
    label: "Software & Internet",
    value: "Software & Internet",
    children: [
      {
        label: "Computer Software",
        value: "Computer Software",
      },
      {
        label: "Information Technology and Services",
        value: "Information Technology and Services",
      },
      {
        label: "Internet",
        value: "Internet",
      },
      {
        label: "Computer & Network Security",
        value: "Computer & Network Security",
      },
      {
        label: "Information Services",
        value: "Information Services",
      },
      {
        label: "Computer Games",
        value: "Computer Games",
      },
      {
        label: "Outsourcing/Offshoring",
        value: "Outsourcing/Offshoring",
      },
    ],
  },
  {
    label: "Telecommunications",
    value: "Telecommunications",
    children: [
      {
        label: "Wireless",
        value: "Wireless",
      },
    ],
  },
  {
    label: "Transportation & Storage",
    value: "Transportation & Storage",
    children: [
      {
        label: "Logistics and Supply Chain",
        value: "Logistics and Supply Chain",
      },
      {
        label: "Transportation/Trucking/Railroad",
        value: "Transportation/Trucking/Railroad",
      },
      {
        label: "Airlines/Aviation",
        value: "Airlines/Aviation",
      },
      {
        label: "Warehousing",
        value: "Warehousing",
      },
      {
        label: "Package/Freight Delivery",
        value: "Package/Freight Delivery",
      },
    ],
  },
  {
    label: "Travel, Recreation, and Leisure",
    value: "Travel, Recreation, and Leisure",
    children: [
      {
        label: "Computer Games",
        value: "Computer Games",
      },
      {
        label: "Entertainment",
        value: "Entertainment",
      },
      {
        label: "Hospitality",
        value: "Hospitality",
      },
      {
        label: "Performing Arts",
        value: "Performing Arts",
      },
      {
        label: "Music",
        value: "Music",
      },
      {
        label: "Gambling & Casinos",
        value: "Gambling & Casinos",
      },
      {
        label: "Fine Art",
        value: "Fine Art",
      },
      {
        label: "Arts and Crafts",
        value: "Arts and Crafts",
      },
      {
        label: "Leisure, Travel & Tourism",
        value: "Leisure, Travel & Tourism",
      },
      {
        label: "Recreational Facilities and Services",
        value: "Recreational Facilities and Services",
      },
      {
        label: "Sports",
        value: "Sports",
      },
    ],
  },
  {
    label: "Wholesale & Distribution",
    value: "Wholesale & Distribution",
    children: [
      {
        label: "Wholesale",
        value: "Wholesale",
      },
    ],
  },
  {
    label: "Non-Profit",
    value: "Non-Profit",
    children: [
      {
        label: "Civic & Social Organization",
        value: "Civic & Social Organization",
      },
      {
        label: "Environmental Services",
        value: "Environmental Services",
      },
      {
        label: "Philanthropy",
        value: "Philanthropy",
      },
      {
        label: "Religious Institutions",
        value: "Religious Institutions",
      },
    ],
  },
  {
    label: "Other",
    value: "Other",
    children: [
      {
        label: "Local Business",
        value: "Local Business",
      },
      {
        label: "Research",
        value: "Research",
      },
    ],
  },
]

export const seniorityLevelList = ["C-level", "Manager-Level", "VP-Level", "Staff", "Director-Level", "Other"]
export const headcountList = [
  "0 - 25",
  "25 - 100",
  "100 - 250",
  "250 - 1000",
  "1K - 10K",
  "10K - 50K",
  "50K - 100K",
  "> 100K",
]
export const revenueList = [
  "$0 - 1M",
  "$1M - 10M",
  "$10M - 50M",
  "$50M - 100M",
  "$100M - 250M",
  "$250M - 500M",
  "$500M - 1B",
  "> $1B",
]

export const contactTypeList = ["Any", "Direct Phone", "Mobile Phone"]
