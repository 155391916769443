import React, { FC } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"

type DeleteStepModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const DeleteStepModal: FC<DeleteStepModalProps> = ({ open, onCancel }) => {
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    console.log("Deleted")
    onCancel()
  }

  return (
    <Modal width={530} open={open} title={"Are you sure you want to delete this step?"} onCancel={closeModal}>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Delete"}
        onCancel={closeModal}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}
