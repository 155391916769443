import { Checkbox, CheckboxProps, GetProp, Space } from "antd"
import React, { FC, useState } from "react"

type CheckboxValueType = GetProp<typeof Checkbox.Group, "value">[number]

const plainPhoneOptions = ["Phone Calls", "Voicemail", "SMS"]
const plainEmailsOptions = ["AI Personalized Email", "Manual Emails"]
export const StepsTypesCheckboxes: FC = () => {
  const [checkedPhoneList, setCheckedPhoneList] = useState<CheckboxValueType[]>()
  const [checkedEmailsList, setCheckedEmailsList] = useState<CheckboxValueType[]>()

  const checkAllPhone = plainPhoneOptions?.length === checkedPhoneList?.length
  const phoneIndeterminate = checkedPhoneList && checkedPhoneList.length > 0 && checkedPhoneList.length < plainPhoneOptions.length

  const checkAllEmails = plainEmailsOptions?.length === checkedEmailsList?.length
  const emailsIndeterminate = checkedEmailsList && checkedEmailsList.length > 0 && checkedEmailsList.length < plainEmailsOptions.length

  const onPhoneChange = (list: CheckboxValueType[]) => {
    setCheckedPhoneList(list)
  }

  const onEmailsChange = (list: CheckboxValueType[]) => {
    setCheckedEmailsList(list)
  }

  const onCheckAllPhoneChange: CheckboxProps["onChange"] = e => {
    setCheckedPhoneList(e.target.checked ? plainPhoneOptions : [])
  }

  const onCheckAllEmailsChange: CheckboxProps["onChange"] = e => {
    setCheckedEmailsList(e.target.checked ? plainEmailsOptions : [])
  }

  return (
    <Space direction={"vertical"}>
      <Checkbox indeterminate={phoneIndeterminate} onChange={onCheckAllPhoneChange} checked={checkAllPhone} style={{fontWeight:500}}>
        Phone:
      </Checkbox>
      <Checkbox.Group
        options={plainPhoneOptions}
        value={checkedPhoneList}
        onChange={onPhoneChange}
        disabled={checkedPhoneList?.length===plainPhoneOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />
      <Checkbox indeterminate={emailsIndeterminate} onChange={onCheckAllEmailsChange} checked={checkAllEmails} style={{fontWeight:500}}>
        Emails:
      </Checkbox>
      <Checkbox.Group
        options={plainEmailsOptions}
        value={checkedEmailsList}
        onChange={onEmailsChange}
        disabled={checkedEmailsList?.length===plainEmailsOptions?.length}
        style={{ flexDirection: "column", gap: 8, paddingLeft: 28 }}
      />
      <Checkbox style={{fontWeight:500}}>Automated Booking</Checkbox>
    </Space>
  )
}
