import React, { FC, useState, useEffect } from "react";
import { Button, Col, Flex, Image, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import { ConfirmButtons, Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons";
import { useBreakpoints } from "../../../../helpers/useBreakpoints";
import DashboardsImg from "src/shared/images/dashboards.webp";
import "./index.less";

type CountdownTimerProps = {
  initialTime: number;
  onTimeUp: () => void;
};

const CountdownTimer: FC<CountdownTimerProps> = ({ initialTime, onTimeUp }) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime === 0) {
          clearInterval(timer);
          onTimeUp();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [initialTime, onTimeUp]);

  const formatTime = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <Typography.Paragraph className={"mobile-timer"}>
      {formatTime(timeLeft)}
    </Typography.Paragraph>
  );
};

type UnlockAccessModalProps = {
  open: boolean;
  onCancel: VoidFunction;
  handleSubmit: VoidFunction;
};

export const UnlockAccessModal: FC<UnlockAccessModalProps> = ({ open, onCancel, handleSubmit }) => {
  const { isDesktop } = useBreakpoints();

  const closeModal = () => {
    onCancel();
  };

  const handleTimeUp = () => {
    console.log("Time's up!");
  };

  return isDesktop ? (
    <Modal customClassName={"unlock-modal"} width={567} hasCloseIcon={false} open={open} onCancel={closeModal}>
      <Flex vertical align={"center"}>
        <Typography.Paragraph className={"unlock-modal-title"}>Unlock Meetz</Typography.Paragraph>
        <Typography.Paragraph className={"unlock-modal-subtitle"}>
          The Ultimate Al Lead Generation Platform
        </Typography.Paragraph>
        <Typography.Paragraph className={"unlock-modal-description"}>
          Meetz Makes Lead Generation Super Easy And Effective With Al Personalization And Accurate Outreach Data.{" "}
          <Link className={"watch-link"} to={"https://www.youtube.com/"}>
            Watch
          </Link>{" "}
          how you'll be getting leads with Meetz.
        </Typography.Paragraph>
        <div className={"player-box"}>
          <SvgIcon type={"play"} width={40} style={{ cursor: "pointer" }} />
        </div>
        <Row justify={"space-between"} align={"middle"} wrap={false} style={{ width: "100%" }}>
          <Button className={"link"} style={{ padding: "0 17px" }} onClick={onCancel}>
            Log Out
          </Button>
          <Button className={"primary"} htmlType={"submit"} onClick={handleSubmit}>
            Click Here To Unlock Meetz Now
          </Button>
        </Row>
        <div className={"deal-package-wrapper"}>
          <Typography.Paragraph className={"deal-package-title"}>
            Exclusive Deal.Ai Package • 20% Discount On Your Meetz Plan, For The First 12 Months!
          </Typography.Paragraph>
          <Row className={"gradient1"}></Row>
          <Row className={"gradient2"}></Row>
        </div>
      </Flex>
    </Modal>
  ) : (
    <Col className={"mobile-unlock-modal"}>
      <Image className={"mobile-banner"} preview={false} src={DashboardsImg} alt={"Dashboards"} />
      <Row className={"mobile-gradient1"} />
      <Row className={"mobile-gradient2"} />
      <Flex className={"mobile-box"} vertical={true} align={"center"}>
        <CountdownTimer initialTime={3 * 60 * 60} onTimeUp={handleTimeUp} />
        <Typography.Title level={1} className={"mobile-main-title"}>Exclusive <Typography.Text className={"accent-text"}>Deal.Ai</Typography.Text> Package</Typography.Title>
        <Typography.Paragraph className={"mobile-description"}><Typography.Text className={"discount"}>20% Discount</Typography.Text> On Your Meetz Plan, For The First 12 Months!</Typography.Paragraph>
        <Flex className={"video-player"} vertical={true} justify={"center"} align={"center"}>
          <SvgIcon type={"play2"} width={24} height={24} />
        </Flex>
        <ConfirmButtons className={"confirm-buttons"} rightButtonTitle={"Activate Trial"} handleSubmit={handleSubmit} />
        <Button className={"link meetz-link"} onClick={onCancel}>Meetz.ai</Button>
      </Flex>
    </Col>
  );
};
