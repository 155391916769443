import { Dropdown, MenuProps } from "antd"
import React, { useState } from "react"
import { SvgIcon } from "src/shared/icons"
import { Player } from "./ui/Player"
import "./index.less"

export const PlayerDropdown: React.FC = ({ ...props }) => {
  const [menuVisible, setMenuVisible] = useState(false)

  const handleVisibleChange = (visible: boolean) => {
    setMenuVisible(visible)
  }

  const items: MenuProps["items"] = [
    {
      label: <Player />,
      key: "0",
    },
  ]

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      rootClassName={"player-dropdown"}
      open={menuVisible}
      onOpenChange={handleVisibleChange}
      {...props}
    >
      <SvgIcon type={"playHeader"} style={{ cursor: "pointer" }} />
    </Dropdown>
  )
}
