import { useCurrentEditor } from "@tiptap/react"
import { Flex, Upload, message } from "antd"
import type { UploadProps } from 'antd';
import React, { FC, Fragment, useCallback, useState } from "react"
import { MenuItem } from "../MenuItem"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

const props: UploadProps = {
  name: 'file',
  action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  headers: {
    authorization: 'authorization-text',
  },
  onChange(info) {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
};

export const MenuBar: FC = () => {
  const { editor } = useCurrentEditor()
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const toggleLink = useCallback(() => {
    const isActive = editor?.isActive("link")
    if (isActive) {
      editor?.chain().focus().unsetLink().run()
    } else {
      const previousUrl = editor?.getAttributes("link").href
      const url = window.prompt("URL", previousUrl)

      if (url === null) {
        return
      }

      if (url === "") {
        editor?.chain().focus().extendMarkRange("link").unsetLink().run()
        return
      }

      editor?.chain().focus().extendMarkRange("link").setLink({ href: url }).run()
    }
  }, [editor])

  if (!editor) {
    return null
  }

  const items = [
    // {
    //   icon: "addFile",
    //   title: "Attach File",
    //   action: () => false,
    //   // isActive: () => editor.isActive("bold"),
    // },
    {
      icon: "bold",
      title: "Bold",
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive("bold"),
    },
    {
      icon: "underline",
      title: "Underline",
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive("underline"),
    },
    {
      icon: "italic",
      title: "Italic",
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive("italic"),
    },
    {
      icon: "link",
      title: "Link",
      action: () => toggleLink(),
      isActive: () => editor.isActive("link"),
    },
    {
      icon: "alignLeft",
      title: "Align Left",
      action: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.isActive({ textAlign: 'left' }),
    },
    {
      icon: "alignCenter",
      title: "Align Center",
      action: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.isActive({ textAlign: 'center' }),
    },
    {
      icon: "alignRight",
      title: "Align Right",
      action: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.isActive({ textAlign: 'right' }),
    },
    {
      icon: "unorderedList",
      title: "Unordered List",
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive("bulletList"),
    },
    {
      icon: "orderedList",
      title: "Ordered List",
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive("orderedList"),
    },
    // {
    //   icon: "text-wrap",
    //   title: "Hard Break",
    //   action: () => editor.chain().focus().setHardBreak().run(),
    // },
  ]

  return (
    <>
      {uploadedFiles.map((file:any, index:any) => (
        <div key={index}>
          {file.name}
        </div>
      ))}
      <Flex className={"editor-menubar"} align={"center"} justify={"space-between"}>
        <Upload {...props} >
          <SvgIcon type={"addFile"} style={{cursor: "pointer"}}/>
        </Upload>
        {items.map((item, index) => (
          <Fragment key={index}>
            <MenuItem {...item} />
          </Fragment>
        ))}
      </Flex>
    </>
  )
}