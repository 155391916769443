import { Badge, Button, Divider, Flex, List, Popover, Row, Typography } from "antd"
import React, { useState } from "react"
import { SvgIcon } from "src/shared/icons"
import { getInteractionsOperation } from "src/helpers/getInteractionsOperation"
import { recentInteractionsData } from "src/components/pages/dashboard/dashboardData"
import "./index.less"

export const NotificationDropdown: React.FC = ({ ...props }) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }
  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen)
  }

  const content = (
    <Flex vertical>
      <Row className={"notification-header"} justify={"space-between"} align={"middle"}>
        <Typography.Paragraph className={"notification-header-title"}>Notifications</Typography.Paragraph>
        <SvgIcon type={"close"} onClick={handleClose} style={{ cursor: "pointer" }} />
      </Row>
      <Divider style={{ margin: 0 }} />
      <List
        className={"notification-list"}
        dataSource={recentInteractionsData}
        renderItem={(item: any) => (
          <List.Item key={item.id} className={"notification-item"}>
            {item.time==="now" && <Badge color={"#01AEEF"}/>}
            <Row justify={"space-between"} align={"middle"} wrap={false} style={{ width: "100%" }}>
              <Typography.Paragraph className={"notification-title"}>
                Incoming {item.name.charAt(0)?.toUpperCase() + item.name?.slice(1)}
              </Typography.Paragraph>
              <Typography.Paragraph className={"notification-time"}>{item.time}</Typography.Paragraph>
            </Row>
            <Typography.Paragraph className={"notification-contact"}>
              <span className={"notification-contact-accent"}>{item.contact}</span>{" "}
              {getInteractionsOperation(item.name)}
            </Typography.Paragraph>
            {item.time==="now" && <Row align={"middle"} style={{marginTop:4, gap: 45}}>
              <Button className={"primary"}>Answer</Button>
              <Button className={"link"}>Decline</Button>
            </Row>}
          </List.Item>
        )}
      />
    </Flex>
  )

  return (
    <div className={"notification-container"}>
      <Popover
        content={content}
        trigger={"click"}
        rootClassName={"notification-dropdown"}
        arrow={false}
        open={open}
        onOpenChange={handleOpenChange}
        {...props}
      >
        <Flex>
          <Badge dot={true} offset={[-5, 5]} color={"#01AEEF"} style={{ lineHeight: "normal" }}>
            <SvgIcon type={"bell"} style={{ cursor: "pointer" }} />
          </Badge>
        </Flex>
      </Popover>
    </div>
  )
}
