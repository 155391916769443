import { Flex, Typography } from "antd"
import React, { FC } from "react"
import { useNavigate } from "react-router"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

const actionsToTakeData = {
  phone: 10,
  email: 10,
  task: 2,
}

export const ActionsDataCard: FC = () => {
  const navigate = useNavigate()

  const actionToTakeItem = [
    {
      icon: <SvgIcon type={"phoneCallSelect"} width={28} height={28} />,
      title: `Phone actions to take (${actionsToTakeData.phone})`,
      subtitle: "Start with the phone calls you need to make today.",
      onClick: () => navigate("/campaigns"),
    },
    {
      icon: <SvgIcon type={"personalizedEmailSelect"} width={28} height={28} />,
      title: `Email Actions to take (${actionsToTakeData.email})`,
      subtitle: "Start with the emails you need to send today.",
      onClick: () => navigate("/campaigns"),
    },
    {
      icon: <SvgIcon type={"taskSelect"} width={28} height={28} />,
      title: `Tasks to complete (${actionsToTakeData.task})`,
      subtitle: "Start with the tasks you need to accomplish today.",
      onClick: () => navigate("/campaigns"),
    },
  ]

  return (
    <>
      <Flex vertical gap={8} className={"actions-dashboard-wrapper"}>
        {actionToTakeItem.map((item, index) => (
          <Flex
            key={`${index}-${item.title}`}
            className={"actions-item"}
            align={"center"}
            gap={12}
            onClick={item.onClick}
          >
            {item.icon}
            <Flex gap={2} vertical>
              <Typography.Paragraph className={"actions-item-title"}>{item.title}</Typography.Paragraph>
              <Typography.Paragraph className={"actions-item-subtitle"}>{item.subtitle}</Typography.Paragraph>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </>
  )
}
