import { Checkbox, CollapseProps, Flex, Form, Input } from "antd"
import React, { FC, useState } from "react"
import { Collapse, ConfirmButtons, CustomLabel, Loader, Modal, TextEditor } from "src/components/ui"
import "./index.less"

type ApproveAiVariantsModalProps = {
  open: boolean
  onCancel: VoidFunction
  formData: any
  setFormData: React.Dispatch<any>
}

export const ApproveAiVariantsModal: FC<ApproveAiVariantsModalProps> = ({ open, onCancel, formData, setFormData }) => {
  const [loading, setLoading] = useState(true)
  const isReply = formData?.additionally


  const closeModal = () => {
    onCancel()
    setFormData( {})
  }

  const handleFormSubmit = () => {
    console.log("Approved")
    closeModal()
  }

  const items: CollapseProps["items"] =[
    {
      key: "1",
      label: "Variant A",
      children:
        <>
          {!isReply &&
            <>
              <CustomLabel title={"Subject Line"}/>
              <Form.Item name={"lineA"} initialValue={"Meeting John <> Robert"} >
                <Input/>
              </Form.Item>
            </>}
      <CustomLabel title={"Email Body"}/>
        <TextEditor content={"Hi {{var:first_name;alt:NaN;nat:2}}, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
          "\n" +
          "Warm regards"}/>
      </>
    },
    {
      key: "2",
      label: "Variant B",
      children:
        <>
          {!isReply &&
            <>
              <CustomLabel title={"Subject Line"}/>
              <Form.Item name={"lineB"} initialValue={"Meeting John <> Robert"} >
                <Input/>
              </Form.Item>
            </>}
          <CustomLabel title={"Email Body"}/>
          <TextEditor content={"Hi {{var:first_name;alt:NaN;nat:2}}, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
            "\n" +
            "Warm regards"}/>
      </>
    }
  ]


  return (
      <Modal customClassName={"approve-modal-wrapper"} width={530} open={open} title={"Approve AI A/B Testing Variants"} onCancel={closeModal}>
        {loading ? <Flex vertical className={"loader-wrapper"} justify={"center"}><Loader tip={"Loading... AI is selecting the best variants"}/></Flex>:
          <>
          {!isReply &&
          <>
            <CustomLabel title={"Additionally"}/>
            <Form.Item name={"additionally"} >
              <Checkbox>Reply to incoming email</Checkbox>
            </Form.Item>
          </>}
          <Collapse items={items}/>
          </>
        }
      <ConfirmButtons rightButtonTitle={"Approve"} leftButtonTitle={"Back"} onCancel={closeModal} handleSubmit={handleFormSubmit} disabled={loading}/>
      </Modal>
  )
}
