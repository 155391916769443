import { Button, Checkbox, Form, Input } from "antd"
import React, { FC } from "react"
import { useNavigate } from "react-router"
import { ContentCard, CustomLabel } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"

export const AiPersonalizedEmail: FC = () => {
  const navigate = useNavigate()

  return (
    <ContentCard headerIcon={<SvgIcon type={"personalizedEmail"} />} cardTitle={"AI Personalized Email"}>
      <CustomLabel title={"Additionally"} />
      <Form.Item name={"aiAdditionally"}>
        <Checkbox defaultChecked>Reply to incoming email</Checkbox>
      </Form.Item>
      <CustomLabel
        title={"AI Email settings"}
        subtitle={
          "AI personalized email will be customized for each prospect individually through Meetz. Below, you can fine-tune the advanced settings."
        }
      />
      <Button
        className={"link"}
        style={{ marginBottom: 22 }}
        onClick={() => navigate("/campaign/ai-generated/test-ai-settings")}
      >
        Test AI Email
      </Button>
      <CustomLabel title={"Example"} />
      <Form.Item
        name={"aiExample"}
        initialValue={
          "Hi Aron, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
          "\n" +
          "Warm regards"
        }
      >
        <Input.TextArea autoSize />
      </Form.Item>
    </ContentCard>
  )
}
