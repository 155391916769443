import { Form, Input, message, Progress, Radio, Row, Space, Typography } from "antd"
import React, { FC, useEffect, useState } from "react"
import { ConfirmButtons, Modal, RadioContentWithTooltip } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { prospectData } from "../../info"
import { HeaderTagsBar } from "../ExploreTable/ui/HeaderTagsBar"
import "./index.less"

type ExportContactsProps = {
  open: boolean
  onCancel: VoidFunction
  totalSelectedProspects: number
  setTotalSelectedProspects: React.Dispatch<React.SetStateAction<React.Key[]>>
  selectedRowKeys: React.Key[]
}

const exportTooltipText =
  "Meetz will reverify your prospects information in real time.\n" +
  "We will recheck where they work in real time when you export your list. If we can't reverify their details we won't charge you the prospect credits and won't extract them.\n" +
  "This option doesn't cost extra and is recommended to use."

export const ExportProspectsModal: FC<ExportContactsProps> = ({
  open,
  onCancel,
  totalSelectedProspects,
  setTotalSelectedProspects,
  selectedRowKeys,
}) => {
  const [form] = Form.useForm()
  const [bulkLength, setBulkLength] = useState<number>(0)
  const [messageApi, contextHolder] = message.useMessage()
  const [formData, setFormData] = useState<any>()

  const messageContent = (
    <Space direction={"vertical"}>
      <Row align={"middle"} style={{ gap: 8 }}>
        <SvgIcon type={"infoFilled"} />
        <Typography.Paragraph className={"bulk-message-title"}>{`Exporting ${
          selectedRowKeys.length || totalSelectedProspects
        } of ${prospectData.length} Contacts`}</Typography.Paragraph>
      </Row>
      <Progress strokeColor={"#01AEEF"} size={"small"} percent={75} showInfo={false} />
    </Space>
  )

  const success = () => {
    messageApi
      .open({
        content: messageContent,
        className: "custom-message",
        duration: 2.5,
        style: {
          padding: "10px 16px",
        },
      })
      .then(() => {
        message.success("Exporting finished", 2.5)
      })
  }

  useEffect(() => {
    const selectedBulkByInput: string[] = []
    for (let i = 0; i < bulkLength && i < prospectData.length; i++) {
      selectedBulkByInput.push((i + 1).toString())
    }

    setTotalSelectedProspects(selectedBulkByInput)
  }, [bulkLength])

  const closeModal = () => {
    onCancel()
    form.resetFields()
    setBulkLength(0)
  }

  const onValueChange = (changedValues: any, allValues: any) => {
    setFormData(allValues)
  }

  const handleFormSubmit = () => {
    console.log("Export test")
    onCancel()
    form.resetFields()
    setBulkLength(0)
    success()
  }

  const typeSelected = form.getFieldValue("type")

  return (
    <Modal
      width={569}
      customClassName={"export-selected-contacts-modal"}
      open={open}
      title={"Export Prospects"}
      onCancel={closeModal}
    >
      <HeaderTagsBar
        totalAmount={prospectData.length}
        selectedAmount={selectedRowKeys.length || totalSelectedProspects}
      />
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onValuesChange={onValueChange}
        onFinish={handleFormSubmit}
      >
        <Form.Item className={"export-name-item"} name={"name"} label={"Export Name"}>
          <Input placeholder={"Extraction Name"} />
        </Form.Item>
        <Form.Item className={"export-type-item"} name={"type"} label={"Choose Export Type"}>
          <Radio.Group style={{ width: "100%" }}>
            <Space direction={"vertical"}>
              <Radio value={"Export Selected Prospects"}>{"Export Selected Prospects"}</Radio>
              <Radio value={"Bulk Export"}>{"Bulk Export"}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {typeSelected !== "Bulk Export" ? (
          <Form.Item className={"export-method-item"} name={"exportType"} label={"How Export?"}>
            <Radio.Group style={{ width: "100%" }}>
              <Space direction={"vertical"}>
                <Radio value={"Export Only The Verified Prospects"}>
                  <RadioContentWithTooltip
                    radioTitle={"Export Only The Verified Prospects"}
                    tooltipTitle={exportTooltipText}
                  />
                </Radio>
                <Radio value={"Export All"}>{"Export All"}</Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        ) : (
          <Form.Item name={"bulkAmount"} label={"Amount To Export From The Filtered"}>
            <Input placeholder={"1"} onChange={e => setBulkLength(Number(e.target.value))} />
          </Form.Item>
        )}

        {contextHolder}
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Export"}
          onCancel={closeModal}
          disabled={!typeSelected}
        />
      </Form>
    </Modal>
  )
}
