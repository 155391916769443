import { Button, Checkbox, CollapseProps, Flex, List, Popconfirm, Row, Space, Typography } from "antd"
import React, { FC, useState } from "react"
import { App } from "src/types"
import noActions from "src/shared/images/noActions.png"
import manWithSkateboard from "src/shared/images/manWithSkateboard.webp"
import {
  CallSetupModal,
  MoveProspectsSelectStepModal,
  SelectEmailModal,
} from "src/components/pages/campaigns/CampaignDetails/ui"
import { Collapse, ContentCard } from "src/components/ui"
import { getActionButtonTitle, getActionItemButtonTitle } from "src/helpers"
import { SvgIcon } from "src/shared/icons"
import { useNavigate } from "react-router"
import "./index.less"

interface ActionsForTodayProps {
  data: App.AllAction[]
}

export const ActionsForTodayCard: FC<ActionsForTodayProps> = ({ data }) => {
  const [openModal, setOpenModal] = useState(false)
  const [openSelectStepModal, setOpenSelectStepModal] = useState(false)
  const [openCallSetupModal, setOpenCallSetupModal] = useState(false)
  const [selectedCounts, setSelectedCounts] = useState<{ [key: string]: number }>({})
  const [selectedActions, setSelectedActions] = useState<string[]>([])
  const [visibleItems, setVisibleItems] = useState<number>(7)
  const [isPlanActivated, setIsPlanActivated] = useState(true)
  const navigate = useNavigate()

  const onLoadMore = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + 7)
  }

  const handleUpdatePlanClick = () => {
    navigate("/billing")
  }

  const loadMoreBtn = (
    <div
      style={{
        textAlign: "center",
        marginTop: 12,
        height: 32,
        lineHeight: "32px",
      }}
    >
      <Button className={"link"} onClick={onLoadMore}>
        See more
      </Button>
    </div>
  )

  const showModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const showSelectStepModalModal = () => {
    setOpenSelectStepModal(true)
  }

  const showCallSetupModal = () => {
    setOpenCallSetupModal(true)
  }

  const onCancel = () => {
    setOpenModal(false)
    setOpenCallSetupModal(false)
    setOpenSelectStepModal(false)
  }

  const handleCheckboxChange = (itemTitle: string, checked: boolean, action: string | undefined) => {
    setSelectedCounts(prevCounts => ({
      ...prevCounts,
      [itemTitle]: checked ? (prevCounts[itemTitle] || 0) + 1 : (prevCounts[itemTitle] || 0) - 1,
    }))
    if (checked && action) {
      setSelectedActions(prevSelectedActions => [...prevSelectedActions, action])
    } else {
      setSelectedActions(prevSelectedActions => prevSelectedActions.filter(item => item !== action))
    }
  }

  const totalInfoCount = data.reduce((total, action) => total + action.info.length, 0)

  const title = (
    <Row style={{ gap: 16 }}>
      <Typography.Paragraph>
        {`${selectedActions.length} of ${totalInfoCount} ${
          selectedActions.length === 1 ? "prospect is selected" : "prospects are selected"
        }`}
      </Typography.Paragraph>
      <Button
        className={"link"}
        onClick={() => {
          setSelectedActions([])
          setSelectedCounts({})
        }}
      >
        Deselect all
      </Button>
    </Row>
  )

  const items: CollapseProps["items"] = data.map((item, index) => {
    const selectedCount = selectedCounts[item.title] || 0
    const label = selectedCount !== undefined && selectedCount !== 0 ? `${item.title} (${selectedCount})` : item.title

    return {
      key: item.id,
      label: label,
      extra: (
        <Button
          key={`${index}-${item.id}`}
          className={"link"}
          onClick={getActionButtonTitle(item.title) === "Send All" ? showModal : showCallSetupModal}
        >
          {getActionButtonTitle(item.title)}
        </Button>
      ),
      children: (
        <List
          key={`${index}-${item.id}`}
          itemLayout={"horizontal"}
          loadMore={loadMoreBtn}
          dataSource={item.info.slice(0, visibleItems)}
          renderItem={(it: any, index) => (
            <List.Item
              key={`${index}-${it.id}`}
              actions={[<Button className={"link"} onClick={() => (item.title === "Emails" || item.title === "SMS" ? showModal() : closeModal())}>{getActionItemButtonTitle(item.title)}</Button>]}
            >
              <Space direction={"vertical"} size={4}>
                {item.title === "Tasks" ? (
                  <Typography.Paragraph>{it.name}</Typography.Paragraph>
                ) : (
                  <>
                    <Popconfirm
                      title={title}
                      placement={"topLeft"}
                      showCancel={false}
                      style={{ width: 528 }}
                      okType={"link"}
                      okText={"Move"}
                      onConfirm={showSelectStepModalModal}
                      icon={false}
                    >
                      <Checkbox
                        checked={selectedActions.includes(it.id)}
                        onChange={e => handleCheckboxChange(item.title, e.target.checked, it.id)}
                      >
                        <Typography.Paragraph className={"contact-name"}>{it.name}</Typography.Paragraph>
                      </Checkbox>
                    </Popconfirm>
                    <Typography.Paragraph className={"contact-data"}>{it.contact}</Typography.Paragraph>
                  </>
                )}
              </Space>
            </List.Item>
          )}
        ></List>
      ),
    }
  })

  return (
    <>
      <ContentCard
        customClassName={"all-actions-card"}
        headerIcon={<SvgIcon type={"allActions"} />}
        cardTitle={"All Actions for Today"}
      >
        {!isPlanActivated ? (
          <Flex vertical justify={"center"}>
            <Flex justify={"center"}>
              <img src={manWithSkateboard} alt={"Plan not activated"} width={198} height={200} />
            </Flex>
            <Typography.Paragraph className={"empty-data"} style={{ textAlign: "center", marginTop: 22 }}>
              Start your free trial to activate this campaign
            </Typography.Paragraph>
            <Button className={"link"} style={{ marginTop: 8 }} onClick={handleUpdatePlanClick}>Update Plan</Button>
          </Flex>
        ) : data ? (
          <Collapse items={items} />
        ) : (
          <Flex vertical gap={22} justify={"center"}>
            <Flex justify={"center"}>
              <img src={noActions} alt={"No actions"} width={309} />
            </Flex>
            <Typography.Paragraph className={"empty-data"} style={{ textAlign: "center" }}>
              No Actions For Today.
            </Typography.Paragraph>
          </Flex>
        )}
      </ContentCard>
      <SelectEmailModal data={data[0].info} open={openModal} onCancel={onCancel} />
      <MoveProspectsSelectStepModal open={openSelectStepModal} onCancel={onCancel} />
      <CallSetupModal data={data[1].info} open={openCallSetupModal} onCancel={onCancel} />
    </>
  )
}
