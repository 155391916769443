import { Button, Flex, Image, Row, Typography } from "antd"
import React from "react"
import runningManWithBox from "src/shared/images/runningManWithBox.webp"
import "./index.less"

export const GoToDesktopModal = () => {
  return (
    <Flex className={"go-to-desktop-modal"} vertical={true} align={"center"}>
      <Row className={"mobile-gradient1"} />
      <Row className={"mobile-gradient2"} />
      <Flex className={"box"} vertical={true} align={"center"}>
        <Image className={"banner"} preview={false} src={runningManWithBox} />
        <Typography.Title className={"main-title"}>Go to <Typography.Text className={"accent-text"}>Desktop App!</Typography.Text></Typography.Title>
        <Typography.Paragraph className={"description"}>To view all your new leads, switch to our desktop app for advanced features and business management tools.</Typography.Paragraph>
      </Flex>
      <Button className={"link meetz-link"}>Meetz.ai</Button>
    </Flex>
  )
}