import { Form, Input, Radio, Select, TimePicker, Typography } from "antd"
import dayjs from "dayjs"
import React, { FC, useState } from "react"
import { WeekTimePlanner } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

export const EmailSchedulingTab: FC = () => {
  const [selectedTimeFormat, setSelectedTimeFormat] = useState("24h")
  const [form] = Form.useForm()
  const timeFormat = selectedTimeFormat === "24h" ? "HH:mm" : "h:mm A"

  return (
    <Form className={"email-scheduling-form"} form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Form.Item name={"firstName"} label={"Your First Name"}>
        <Input placeholder={"Laura"} />
      </Form.Item>
      <Form.Item name={"timezone"} label={"Timezone"}>
        <Select placeholder={"American/New_York EST"} />
      </Form.Item>
      <Form.Item name={"timeFormat"} label={"Select Time Format"}>
        <Radio.Group defaultValue={"24h"} size={"middle"} onChange={e => setSelectedTimeFormat(e.target.value)}>
          <Radio.Button value={"24h"}>24h</Radio.Button>
          <Radio.Button value={"AM/PM"}>AM/PM</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Typography.Paragraph className={"sending-times-title"}>Sending times</Typography.Paragraph>
      <WeekTimePlanner timeFormat={timeFormat} />
      <Form.Item name={"workingHours"} label={"Working Hours For Prospect"} style={{ marginTop: 24 }}>
        <TimePicker.RangePicker
          suffixIcon={<SvgIcon type={"arrowSuffix"} />}
          defaultValue={[dayjs("4:00", timeFormat), dayjs("9:00", timeFormat)]}
          format={timeFormat}
        />
      </Form.Item>
    </Form>
  )
}
