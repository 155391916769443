import { Divider, Row, Typography } from "antd"
import { FC, useState } from "react"
import "./index.less"

export type StatisticItem = {
  value: number
  title: string
}

interface StatisticProps {
  data: StatisticItem[]
}

export const StatisticsBar: FC<StatisticProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const handleButtonClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index)
  }
  return (
    <Row className={"statistics-wrapper"} justify={"space-around"} align={"middle"} wrap={false}>
      {data.map((item, index) => {
        return (
          <>
            <Row
              key={`${index}-${item.title}`}
              className={`statistics-btn ${index === activeIndex ? "active" : ""}`}
              align={"middle"}
              onClick={() => handleButtonClick(index)}
            >
              <Typography.Paragraph className={"statistics-value"}>{item.value}</Typography.Paragraph>
              <Typography.Paragraph className={"statistics-title"}>{item.title}</Typography.Paragraph>
            </Row>
            {data.length !== index + 1 ? (
              <Divider type={"vertical"} key={`${item.title}-${index}`} style={{ height: 56 }} />
            ) : null}
          </>
        )
      })}
    </Row>
  )
}
