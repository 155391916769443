import { Flex, Typography } from "antd"
import { FC } from "react"
import "./index.less"

export const LockedActionsToTake: FC = () => {
  return (
    <Flex className={"locked-actions-wrapper"} vertical gap={8}>
      <Typography.Paragraph className={"locked-title"}>Actions to take locked!</Typography.Paragraph>
      <Typography.Paragraph className={"locked-subtitle"}>
        To unlock it, you need to update plan or activate trial.
      </Typography.Paragraph>
    </Flex>
  )
}
