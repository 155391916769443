import { FC, lazy } from "react"
import { Navigate, RouteObject } from "react-router-dom"
import { Loader } from "../components/layout"
import { RegisteredLayout } from "../components/layout/RegisteredLayout"

const Dashboard = lazy<FC>(() => import("./dashboard"))
const ProspectData = lazy<FC>(() => import("./prospect-data"))
const Campaigns = lazy<FC>(() => import("./campaigns"))
const Emails = lazy<FC>(() => import("./emails-data"))
const PhoneCalls = lazy<FC>(() => import("./phone-calls"))
const MeetingLinks = lazy<FC>(() => import("./meeting-links"))
const TeamMembers = lazy<FC>(() => import("./team-members"))
const BookMeeting = lazy<FC>(() => import("./book-meeting"))
const BookMeetingFromEmail = lazy<FC>(() => import("./book-meeting-from-email"))
const Billing = lazy<FC>(() => import("./billing"))
const SignIn = lazy<FC>(() => import("./sign-in"))
const SignUp = lazy<FC>(() => import("./sign-up"))
const Deals = lazy<FC>(() => import("./deals"))

const routes: RouteObject[] = [
  {
    Component: RegisteredLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: ProspectData,
        path: "/prospect-data",
      },
      {
        Component: Dashboard,
        path: "/dashboard",
      },
      {
        Component: Deals,
        path: "/deals",
      },
      {
        Component: Campaigns,
        path: "/campaigns",
      },
      {
        Component: Campaigns,
        path: "/campaign",
        children: [
          {
            Component: Campaigns,
            path: "ai-generated",
            children: [
              {
                Component: Campaigns,
                path: "test-ai-settings",
              },
            ],
          },
          {
            Component: Campaigns,
            path: "from-scratch",
          },
          {
            Component: Campaigns,
            path: "from-saved",
          },
          {
            Component: Campaigns,
            path: ":id",
            children: [
              {
                Component: Campaigns,
                path: "prospects",
              },
              {
                Component: Campaigns,
                path: "edit-sequence",
              },
            ],
          },
        ],
      },
      {
        Component: Emails,
        path: "/mailing",
      },
      {
        Component: PhoneCalls,
        path: "/phone-calls",
      },
      {
        Component: MeetingLinks,
        path: "/meeting-links",
      },
      {
        Component: TeamMembers,
        path: "/team-members",
        children: [
          {
            Component: TeamMembers,
            path: ":id",
          },
        ],
      },
      {
        Component: Billing,
        path: "/billing",
      },
      {
        element: <Navigate to={"/dashboard"} replace />,
        path: "*",
      },
    ],
  },
  {
    Component: BookMeeting,
    path: "/book-meeting",
  },
  {
    Component: BookMeetingFromEmail,
    path: "/book-meeting-from-email",
  },
  {
    Component: SignIn,
    path: "/sign-in",
  },
  {
    Component: SignUp,
    path: "/sign-up",
  },
]

export default routes
