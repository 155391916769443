import { Button } from "antd"
import React, { FC, useState } from "react"
import { ContentCard, CustomLabel } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { AddVoicemailModal } from "../AddVoicemailModal"

export const PhoneCall: FC = () => {
  const [openModal, setOpenModal] = useState(false)

  const showModal = () => {
    setOpenModal(true)
  }
  const onCancel = () => {
    setOpenModal(false)
  }

  return (
    <ContentCard headerIcon={<SvgIcon type={"phoneCall"} />} cardTitle={"Phone Call"}>
      <CustomLabel title={"Voicemail"} />
      <Button className={"link"} onClick={showModal}>
        Add
      </Button>
      <AddVoicemailModal open={openModal} onCancel={onCancel} />
    </ContentCard>
  )
}
