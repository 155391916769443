import { Collapse as AntCollapse, CollapseProps } from "antd"
import React, { FC, PropsWithChildren } from "react"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

interface CustomCollapseProps extends CollapseProps {
  customClassName?: string
  items: any
}

export const Collapse: FC<PropsWithChildren<CustomCollapseProps>> = ({ customClassName, items, ...props }) => {
  return (
    <AntCollapse
      className={`collapse-wrapper ${customClassName}`}
      items={items}
      expandIcon={({ isActive }) => (
        <SvgIcon type={"arrowCollapse"} style={{ transform: isActive ? "rotate(0deg)" : "rotate(180deg)" }} />
      )}
      collapsible={"header"}
      ghost
      {...props}
    />
  )
}
