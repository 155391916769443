import { Button, Flex, Row, Space, Typography } from "antd"
import { FC } from "react"
import { Timer } from "../../billing/ui/PlansModal"
import "./index.less"

export const ExclusiveOfferBanner: FC<{ setIsDashboardLocked: React.Dispatch<React.SetStateAction<boolean>> }> = ({
  setIsDashboardLocked,
}) => {
  return (
    <Flex className={"exclusive-offer-wrapper"} justify={"space-between"} align={"center"}>
      <Space direction={"vertical"}>
        <Row style={{ gap: 38 }}>
          <Typography.Paragraph className={"exclusive-offer-title"}>Exclusive Deal.Ai Package</Typography.Paragraph>
          <Timer />
        </Row>
        <Typography.Paragraph className={"exclusive-offer-subtitle"}>
          <span className={"exclusive-offer-subtitle-accent"}>20% Discount</span> On Your Meetz Plan, For The First 12
          Months!
        </Typography.Paragraph>
      </Space>
      <Button className={"activate-btn"} onClick={() => setIsDashboardLocked(false)}>
        Activate Trial
      </Button>
      <Row className={"gradient1"}></Row>
      <Row className={"gradient2"}></Row>
    </Flex>
  )
}
