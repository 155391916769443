import { CSSProperties, FC } from "react"
import { StatusTag } from "src/components/ui"

export const EmailsStatus: FC<{ status: string, key?: string, count?: number, style?: CSSProperties }> = ({ status, count, ...props }) => {
  switch (status) {
    case "DELIVERED":
      return <StatusTag color={"purple"} value={"Delivered"} {...props} />
    case "PENDING":
      return <StatusTag color={"magenta"} value={"Pending"} {...props} />
    case "LIKELY FORWARDED":
      return <StatusTag color={"gold"} value={"Bounced"} {...props} />
    case "OPENED":
      return <StatusTag color={"cyan"} value={"Opened"} count={count} {...props} />
    case "REPLIED":
      return <StatusTag color={"processing"} value={"Replied"} count={count} {...props} />
    default:
      return <StatusTag color={"success"} value={"Booked Meetings"} count={count} {...props} />
  }
}
