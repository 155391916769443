import React, { FC } from "react"
import { CreateCampaignMethodSelect } from "src/components/pages/campaigns/ui/CreateCampaignModal/ui/CreateCampaignMethodSelect"
import { DashboardCard } from "../ui/DashboardCard"
import { ActionsDataCard } from "./ActionsDataCard"
import "./index.less"

interface ActionsToTakeCardProps {
  data?: any
}

export const ActionsToTakeCard: FC<ActionsToTakeCardProps> = ({ data = true }) => {
  return (
    <DashboardCard
      title={"Actions To Take"}
      subtitle={
        !data ? "To display actions, you need to first create a campaign and select the appropriate steps." : ""
      }
      customClassName={"actions-to-take-wrapper"}
    >
      {data ? <ActionsDataCard /> : <CreateCampaignMethodSelect isModalContent={false} />}
    </DashboardCard>
  )
}
