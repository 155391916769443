import { Table, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC, useEffect, useState } from "react"
import { StatusTag } from "src/components/ui"
import { totalAmountEmailSchedulingData } from "../../../dashboardData"
import "./index.less"

export const AiInitiatedEmailTable: FC = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  const columns: ColumnsType<any> = [
    {
      title: "Guests Name",
      dataIndex: "name",
      key: "Guests Name",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Last Engaged",
      dataIndex: "lastEngaged",
      key: "lastEngaged",
    },
    {
      title: "Next Follow Up",
      dataIndex: "nextFollowUp",
      render: value =>
        value ? (
          <StatusTag value={"Completed"} color={"geekblue"} />
        ) : (
          <StatusTag value={"Incomplete"} color={"error"} />
        ),
      key: "nextFollowUp",
    },
    {
      title: "Meeting Status",
      dataIndex: "meetingStatus",
      render: value =>
        value ? <StatusTag value={"Booked"} color={"cyan"} /> : <StatusTag value={"Not Booked"} color={"default"} />,
      key: "meetingStatus",
    },
  ]

  return (
    <>
      <Typography.Title level={4} style={{ marginTop: 30 }}>
        Total Amount for December 2024{" "}
      </Typography.Title>
      <Table
        className={"ai-initiated-email-table"}
        dataSource={totalAmountEmailSchedulingData}
        columns={columns}
        loading={loading}
        rowKey={"id"}
        pagination={{ pageSize: 5 }}
      />
    </>
  )
}
