import { Checkbox, Col, Form, FormInstance, FormItemProps, Row } from "antd"
import React, { FC } from "react"
import { FilterTagsList } from "src/components/ui"

interface CheckboxGroupFilterItem extends FormItemProps {
  className?: string
  data: string[]
  form: FormInstance
  fieldName: string
  checkboxList: string[]
}
export const CheckboxGroupFilterItem: FC<CheckboxGroupFilterItem> = ({
  className,
  data,
  form,
  fieldName,
  checkboxList,
}) => {
  return (
    <>
      <Form.Item className={className} name={fieldName}>
        <Checkbox.Group style={{ width: "100%" }}>
          <Row gutter={[12, 12]}>
            {checkboxList.map((checkbox, index) => (
              <Col key={`${index}-${checkbox}`} span={className ? 16 : 12}>
                <Checkbox value={checkbox}>{checkbox}</Checkbox>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      </Form.Item>
      <FilterTagsList data={data} form={form} fieldToUpdate={fieldName} />
    </>
  )
}
