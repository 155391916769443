import { Input } from "antd"
import React, { FC, useContext } from "react"
import { useNavigate } from "react-router"
import { CampaignsContext } from "src/components/app/context/CampaignsContext"
import { ConfirmButtons, CustomLabel, Modal } from "src/components/ui"

type CreateFromScratchModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const CreateFromScratchModal: FC<CreateFromScratchModalProps> = ({ open, onCancel }) => {
  const navigate = useNavigate()
  const { setCampaignName } = useContext(CampaignsContext)
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    navigate("/campaign/from-scratch")
    onCancel()
  }

  return (
    <Modal width={530} open={open} title={"New Campaign"} onCancel={closeModal}>
      <CustomLabel title={"Name"} isFontBold />
      <Input placeholder={"Enter text"} style={{ marginBottom: 28 }} onChange={e => setCampaignName(e.target.value)} />
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Create"}
        onCancel={closeModal}
        handleSubmit={handleFormSubmit}
      />
    </Modal>
  )
}
