import { Button, Flex, Row, Typography } from "antd"
import { FC } from "react"
import { useNavigate } from "react-router"
import "./index.less"

interface UpdatePlanBannerProps {
  title: string
  subtitle: string
}
export const UpdatePlanBanner: FC<UpdatePlanBannerProps> = ({ title, subtitle }) => {
  const navigate = useNavigate()
  return (
    <Flex className={"create-campaign-modal-banner"} align={"center"} gap={127}>
      <Flex vertical gap={2} style={{ maxWidth: 191 }}>
        <Typography.Paragraph className={"banner-title"}>{title}</Typography.Paragraph>
        <Typography.Paragraph className={"banner-subtitle"}>{subtitle}</Typography.Paragraph>
      </Flex>
      <Button className={"update-btn"} onClick={() => navigate("/billing")}>
        Update Plan
      </Button>
      <Row className={"gradient1"}></Row>
      <Row className={"gradient2"}></Row>
    </Flex>
  )
}
