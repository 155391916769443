import { Checkbox, Flex, Form, GetProp, Input, Typography } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, CustomSelect, Modal, TableHeader } from "src/components/ui"
import { addressesAvailable } from "../../../info"

type CheckAddressModalProps = {
  open: boolean
  onCancel: VoidFunction
}

const countriesOptions = [
  {
    label: "United States",
    value: "United States",
  },
  {
    label: "Ukraine",
    value: "Ukraine",
  },
  {
    label: "United Arabian Emirates",
    value: "United Arabian Emirates",
  },
]

export const CheckAddressModal: FC<CheckAddressModalProps> = ({ open, onCancel }) => {
  const [isAddressAvailable, setIsAddressAvailable] = useState(true)
  const [form] = Form.useForm()
  const closeModal = () => {
    form.resetFields()
    onCancel()
  }
  const handleFormSubmit = () => {
    console.log(" test")
    onCancel()
    form.resetFields()
  }

  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = checkedValues => {
    console.log("checked = ", checkedValues)
  }

  return (
    <Modal width={530} open={open} title={"Check Address"} onCancel={closeModal}>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Form.Item name={"address"} label={"Look Up Address"} style={{ marginBottom: 0 }}>
          <TableHeader searchPlaceholder={"New York"} hasMaxWidth={false} />
        </Form.Item>
        {isAddressAvailable ? (
          <Checkbox.Group className={"phones-available-radio-group"} onChange={onChange} style={{ width: "100%" }}>
            <Flex vertical gap={8} style={{ maxHeight: 104, overflowY: "scroll", marginBottom: 28 }}>
              {addressesAvailable.map((address, index) => (
                <Checkbox key={`${index}-${address}`} value={address}>
                  {address}
                </Checkbox>
              ))}
            </Flex>
          </Checkbox.Group>
        ) : (
          <Typography.Paragraph className={"empty-state"}>
            No Numbers Found. Try a Different Area Code or Check Your Input
          </Typography.Paragraph>
        )}
        <Form.Item name={"country"} label={"Country"} initialValue={"United States"}>
          <CustomSelect options={countriesOptions} />
        </Form.Item>
        <Form.Item name={"city"} label={"City"}>
          <Input placeholder={"City"} />
        </Form.Item>
        <Form.Item name={"region"} label={"Region"}>
          <Input placeholder={"Region"} />
        </Form.Item>
        <Form.Item name={"street"} label={"Street"}>
          <Input placeholder={"Street"} />
        </Form.Item>
        <Form.Item name={"postal"} label={"Postal Code"}>
          <Input placeholder={"Postal Code"} />
        </Form.Item>
        <ConfirmButtons rightButtonTitle={"Save and Continue"} leftButtonTitle={"Back"} onCancel={closeModal} />
      </Form>
    </Modal>
  )
}
