import { Button, Checkbox, Divider, Form, Row, Typography } from "antd"
import React, { FC, useEffect, useState } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"
import TextArea from "antd/es/input/TextArea"
import { ApproveAiVariantsModal } from "../ApproveAiVariantsModal"

type CreateVariantsModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const CreateVariantsModal: FC<CreateVariantsModalProps> = ({ open, onCancel }) => {
  const [openApproveModal, setOpenApproveModal] = useState(false)
  const [formData, setFormData] = useState<any>()
  const [form] = Form.useForm()

  useEffect(() => {
    const formValues = form.getFieldsValue()
    setFormData(formValues)
  }, [form])

  const closeModal = () => {
    onCancel()
    form.resetFields()
  }

  const handleApproveModalClose= () => {
   setOpenApproveModal(false)
}

  const handleFormSubmit = () => {
    console.log("created")
    setOpenApproveModal(true)
    closeModal()
  }

  const onValueChange = (changedValues: any, allValues: any) => {
    setFormData(allValues);
  }

  return (
    <>
    <Modal width={530} open={open} title={"Create AI A/B Testing Variants"} onCancel={closeModal}>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleFormSubmit}
        onValuesChange={(changedValues, allValues) => onValueChange(changedValues, allValues )}
      >
        <Row justify={"space-between"} align={"middle"} style={{marginBottom: 6}}>
          <Typography.Title level={4}>Value Proposition</Typography.Title>
          <Row align={"middle"}>
                <Button
                  className={"link"}
                  // onClick={openSaveModal}
                >
                  Save
                </Button>
                <Divider type={"vertical"} />
            <Button
              className={"link"}
              // onClick={openFromSavedModal}
            >
              Select From Saved
            </Button>
          </Row>
        </Row>
        <Typography.Paragraph className={"secondary-label-subtitle"} style={{marginBottom:8}}>Add The One Main Key Benefit Clients Would Get By Using Your Company And The Specific Feature That Delivers It.</Typography.Paragraph>
        <Form.Item
          name={"valueProposition"}
        >
          <TextArea placeholder={"Keep It Brief"} />
        </Form.Item>
        <Form.Item
          name={"specificInstructions"}
          label={"Any specific instructions to give to the AI?"}
        >
          <TextArea placeholder={"Keep It Brief"} />
        </Form.Item>
          <Form.Item
            name={"emailBody"}
            label={"Email body to create email variants based off of (Optional)"}
          >
            <TextArea placeholder={"Keep It Brief"} />
          </Form.Item>
          <Form.Item name={"additionally"} label={"Additionally"}  valuePropName="checked" >
            <Checkbox>Reply to incoming email</Checkbox>
          </Form.Item>
        <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Create"} onCancel={closeModal} />
      </Form>
    </Modal>
      <ApproveAiVariantsModal open={openApproveModal} onCancel={handleApproveModalClose} formData={formData} setFormData={setFormData}/>
    </>
  )
}
