import { Button, Flex, Form, List, MenuProps, Radio, Typography } from "antd"
import React, { FC, useEffect, useState } from "react"
import { Drawer, SignInWithButtons } from "src/components/ui"
import { CustomTag } from "src/components/ui/CustomTag"
import { DotsMenuDropdown } from "src/components/ui/DotsMenuDropdown"
import { CustomSelect } from "../../../../ui"
import { SvgIcon } from "../../../../../shared/icons"
import "./index.less"

interface AdditionalAvailabilityDrawerProps {
  onClose: () => void
}

const communicationMethods = [
  { value: "Meet", label: "Meet" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
]

const bufferTime = [
  { value: "0 min", label: "0 min" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
]

const minTimeToBook = [
  { value: "0 min", label: "0 min" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
]

export const AdditionalAvailabilityDrawer: FC<AdditionalAvailabilityDrawerProps> = ({ onClose }) => {
  const [mainCalendarData, setMainCalendarData] = useState([{ id: "1", email: "joan@gmail.com" }])
  const [additionalCalendarData, setAdditionalCalendarData] = useState([
    { id: "2", email: "tom@meetz.ai" },
    { id: "3", email: "gal.stuman@meetz.ai" },
  ])
  const [signedInData, setSignedInData] = useState({ status: false })
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null)
  const [meetingToolsData, setMeetingToolsData] = useState([
    {
      id: "00",
      name: "Google Meet",
      default: true,
      integration: {
        status: "enabled",
      }
    },
    {
      id: "01",
      name: "Teams",
      default: false,
      integration: {
        status: "disabled",
      }
    },
    {
      id: "02",
      name: "Zoom",
      default: false,
      integration: {
        status: "disabled",
      }
    },
  ])
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      mainCalendarData: mainCalendarData,
      additionalCalendarData: additionalCalendarData,
      signedInData: signedInData,
    })
  }, [form])

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then(values => {
        console.log("Form values:", {
          ...values,
          mainCalendarData: mainCalendarData,
          additionalCalendarData: additionalCalendarData,
          signedInData: signedInData,
          meetingTools: meetingToolsData,
        })
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
    onClose()
  }

  const handleResetBtnClick = () => {
    form.resetFields()
  }

  const handleDropdownClick = (id: string) => {
    setSelectedItemId(id)
  }

  const handleIntegrateBtnClick = (id: string) => {
    const selectedTool = meetingToolsData.find(el => el.id === id)
  }

  const handleDisconnectClick = (id: string) => {
    const updatedTools = meetingToolsData.map(el => el.id === id ? {...el, integration: {...el.integration, status: "disabled" }} : el)
    setMeetingToolsData(updatedTools)
  }

  const setToMainCalendar = (id: string | null) => {
    if (id) {
      const itemToMove = additionalCalendarData.find(item => item.id === id)

      if (itemToMove) {
        const updatedAdditionalCalendarData = additionalCalendarData.filter(item => item.id !== id)
        setAdditionalCalendarData(updatedAdditionalCalendarData)
        setMainCalendarData(prevData => [...prevData, itemToMove])
      }
    }
  }

  const removeFromAdditionalCalendar = (id: string | null) => {
    if (id) {
      const updatedAdditionalCalendarData = additionalCalendarData.filter(item => item.id !== id)
      setAdditionalCalendarData(updatedAdditionalCalendarData)
    }
  }

  const meetingToolsOptions = meetingToolsData.map(el => ({
    value: el.id,
    label: (
      <Flex justify={"space-between"} align={"center"}>
        <Typography.Paragraph>{el.name}</Typography.Paragraph>
        {el.integration.status === "enabled" ? (
          <Flex gap={8} align={"center"}>
            {el.default && (
              <CustomTag
                text={"Default"}
                color={"#0FCA7A"}
                backgroundColor={"rgba(15, 202, 122, 0.10)"}
                style={{ width: "fit-content", padding: "6px 16px", lineHeight: 1 }}
              />
            )}
            <SvgIcon type={"close"} width={20} height={20} className={"close-btn"} onClick={() => handleDisconnectClick(el.id)} />
          </Flex>
        ) : (
          <Button className={"link"} onClick={() => handleIntegrateBtnClick(el.id)}>
            Integrate
          </Button>
        )}
      </Flex>
    ),
    default: el.default,
  }))

  const dropdownMenuItems: MenuProps["items"] = [
    {
      key: "1",
      label: "Set as Meeting-Booked Calendar",
      onClick: () => setToMainCalendar(selectedItemId),
    },
    {
      key: "2",
      label: "Remove",
      onClick: () => removeFromAdditionalCalendar(selectedItemId),
    },
  ]

  useEffect(() => {
    console.log()
  }, [])

  return (
    <Drawer
      open={true}
      title={"Additional Availability"}
      leftButtonTitle={"Reset all"}
      rightButtonTitle={"Save"}
      onBtnPrimaryClick={handleFormSubmit}
      onBtnSecondaryClick={handleResetBtnClick}
      onCancel={onClose}
      className={"additional-availability-drawer"}
    >
      <Form
        form={form}
        layout={"vertical"}
        initialValues={{
          defaultCommunicationMethod: communicationMethods[0].value,
          bufferTimeBetweenMeetings: bufferTime[0].value,
          minimumTimeToBookInAdvance: minTimeToBook[0].value,
          meetingTools: meetingToolsOptions.find(meet => meet.default)?.value,
        }}
        className={"additional-availability-form"}
      >
        <Flex vertical={true} gap={22} style={{ paddingTop: 22 }}>
          <Flex vertical={true} gap={16}>
            <Typography.Title level={3} className={"subtitle"}>
              Main Calendar
            </Typography.Title>
            <List
              itemLayout={"horizontal"}
              dataSource={mainCalendarData}
              renderItem={item => (
                <List.Item>
                  <Flex justify={"space-between"} align={"center"} style={{ width: "100%" }}>
                    <Typography.Paragraph className={"text"}>{item.email}</Typography.Paragraph>
                    <CustomTag
                      text={"Meeting-Booked Calendar"}
                      color={"#01AEEF"}
                      backgroundColor={"rgba(1, 174, 239, 0.10)"}
                      style={{ width: "fit-content", padding: "6px 16px", lineHeight: 1 }}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </Flex>
          <Flex vertical={true} gap={16}>
            <Typography.Title level={3} className={"subtitle"}>
              Additional Calendar
            </Typography.Title>
            <List
              itemLayout='horizontal'
              dataSource={additionalCalendarData}
              renderItem={item => (
                <List.Item>
                  <Flex justify={"space-between"} align={"center"} style={{ width: "100%" }}>
                    <Typography.Paragraph className={"text"}>{item.email}</Typography.Paragraph>
                    <DotsMenuDropdown
                      menuItems={dropdownMenuItems}
                      onDropdownClick={() => handleDropdownClick(item.id)}
                    />
                  </Flex>
                </List.Item>
              )}
            />
          </Flex>
          <Flex vertical={true} gap={16}>
            <SignInWithButtons title={"Add"} />
          </Flex>
          <Flex vertical={true} gap={16}>
            <Typography.Title level={3} className={"subtitle"}>
              General Settings
            </Typography.Title>
            <Flex vertical={true} gap={8}>
              <Typography.Paragraph className={"label"}>Default communication method</Typography.Paragraph>
              <Form.Item name={"defaultCommunicationMethod"}>
                <CustomSelect options={communicationMethods} />
              </Form.Item>
            </Flex>
            <Flex vertical={true} gap={8}>
              <Typography.Paragraph className={"label"}>Buffer Times Between Meetings</Typography.Paragraph>
              <Form.Item name={"bufferTimeBetweenMeetings"}>
                <CustomSelect options={bufferTime} />
              </Form.Item>
            </Flex>
            <Flex vertical={true} gap={8}>
              <Typography.Paragraph className={"label"}>Minimum Time To Book In Advance</Typography.Paragraph>
              <Form.Item name={"minimumTimeToBookInAdvance"}>
                <CustomSelect options={minTimeToBook} />
              </Form.Item>
            </Flex>
          </Flex>
          <Flex vertical={true} gap={16}>
            <Typography.Title level={3} className={"subtitle"}>
              Meeting Tools
            </Typography.Title>
            <Form.Item name={"meetingTools"}>
              <Radio.Group options={meetingToolsOptions.map(option => ({ label: option.label, value: option.value }))} className={"meeting-tools-radio-group"} />
            </Form.Item>
          </Flex>
        </Flex>
      </Form>
    </Drawer>
  )
}
