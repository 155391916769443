import { Button, Checkbox, Flex, Form, Radio, Row, Space, Tag, Tooltip, Typography } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import "./index.less"

type EmailSendingLimitsProps = {
  open: boolean
  onCancel: VoidFunction
  emailData: any
}

const emailsPerDay =[
  {
    id: "1",
    day:"1",
    value:"10"
  },
  {
    id: "2",
    day:"3",
    value:"16",
  },
  {
    id: "3",
    day:"4",
    value:"4/40"
  },
  {
    id: "4",
    day:"5",
    value:"20"
  },
  {
    id: "5",
    day:"6",
    value:"32"
  }
]

export const warmUpTooltipText ="Slowly increment sends day by day to warm up your email address.\n" +
  "The best part? Since every email is completely unique the warm up will be even more effective since it looks handwritten and not templated!"

export const updateLimitTooltipText=<Flex vertical gap={16}>
  <Typography.Paragraph>Any Update Will Reset the Warm Up Day to 1 </Typography.Paragraph>
  <Typography.Paragraph>Not recommended to go over 300 emails per day or over 100 for a new email address.</Typography.Paragraph>
</Flex>


export const EmailSendingLimitsModal: FC<EmailSendingLimitsProps> = ({ open, onCancel,emailData }) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null)
  const [form] = Form.useForm()

  const closeModal = () => {
    onCancel()
    form.resetFields()
  }

  const handleButtonClick = (index: number) => {
    setActiveIndex(index === activeIndex ? null : index)
  }

  const handleFormSubmit = () => {
    console.log(" test")
    onCancel()
    form.resetFields()
  }

  return (
    <Modal
      width={530}
      customClassName={"sending-limits-modal"}
      open={open}
      title={"Email Sending Limits"}
      onCancel={closeModal}
    >
      <Tag>{emailData?.email}</Tag>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        onFinish={handleFormSubmit}
      >
        <Form.Item name={"type"} label={"Type"} initialValue={100}>
          <Radio.Group
          >
            <Space direction={"vertical"}>
              <Radio value={"Recommended"}>Recommended</Radio>
              <Radio value={"Quick"}>Quick</Radio>
              <Radio value={"Long"}>Long</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Row justify={"space-between"} align={"middle"} wrap={false} style={{marginBottom: 8}} >
          <Typography.Title level={4}>Email Sends Per Day</Typography.Title>
          <Row align={"middle"}>
            <Button className={"link"}>Update Limit</Button>
            <Tooltip placement={"bottomRight"} title={updateLimitTooltipText}>
              <SvgIcon type={"tooltip"} style={{marginLeft: 11, cursor: "pointer"}}/>
            </Tooltip>
          </Row>
        </Row>
        <Flex gap={41} >
          {emailsPerDay.map((item, index) =>{
            return(
              <Space
                key={`${index}-${item.day}`}
                className={`emails-qty-btn ${index === activeIndex ? "active" : ""}`}
                direction={"vertical"}
                style={{margin: "8px 0 28px"}}
                onClick={() => handleButtonClick(index)}
              >
                <Typography.Paragraph className={"emails-qty-day"}>{`Day ${item.day}`}</Typography.Paragraph>
                <Typography.Paragraph className={`emails-qty ${index === activeIndex ? "active" : ""}`}>{item.value}</Typography.Paragraph>
              </Space>
            )
          })}

        </Flex>
        <Form.Item name={"warmUp"} >
          <Checkbox className={"warm-up-checkbox"}>
            Warm Up Email Schedule <Tooltip placement={"bottomRight"} title={warmUpTooltipText}><SvgIcon type={"tooltip"} style={{marginLeft: 8}}/></Tooltip></Checkbox>
        </Form.Item>

      <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Save"} onCancel={closeModal}/>
      </Form>
    </Modal>
  )
}
