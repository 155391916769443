import { Col, Flex, Form, Image, Input, Space, Typography } from "antd"
import { FC, useState } from "react"
import { Drawer } from "../../ui"
import "./index.less"

interface AssistantSettingsDrawerProps {
  onClose: () => void
}

export const AssistantSettingsDrawer: FC<AssistantSettingsDrawerProps> = ({ onClose }) => {
  const [form] = Form.useForm()
  const [userData, setUserData] = useState({
    name: "Laura",
    email: "Laura@meetz.ai",
    defaultMeetingTitle: "Meeting | Quick Intro",
    signature: {
      img: "src/shared/images/signature.jpg",
    },
  })

  const handleResetClick = () => {
    setUserData({
      name: "",
      email: "",
      defaultMeetingTitle: "",
      signature: {
        img: "",
      },
    })
    form.setFieldsValue({
      name: "",
      email: "",
      defaultMeetingTitle: "",
      signature: {
        img: "",
      },
    })
  }

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then(values => {
        const updatedValues = { ...userData, ...values }
        console.log(updatedValues)
      })
      .catch(errorInfo => {
        console.error("Validation failed:", errorInfo)
      })
    onClose()
  }

  return (
    <Drawer
      open={true}
      title={"Assistant Settings"}
      leftButtonTitle={"Reset all"}
      rightButtonTitle={"Save"}
      onBtnPrimaryClick={handleFormSubmit}
      onBtnSecondaryClick={handleResetClick}
      onCancel={onClose}
      rootClassName={"assistant-settings-drawer"}
    >
      <Form form={form} initialValues={userData} layout={"vertical"} className={"form"}>
        <Flex vertical={true} gap={22}>
          <Form.Item name={"name"} label={"Assistants First Name"}>
            <Input />
          </Form.Item>
          <Form.Item name={"email"} label={"Assistants Email"}>
            <Input />
          </Form.Item>
          <Form.Item name={"defaultMeetingTitle"} label={"Default Meeting Title"}>
            <Input />
          </Form.Item>
          <Col className={"signature-wrapper"}>
            <Typography.Paragraph className={"label"}>Signature</Typography.Paragraph>
            <Space className={"signature-img-wrapper"}>
              {userData.signature.img && (
                <Image
                  src={userData.signature.img}
                  preview={false}
                  className={"signature-img"}
                  width={380}
                  height={140}
                />
              )}
            </Space>
          </Col>
        </Flex>
      </Form>
    </Drawer>
  )
}
