import { Flex } from "antd"
import React, { FC } from "react"
import { ConfirmButtons, CustomLabel, Modal } from "src/components/ui"

type SequenceCreatedModalProps = {
  open: boolean
  onCancel: VoidFunction
  onNextPage: VoidFunction
}

export const SequenceCreatedModal: FC<SequenceCreatedModalProps> = ({ open, onCancel, onNextPage }) => {
  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    onNextPage()
    console.log("Created")
    onCancel()
  }

  return (
    <Modal width={530} open={open} title={"The sequence is created"} onCancel={closeModal}>
      <Flex vertical gap={20}>
        <CustomLabel title={"Add Prospects to activate the campaign on the following page."} />
        <ConfirmButtons
          leftButtonTitle={"Back"}
          rightButtonTitle={"Add Prospects"}
          onCancel={closeModal}
          handleSubmit={handleFormSubmit}
        />
      </Flex>
    </Modal>
  )
}
