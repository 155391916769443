import { Button, Checkbox, CheckboxProps, Flex, Form, Input } from "antd"
import React, { FC, useState } from "react"
import { ContentCard, CustomLabel, TextEditor } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { AddVariableModal } from "../AddVariableModal"
import { CreateVariantsModal } from "../CreateVariantsModal"

export const ManualEmails: FC = () => {
  const [openModal, setOpenModal] = useState(false)
  const [openCreateVariantsModal, setOpenCreateVariantsModal] = useState(false)
  const [hasReply, setHasReply] = useState<boolean>(false)
  const onChange: CheckboxProps["onChange"] = e => {
    setHasReply(e.target.checked)
  }
  const showModal = () => {
    setOpenModal(true)
  }

  const showCreateVariantsModal = () => {
    setOpenCreateVariantsModal(true)
  }
  const onCancel = () => {
    setOpenModal(false)
    setOpenCreateVariantsModal(false)
  }

  return (
    <ContentCard
      headerIcon={<SvgIcon type={"letter"} />}
      cardTitle={"Manual Email"}
      headerRightContent={
        <Button className={"link"} onClick={showModal}>
          Add Variable
        </Button>
      }
    >
      <CustomLabel title={"Additionally"} />
      <Form.Item name={"additionally"}>
        <Checkbox onChange={onChange}>Reply to incoming email</Checkbox>
      </Form.Item>
      {!hasReply && (
        <React.Fragment>
          <CustomLabel title={"Subject Line"} />
          <Form.Item name={"subjectLine"}>
            <Input />
          </Form.Item>
        </React.Fragment>
      )}
      <CustomLabel title={"Email Body"} />
      <Flex vertical style={{ marginBottom: 22 }}>
        <TextEditor
          content={
            "Hi Kan, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
            "\n" +
            "Warm regards"
          }
        />
      </Flex>
      <CustomLabel title={"Example"} />
      <Form.Item
        name={"example"}
        initialValue={
          "Hi Aron, I wanted to share how our AI can simplify your email personalization process, boosting your design projects. Designers like you can use it to tailor outreach to potential clients faster and easier. It takes the hassle out of manual customization, freeing up more time for your creative work. Would this be of interest?\n" +
          "\n" +
          "Warm regards"
        }
      >
        <Input.TextArea autoSize disabled />
      </Form.Item>
      <CustomLabel title={"Variants"}/>
      <Button className={"link"} onClick={showCreateVariantsModal}>Create AI A/B Testing Variants</Button>
      <AddVariableModal open={openModal} onCancel={onCancel} />
      <CreateVariantsModal open={openCreateVariantsModal} onCancel={onCancel}/>
    </ContentCard>
  )
}
