import { App } from "../../../types"

export const campaignData: App.CampaignEntity[] = [
  {
    id: "1",
    campaign: "Winter Wonderland Discounts",
    contacts: "85",
    contactsInCampaign: "78",
    actionsForToday: "0",
    prospects: "56",
    createdAt: "Dec 3, 2024",
    status: "Active",
  },
  {
    id: "2",
    campaign: "Spring Savings Spectacular",
    contacts: "499",
    contactsInCampaign: "453",
    actionsForToday: "2",
    prospects: "56",
    createdAt: "Mar 15, 2024",
    status: "Paused",
  },
  {
    id: "3",
    campaign: "Summer Sunshine Deals",
    contacts: "123",
    contactsInCampaign: "67",
    actionsForToday: "3",
    prospects: "56",
    createdAt: "Mar 15, 2024",
    status: "Active",
  },
  {
    id: "4",
    campaign: "Fall Fashion Frenzy",
    contacts: "456",
    contactsInCampaign: "56",
    actionsForToday: "2",
    prospects: "56",
    createdAt: "Jan 12, 2024",
    status: "Active",
  },
  {
    id: "5",
    campaign: "Travel Temptations Newsletter",
    contacts: "85",
    contactsInCampaign: "78",
    actionsForToday: "0",
    prospects: "56",
    createdAt: "Oct 28, 2024",
    status: "Active",
  },
  {
    id: "6",
    campaign: "Back-to-School Bonanza",
    contacts: "123",
    contactsInCampaign: "67",
    actionsForToday: "3",
    prospects: "56",
    createdAt: "Mar 15, 2024",
    status: "Paused",
  },
  {
    id: "7",
    campaign: "Holiday Happiness Sale",
    contacts: "456",
    contactsInCampaign: "56",
    actionsForToday: "2",
    prospects: "56",
    createdAt: "Jan 12, 2024",
    status: "Active",
  },
  {
    id: "8",
    campaign: "New Year, New You Offers",
    contacts: "85",
    contactsInCampaign: "78",
    actionsForToday: "0",
    prospects: "56",
    createdAt: "Oct 28, 2024",
    status: "Paused",
  },
  {
    id: "9",
    campaign: "Tech-Tastic Email Specials",
    contacts: "123",
    contactsInCampaign: "67",
    actionsForToday: "3",
    prospects: "56",
    createdAt: "Mar 15, 2024",
    status: "Active",
  },
  {
    id: "10",
    campaign: "Health & Wellness Week",
    contacts: "456",
    contactsInCampaign: "56",
    actionsForToday: "2",
    prospects: "56",
    createdAt: "Jan 12, 2024",
    status: "Active",
  },
  {
    id: "11",
    campaign: "Tech Email Specials",
    contacts: "85",
    contactsInCampaign: "78",
    actionsForToday: "0",
    prospects: "56",
    createdAt: "Oct 28, 2024",
    status: "Paused",
  },
  {
    id: "12",
    campaign: "Health Week",
    contacts: "123",
    contactsInCampaign: "67",
    actionsForToday: "3",
    prospects: "56",
    createdAt: "Mar 15, 2024",
    status: "Active",
  },
  {
    id: "13",
    campaign: "Holiday Sale",
    contacts: "456",
    contactsInCampaign: "56",
    actionsForToday: "2",
    prospects: "56",
    createdAt: "Jan 12, 2024",
    status: "Active",
  },
  {
    id: "14",
    campaign: "New Year Offers",
    contacts: "85",
    contactsInCampaign: "78",
    actionsForToday: "0",
    prospects: "56",
    createdAt: "Oct 28, 2024",
    status: "Active",
  },
  {
    id: "15",
    campaign: "Travel Newsletter",
    contacts: "456",
    contactsInCampaign: "56",
    actionsForToday: "2",
    prospects: "56",
    createdAt: "Jan 12, 2024",
    status: "Paused",
  },
]

export const sequenceData = [
  {
    id: "1",
    stepNumber: "1",
    day: "1",
    mode: "Manual Emails",
    delay: "Scenario Started",
    additional: [],
  },
  {
    id: "2",
    stepNumber: "2",
    day: "3",
    mode: "AI Personalized Email",
    delay: "Immediately",
    additional: [],
  },
  {
    id: "3",
    stepNumber: "3",
    day: "5",
    mode: "Manual Emails",
    delay: "Delay for 2 Days",
    additional: ["10", "10"],
  },
  {
    id: "4",
    stepNumber: "4",
    day: "6",
    mode: "Phone Call",
    delay: "Immediately",
    additional: ["Voicemail"],
  },
  {
    id: "5",
    stepNumber: "5",
    day: "8",
    mode: "SMS",
    delay: "Delay for 4 Days",
    additional: ["10", "10"],
  },
  {
    id: "6",
    stepNumber: "6",
    day: "10",
    mode: "Task",
    delay: "Immediately",
    additional: [],
  },
]

export const allActionsData: App.AllAction[] = [
  {
    id: "1",
    title: "Emails",
    info: [
      {
        id: "12",
        name: "Tom Smith",
        contact: "tom@meetz.ai",
        connected: true,
      },
      {
        id: "13",
        name: "Gal Stuman",
        contact: "tom2@meetz.ai",
        connected: true,
      },
    ],
  },
  {
    id: "2",
    title: "Phone Call",
    info: [
      {
        id: "14",
        name: "Tom Smith",
        contact: "(435) 555-5434",
        connected: true,
      },
      {
        id: "15",
        name: "Gal Stuman",
        contact: "(435) 343-3333",
        connected: false,
      },
    ],
  },
  {
    id: "3",
    title: "SMS",
    info: [
      {
        id: "16",
        name: "Tom Smith",
        contact: "(435) 345-5434",
      },
      {
        id: "17",
        name: "Gal Stuman",
        contact: "(435) 345-5434",
      },
      {
        id: "9",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "50",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "60",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "61",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "62",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "63",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "64",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "65",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "66",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "67",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "68",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
      {
        id: "69",
        name: "Tolya Test",
        contact: "(435) 345-5444",
      },
    ],
  },
  {
    id: "4",
    title: "Tasks",
    info: [
      {
        id: "18",
        name: "Google Meet Appointment",
      },
      {
        id: "19",
        name: "Review prospects",
      },
    ],
  },
]

export const sequenceDataWithStatus = [
  {
    id: "1",
    stepNumber: "1",
    day: "1",
    mode: "Manual Emails",
    delay: "Scenario Started",
    additional: [],
    status: {
      success: 2,
      finished: 3,
      completed: 2,
      skipped: 2,
    },
  },
  {
    id: "2",
    stepNumber: "2",
    day: "3",
    mode: "AI Personalized Email",
    delay: "Immediately",
    additional: [],
    status: {
      success: 2,
      finished: 3,
      completed: 2,
      skipped: 2,
    },
  },
  {
    id: "3",
    stepNumber: "3",
    day: "5",
    mode: "Manual Emails",
    delay: "Delay for 2 Days",
    additional: ["10", "10"],
    status: {
      success: 2,
      finished: 3,
      completed: 2,
    },
  },
  {
    id: "4",
    stepNumber: "4",
    day: "6",
    mode: "Phone Call",
    delay: "Immediately",
    additional: ["Voicemail"],
  },
  {
    id: "5",
    stepNumber: "5",
    day: "8",
    mode: "SMS",
    delay: "Delay for 4 Days",
    additional: ["10", "10"],
  },
  {
    id: "6",
    stepNumber: "6",
    day: "10",
    mode: "Task",
    delay: "Immediately",
    additional: [],
  },
]

export const statusInfo: any = [
  {
    success: [
      {
        id: "1",
        prospect: "Tom Smith",
        email: "tom@meetz.ai",
        phone: "(435) 564-7843",
        status: "Email delivered",
      },
      {
        id: "2",
        prospect: "Gal Stuman",
        email: "gal.stuman@meetz.ai",
        phone: "(455) 333-7333",
        status: "Email delivered",
      },
    ],
    finished: [
      {
        id: "1",
        prospect: "Tom Smith",
        email: "tom@meetz.ai",
        phone: "(435) 564-7843",
        status: "Marked as success",
      },
      {
        id: "2",
        prospect: "Gal Stuman",
        email: "gal.stuman@meetz.ai",
        phone: "(455) 333-7333",
        status: "Marked as not interested",
      },
      {
        id: "3",
        prospect: "Tolya Test",
        email: "tolya.test@meetz.ai",
        phone: "(455) 333-7333",
        status: "Marked as not interested",
      },
    ],
    completed: [
      {
        id: "1",
        prospect: "Tom Smith",
        email: "tom@meetz.ai",
        phone: "(435) 564-7843",
        status: "Completed",
      },
      {
        id: "2",
        prospect: "Gal Stuman",
        email: "gal.stuman@meetz.ai",
        phone: "(455) 333-7333",
        status: "Completed",
      },
    ],
    skipped: [
      {
        id: "1",
        prospect: "Tom Smith",
        email: "tom@meetz.ai",
        phone: "(435) 564-7843",
        status: "Email bouncing ",
      },
      {
        id: "2",
        prospect: "Gal Stuman",
        email: "gal.stuman@meetz.ai",
        phone: "(455) 333-7333",
        status: "Email bouncing",
      },
    ],
  },
]

export const campaignsEmailsData = [
  {
    id: "1",
    firstName: "Iron",
    lastName: "Man",
    timeContacted: "2 Days Ago",
    callLength: "3 Min",
    timesCalled: "3",
    incomingCalls: "4",
    status: ["SMS Sent"],
  },
  {
    id: "2",
    firstName: "Iron",
    lastName: "Maiden",
    timeContacted: "2 Days Ago",
    callLength: "20 Min",
    timesCalled: "3",
    incomingCalls: "4",
    status: ["SMS Sent"],
  },
]

export const aiGeneratedPersonalData = {
  linkedinFeed: [
    {
      id: "1",
      howLongAgo: "1 Week",
      action: "Wendy Hellstern likes this",
      personWhoPosted: "",
      post: "We're celebrating National Retro Day by looking back at celebrating National Retro Day by looking back at celebrating National Retro Day by looking back at",
    },
    {
      id: "2",
      howLongAgo: "2 Week",
      action: "Wendy Hellstern likes this",
      personWhoPosted: "Freeman",
      post: "We're celebrating National Retro Day by looking back at celebrating National Retro Day by looking back at celebrating National Retro Day by looking back at",
    },
  ],
  linkedinBio: {
    title: "Vice President, Business Development at REX Engineering Group",
    summary:
      "Finding solutions for customers is something I focus on solutions for customers is something I focus on solutions for customers is something I focus on solutions for customers is something I focus on ",
    location: "Greater Chicago Area",
    company: "REX Engineering Group",
    startDate: "6/2020",
    endDate: "8/2020",
  },
  contactInfo: {
    name: "Wendy",
    jobTitle: "Vice President",
    country: "Greater Chicago Area",
    company: "Imbrex",
    localTime: "11:53 AM",
    phoneNumber: "D. +972585010596",
  },
  companyPost: [
    {
      id: "1",
      howLongAgo: "1 Week",
      post:
        "Headlines from a crazy couple of weeks support the increasing need to oust traditional money transfer methods. Escrow Commons can handle your daily business, real estate, rent and other payments/transfers. Stay tuned for Launch! #realestate #escrow #technologydisruption #smartcontracts #blockchain #payments #paymentsolution\n" +
        "See ",
    },
    {
      id: "2",
      howLongAgo: "4 Year",
      post:
        "Headlines from a crazy couple of weeks support the increasing need to oust traditional money transfer methods. Escrow Commons can handle your daily business, real estate, rent and other payments/transfers. Stay tuned for Launch! #realestate #escrow #technologydisruption #smartcontracts #blockchain #payments #paymentsolution\n" +
        "See ",
    },
    {
      id: "3",
      howLongAgo: "3 Week",
      post:
        "Headlines from a crazy couple of weeks support the increasing need to oust traditional money transfer methods. Escrow Commons can handle your daily business, real estate, rent and other payments/transfers. Stay tuned for Launch! #realestate #escrow #technologydisruption #smartcontracts #blockchain #payments #paymentsolution\n" +
        "See ",
    },
    {
      id: "4",
      howLongAgo: "2 Week",
      post:
        "Headlines from a crazy couple of weeks support the increasing need to oust traditional money transfer methods. Escrow Commons can handle your daily business, real estate, rent and other payments/transfers. Stay tuned for Launch! #realestate #escrow #technologydisruption #smartcontracts #blockchain #payments #paymentsolution\n" +
        "See ",
    },
  ],
  companyArticle: [
    {
      id: "1",
      howLongAgo: "4 Year Edited",
      post: "Change is inevitable, but will you be ready for what's next? Headlines from a crazy couple of weeks support the increasing need to oust traditional money transfer methods",
      article: "Finding Gold on the New Frontier of Real Estate",
      articleSubtitle: "stephenking.substack.com",
      articleLink:
        "https://stephenking.substack.com/image?url=https%3A%2F%2Fcdn.substack.com%2Fimage%2Ffetch%2Fhttps%3A%2F%2Fstephenking.substack.com%2Fimage%2Ffetch%2Fhttps%3A%2F%2Fstephenking.substack.com%2",
    },
    {
      id: "2",
      howLongAgo: "4 Year Edited",
      post: "Change is inevitable, but will you be ready for what's next? Headlines from a crazy couple of weeks support the increasing need to oust traditional money transfer methods",
      article: "Finding Gold on the New Frontier of Real Estate",
      articleSubtitle: "stephenking.substack.com",
      articleLink:
        "https://stephenking.substack.com/image?url=https%3A%2F%2Fcdn.substack.com%2Fimage%2Ffetch%2Fhttps%3A%2F%2Fstephenking.substack.com%2Fimage%2Ffetch%2Fhttps%3A%2F%2Fstephenking.substack.com%2",
    },
    {
      id: "3",
      howLongAgo: "4 Year Edited",
      post: "Change is inevitable, but will you be ready for what's next? Headlines from a crazy couple of weeks support the increasing need to oust traditional money transfer methods",
      article: "Finding Gold on the New Frontier of Real Estate",
      articleSubtitle: "stephenking.substack.com",
      articleLink:
        "https://stephenking.substack.com/image?url=https%3A%2F%2Fcdn.substack.com%2Fimage%2Ffetch%2Fhttps%3A%2F%2Fstephenking.substack.com%2Fimage%2Ffetch%2Fhttps%3A%2F%2Fstephenking.substack.com%2",
    },
  ],
}
