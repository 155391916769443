import { Avatar, Dropdown, Flex, Input, MenuProps, Progress, Row, Typography } from "antd"
import { FC, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { SvgIcon } from "../../shared/icons"
import { ConfirmButtons } from "../ui"
import { AssistantSettingsDrawer } from "./AssistantSettingsDrawer"
import { DialerModal, NotificationDropdown, PlayerDropdown } from "./ui"
import { ViewModal } from "./ui/ViewModal"
import "./DefaultHeader.less"
import { CommonContext } from "../app/context/CommonContext"

const initialCreditData = {
  prospect: {
    left: 209,
    total: 250,
  },
  emails: {
    left: 242,
    total: 250,
  },
  isTrial: false,
}

export const DefaultHeader: FC = () => {
  const { currentView } = useContext(CommonContext)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [openDialerModal, setOpenDialerModal] = useState(false)
  const [isViewModalOpened, setIsViewModalOpened] = useState(false)
  const [isAssistantSettingsDrawerOpened, setIsAssistantSettingsDrawerOpened] = useState(false)
  const [creditsData, setCreditsData] = useState(initialCreditData)

  const showDialerModal = () => {
    setOpenDialerModal(true)
  }

  const closeDialerModal = () => {
    setOpenDialerModal(false)
  }

  const showViewModal = () => {
    setIsViewModalOpened(true)
  }

  const closeViewModal = () => {
    setIsViewModalOpened(false)
  }

  const showAssistantSettingsDrawer = () => {
    setIsAssistantSettingsDrawerOpened(true)
  }

  const closeAssistantSettingsDrawer = () => {
    setIsAssistantSettingsDrawerOpened(false)
  }

  const suffix = (
    <Row style={{ gap: 4 }}>
      <SvgIcon type={"searchSuffixIcon"} />
      <SvgIcon type={"searchSuffixIconK"} />
    </Row>
  )

  const items: MenuProps["items"] = [
    {
      label: (
        <Flex vertical={true} gap={8} className={"profile-item-wrapper"}>
          <Typography.Paragraph className={"profile-menu-title"}>Prospect Data Credits</Typography.Paragraph>
          <Progress
            percent={((creditsData.prospect.total - creditsData.prospect.left) * 100) / creditsData.prospect.total}
            strokeColor={"#01AEEF"}
            trailColor={"#EDFCFD"}
            showInfo={false}
          />
          <Flex vertical={true} gap={4}>
            <Typography.Paragraph className={"profile-menu-value"}>
              Prospect Data Left: {creditsData.prospect.left}
            </Typography.Paragraph>
            <Typography.Paragraph className={"profile-menu-value"}>
              Total Prospect Data: {creditsData.prospect.total}
            </Typography.Paragraph>
          </Flex>
        </Flex>
      ),
      key: "0",
    },
    {
      label: (
        <Flex vertical={true} gap={8} className={"profile-item-wrapper"}>
          <Typography.Paragraph className={"profile-menu-title"}>AI Personalized Emails</Typography.Paragraph>
          <Progress
            percent={((creditsData.emails.total - creditsData.emails.left) * 100) / creditsData.emails.total}
            strokeColor={"#01AEEF"}
            trailColor={"#EDFCFD"}
            showInfo={false}
          />
          <Flex vertical={true} gap={4}>
            <Typography.Paragraph className={"profile-menu-value"}>
              AI Emails Left: {creditsData.emails.left}
            </Typography.Paragraph>
            <Typography.Paragraph className={"profile-menu-value"}>
              Total AI Emails: {creditsData.emails.total}
            </Typography.Paragraph>
          </Flex>
          {creditsData.isTrial && <ConfirmButtons rightButtonTitle={"Skip trial"} className={"skip-trial-wrapper"} />}
        </Flex>
      ),
      key: "1",
    },
    {
      label: (
        <Flex
          align={"center"}
          gap={8}
          className={"profile-item-wrapper"}
          onClick={showViewModal}
        >
          <SvgIcon type={"view"} width={20} height={20} />
          <Typography.Paragraph className={"profile-menu-title"}>View: {currentView}</Typography.Paragraph>
        </Flex>
      ),
      key: "2",
    },
    {
      label: (
        <Flex
          align={"center"}
          gap={8}
          className={"profile-item-wrapper"}
          onClick={showAssistantSettingsDrawer}
        >
          <SvgIcon type={"settings"} width={20} height={20} />
          <Typography.Paragraph className={"profile-menu-title"}>Assistant Settings</Typography.Paragraph>
        </Flex>
      ),
      key: "3",
    },
    {
      label: (
        <Link to={"/billing"}>
          <Flex align={"center"} gap={8} className={"profile-item-wrapper"}>
            <SvgIcon type={"wallet"} width={20} height={20} />
            <Typography.Paragraph className={"profile-menu-title"}>Billing</Typography.Paragraph>
          </Flex>
        </Link>
      ),
      key: "4",
    },
  ]

  return (
    <>
      <Row className={"header-wrapper"} style={{ padding: "20px 40px" }} justify={"space-between"} wrap={false}>
        <Input.Search
          className={"search-input"}
          placeholder={"Search..."}
          allowClear
          prefix={<SvgIcon type={"search"} />}
          suffix={suffix}
          enterButton={false}
          variant={"borderless"}
        />
        <Row align={"middle"} wrap={false} style={{ gap: 16 }}>
          <NotificationDropdown />
          <PlayerDropdown />
          <SvgIcon type={"phone"} onClick={showDialerModal} style={{ cursor: "pointer" }} />
          <Row align={"middle"} wrap={false} style={{ gap: 8 }}>
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              rootClassName={"profile-dropdown"}
              onOpenChange={open => setDropdownOpen(open)}
            >
              <a onClick={e => e.preventDefault()}>
                <Flex align={"center"} gap={8}>
                  <Flex className={"header-avatar-wrapper"} style={{width: currentView === "Personal" ? 40 : 66}}>
                    <Avatar
                      className={"header-avatar"}
                      size={"large"}
                      icon={currentView === "Team" ? false : currentView === "Personal" ? <SvgIcon type={"womanAvatar"} /> : <SvgIcon type={"manAvatar"} width={40} height={40} />}
                      style={{ backgroundColor: currentView === "Team" ? "#FFF" : "#757A891A", border: currentView === "Team" ? "1px solid #01AEEF" : "unset"}}
                    >
                      {currentView === "Team" && "T"}
                    </Avatar>
                    {currentView !== "Personal" && (
                      <Avatar className={"header-avatar"} size={"large"} icon={<SvgIcon type={"womanAvatar"} width={40} height={40} />} />
                    )}
                  </Flex>
                  <Row align={"middle"} style={{ gap: 6 }}>
                    <Typography.Paragraph className={"profile-username"}>Joan Smith</Typography.Paragraph>
                    <SvgIcon
                      type={"arrowHeader"}
                      style={{ transform: dropdownOpen ? "rotate(180deg)" : "rotate(0deg)" }}
                    />
                  </Row>
                </Flex>
              </a>
            </Dropdown>
          </Row>
        </Row>
      </Row>
      {isAssistantSettingsDrawerOpened && (
        <AssistantSettingsDrawer onClose={closeAssistantSettingsDrawer} />
      )}
      {isViewModalOpened && (
        <ViewModal onClose={closeViewModal} />
      )}
      <DialerModal open={openDialerModal} onCancel={closeDialerModal} />
    </>
  )
}
