
export const getStepSequenceIconType = (mode: string) => {
  switch (true) {
    case mode === "Manual Emails":
      return "letter"
    case mode === "AI Personalized Email":
      return "personalizedEmail"
    case mode === "Phone Call":
      return "phoneCall"
    case mode === "SMS":
      return "sms"
    default:
      return "task"
  }
}
