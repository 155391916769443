import { Col, Flex, Row, Space, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC, useEffect, useMemo, useState } from "react"
import { EmailDetailsModal, EmailsStatus } from "src/components/pages/emails-data"
import { Table, TableHeader } from "src/components/ui"
import { App } from "src/types"
import { emailsData } from "../../info"
import "./index.less"

interface EmailsTableProps {
  isTourStep?: boolean;
}

export const EmailsTable: FC<EmailsTableProps> = ({ isTourStep }) => {
  const [selectedContact, setSelectedContact] = useState<App.EmailsEntity | null>(null)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [loading, setLoading] = useState(true)
  const [tourStepData, setTourStepData] = useState(emailsData)
  const [tableData, setTableData] = useState(emailsData)
  const memoizedTourStepData = useMemo(() => tourStepData, [tourStepData]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  useEffect(() => {
    setTourStepData(emailsData)
  }, [emailsData])

  const showModal = () => {
    setOpenModal(true)
  }

  const onCancel = () => {
    setOpenModal(false)
  }

  const columns: ColumnsType<App.EmailsEntity> = [
    {
      title: "Contact name",
      className: "emails-contact-name",
      render: record => (
        <Typography.Paragraph
          onClick={() => {
            setSelectedContact(record)
            showModal()
          }}
        >{`${record.firstName} ${record.lastName}`}</Typography.Paragraph>
      ),
      key: "name",
    },
    {
      title: "Contents",
      render: record => (
        <Space direction={"vertical"} key={record.id}>
          <Col>
            <Typography.Paragraph className={"email-title"}>{record.contents.title}</Typography.Paragraph>
            <Typography.Paragraph ellipsis={true} style={{ width: 300 }}>
              {record.contents.content}
            </Typography.Paragraph>
          </Col>
          <Col>
            <Row wrap={false} style={{ gap: 3 }}>
              <Typography.Paragraph>{`Step ${record.company.step} in`}</Typography.Paragraph>
              <Typography.Paragraph className={"emails-company-name"}>{record.company.name}</Typography.Paragraph>
            </Row>
          </Col>
        </Space>
      ),
      key: "contents",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: value => (
        <Flex wrap={"wrap"} gap={8}>
          {value.map((item: App.EmailStatus, index: number) => (
            <EmailsStatus key={`${index}-${item.title}`} status={item.title} count={item.count} />
          ))}
        </Flex>
      ),
      key: "status",
    },
    {
      title: "Date sent",
      dataIndex: "dateSent",
      key: "dateSent",
    },
  ]

  return (
    <React.Fragment>
      <TableHeader searchPlaceholder={"Search Emails..."} />
      {isTourStep ? (
        <Table customClassName={"emails-table"} columns={columns} dataSource={memoizedTourStepData} />
      ) : (
        <Table customClassName={"emails-table"} loading={loading} columns={columns} dataSource={tableData} />
      )}
      {selectedContact && <EmailDetailsModal open={openModal} onCancel={onCancel} selectedContact={selectedContact} />}
    </React.Fragment>
  )
}
