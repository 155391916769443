import { Dayjs } from "dayjs"
import { generateRandomDigits } from "../../../helpers"

interface Tag {
  text: string
  color: string
  type: string
}

interface Link {
  text: string
  href: string
}

interface Reminder {
  time: Dayjs
  text: string
}

interface Availability {
  [day: string]: string[]
}

interface MeetingCardType {
  id: string
  name: string
  description: string
  tags: Tag[]
  links: Link[]
  reminders: { [key: string]: Reminder }
  meetingLength: string
  timezone: string
  availability: { [key: string]: Availability }
  maximumDaysToScheduleForward: string
  minimumTimeToBookInAdvance: string
  bufferTimeBetweenMeetings: string
  isAiGenerated: boolean
}

export const meetingLinksData: MeetingCardType[] = [
  {
    id: "1",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [
      { text: "30 Min", color: "Green", type: "Meeting Length" },
      { text: "AI", color: "Purple", type: "AI Generated" },
    ],
    links: [
      {
        text: "Copy link",
        href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
      },
    ],
    reminders: {},
    meetingLength: "30 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isAiGenerated: true,
  },
  {
    id: "2",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [{ text: "40 Min", color: "Green", type: "Meeting Length" }],
    links: [
      {
        text: "Copy link",
        href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
      },
    ],
    reminders: {},
    meetingLength: "40 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isAiGenerated: false,
  },
  {
    id: "3",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [{ text: "40 Min", color: "Green", type: "Meeting Length" }],
    links: [
      {
        text: "Copy link",
        href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
      },
    ],
    reminders: {},
    meetingLength: "40 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isAiGenerated: false,
  },
  {
    id: "4",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [{ text: "40 Min", color: "Green", type: "Meeting Length" }],
    links: [
      {
        text: "Copy link",
        href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
      },
    ],
    reminders: {},
    meetingLength: "40 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isAiGenerated: false,
  },
  {
    id: "5",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [{ text: "40 Min", color: "Green", type: "Meeting Length" }],
    links: [
      {
        text: "Copy link",
        href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
      },
    ],
    reminders: {},
    meetingLength: "40 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isAiGenerated: false,
  },
  {
    id: "6",
    name: "Intro Meeting",
    description: "Meeting powered by Meetz.Ai",
    tags: [{ text: "40 Min", color: "Green", type: "Meeting Length" }],
    links: [
      {
        text: "Copy link",
        href: `App.Meetz.Ai/Cal/${generateRandomDigits(10)}`,
      },
    ],
    reminders: {},
    meetingLength: "40 Min",
    timezone: "American/New_York EST",
    availability: {},
    maximumDaysToScheduleForward: "15 Days",
    minimumTimeToBookInAdvance: "0 Min",
    bufferTimeBetweenMeetings: "0 Min",
    isAiGenerated: false,
  },
]
