export const getActionButtonTitle = (item: string) => {
  switch (true) {
    case item === "Emails" || item === "SMS":
      return "Send All"
    case item === "Phone Call":
      return "Call With Parallel Dialer"
    default:
      return "Mark all as completed"
  }
}
