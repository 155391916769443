import { Col, MenuProps, notification, Row } from "antd"
import React, { forwardRef, useContext, useEffect, useMemo, useState } from "react"
import { saveToClipboard } from "../../../helpers"
import { SvgIcon } from "../../../shared/icons"
import { MeetingLinksContext } from "../../app/context/MeetingLinksContext"
import { CustomCard } from "../../ui/CustomCard"
import { EmptyState } from "../../ui/EmptyState"

interface MeetingLinksProps {
  isTourStep?: boolean
  openEditMeetingLinkDrawer: () => void
  openDeleteMeetingLinkDrawer: () => void
  meetingLinksCardRef?: any
}

export const MeetingLinks = forwardRef<HTMLDivElement, MeetingLinksProps>(
  (
    { isTourStep, openEditMeetingLinkDrawer, openDeleteMeetingLinkDrawer, meetingLinksCardRef },
    ref
  ) => {
    const { meetingLinks, setSelectedMeetingLinkId } = useContext(MeetingLinksContext)
    const [tourStepData, setTourStepData] = useState(meetingLinks)
    const [data, setData] = useState(meetingLinks)

    useEffect(() => {
      setData(data)
    }, [meetingLinks])

    const sortMeetingLinks = (links: any) => {
      return [...links].sort((a, b) =>
        a.isAiGenerated === b.isAiGenerated ? 0 : a.isAiGenerated ? -1 : 1
      )
    }

    const sortedTourStepData = useMemo(() => sortMeetingLinks(tourStepData), [tourStepData]);

    const dropdownMenuItems: MenuProps["items"] = [
      {
        key: "1",
        label: "Edit Meeting Link",
        onClick: () => openEditMeetingLinkDrawer(),
      },
      {
        key: "2",
        label: "Delete",
        onClick: () => openDeleteMeetingLinkDrawer(),
      },
    ]

    return isTourStep ? (
      <Row gutter={[24, 24]}>
        {sortedTourStepData.map((card, index) => (
          <Col span={8} key={card.id}>
            <CustomCard
              ref={index === 0 ? meetingLinksCardRef : null}
              id={card.id}
              title={card.name}
              description={card.description}
              tags={card.tags}
              links={card.links}
              isOutlined={card.isAiGenerated}
              onDropdownClick={() => setSelectedMeetingLinkId(card.id)}
              onLinkClick={(href: string) => {
                saveToClipboard(href)
                notification.info({
                  message: "Copied",
                  description: false,
                  duration: 5,
                  icon: <SvgIcon type={"infoFilled"} />,
                  closeIcon: false,
                  placement: "bottomRight",
                })
              }}
              dropdownMenuItems={dropdownMenuItems}
            />
          </Col>
        ))}
      </Row>
    ) : data.length === 0 ? (
      <EmptyState text={"You have no meeting links created."} />
    ) : (
      <Row gutter={[24, 24]}>
        {sortMeetingLinks(data).map((card, index) => (
          <Col span={8} key={card.id}>
            <CustomCard
              ref={index === 0 ? meetingLinksCardRef : null}
              id={card.id}
              title={card.name}
              description={card.description}
              tags={card.tags}
              links={card.links}
              isOutlined={card.isAiGenerated}
              onDropdownClick={() => setSelectedMeetingLinkId(card.id)}
              onLinkClick={(href: string) => {
                saveToClipboard(href)
                notification.info({
                  message: "Copied",
                  description: false,
                  duration: 5,
                  icon: <SvgIcon type={"infoFilled"} />,
                  closeIcon: false,
                  placement: "bottomRight",
                })
              }}
              dropdownMenuItems={dropdownMenuItems}
            />
          </Col>
        ))}
      </Row>
    )
  }
)
