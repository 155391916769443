import { Col, Flex, List, Row, Steps, Typography, notification } from "antd"
import { FC, useEffect, useState } from "react"
import { SvgIcon } from "../../../../../shared/icons"
import { ConfirmButtons, Modal, TabsSwitch } from "../../../../ui"
import { CancelSubscriptionModal } from "../CancelSubscriptionModal"
import { LowerPriorityPlanModal } from "../LowerPriorityPlanModal"
import { PaymentIssueModal } from "../PaymentIssueModal"
import { PaymentMethodModal } from "../PaymentMethodModal"
import { SuccessfulActivationModal } from "../SuccessfulActivationModal"
import { enterprisePlan, plansData } from "./plansData"
import "./index.less"
import { useBreakpoints } from "../../../../../helpers/useBreakpoints"

interface PlansModalProps {
  onClose: () => void
}

export const Timer = () => {
  const [remainingTime, setRemainingTime] = useState(3 * 60 * 60)

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(prevTime => prevTime - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600)
    const minutes = Math.floor((timeInSeconds % 3600) / 60)
    const seconds = Math.floor(timeInSeconds % 60)

    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`
  }

  return (
    <Typography.Paragraph className={"discount-timer"}>{formatTime(remainingTime)}</Typography.Paragraph>
  )
}

export const PlansModal: FC<PlansModalProps> = ({ onClose }) => {
  const { isDesktop } = useBreakpoints()
  const [activeTab, setActiveTab] = useState("Meetz Plans")
  const [currentStep, setCurrentStep] = useState(0)
  const [currentPlan, setCurrentPlan] = useState<any>(plansData[1])
  const [wantedPlan, setWantedPlan] = useState(null)
  const [isLowerPriorityPlanModalOpened, setIsLowerPriorityPlanModalOpened] = useState(false)
  const [isSuccessfulActivationModalOpened, setIsSuccessfulActivationModalOpened] = useState(false)
  const [isCancelSubscriptionModalOpened, setIsCancelSubscriptionModalOpened] = useState(false)
  const [isPaymentIssueModalOpened, setIsPaymentIssueModalOpened] = useState(false)
  const [isPaymentMethodModalOpened, setIsPaymentMethodModalOpened] = useState(false)

  const handleActivePlanBtnClick = (plan: any) => {
    if (plan.id !== currentPlan?.id) {
      setWantedPlan(plan)

      if (plan.priority < currentPlan?.priority) {
        openLowerPriorityPlanModal()
      } else {
        handleChangePlan(plan)
      }
    }
  }

  const handleChangePlan = (plan: any) => {
    setCurrentPlan(plan)
    openSuccessfulActivationModal()
  }

  const handleContactSalesBtnClick = () => {
    window.location.href = "/book-meeting"
  }

  const handleCancelSubscription = () => {
    notification.info({
      message: "Subscription has been cancelled!",
      description: false,
      duration: 5,
      icon: <SvgIcon type={"infoFilled"} />,
      closeIcon: false,
      placement: "bottomRight",
    })
    setCurrentPlan(null)
    closeCancelSubscriptionModal()
  }

  const handleCheckPaymentDetails = () => {
    closePaymentIssueModal()
    openPaymentMethodModal()
  }

  const handleContactSupport = () => {
    window.location.href = "/"
  }

  const openLowerPriorityPlanModal = () => {
    setIsLowerPriorityPlanModalOpened(true)
  }

  const closeLowerPriorityPlanModal = () => {
    setIsLowerPriorityPlanModalOpened(false)
  }

  const openSuccessfulActivationModal = () => {
    setIsSuccessfulActivationModalOpened(true)
  }

  const closeSuccessfulActivationModal = () => {
    setIsSuccessfulActivationModalOpened(false)
  }

  const openCancelSubscriptionModal = () => {
    setIsCancelSubscriptionModalOpened(true)
  }

  const closeCancelSubscriptionModal = () => {
    setIsCancelSubscriptionModalOpened(false)
  }

  const openPaymentIssueModal = () => {
    setIsPaymentIssueModalOpened(true)
  }

  const closePaymentIssueModal = () => {
    setIsPaymentIssueModalOpened(false)
  }

  const openPaymentMethodModal = () => {
    setIsPaymentMethodModalOpened(true)
  }

  const closePaymentMethodModal = () => {
    setIsPaymentMethodModalOpened(false)
  }

  const stepsItems = [
    {
      title: "Today",
      description: (
        <Typography.Paragraph className={"steps-description"}>
          Start 14 Day Free Trial & Generate Traction
        </Typography.Paragraph>
      ),
    },
    {
      title: "Day 12",
      description: (
        <Typography.Paragraph className={"steps-description"}>
          An email reminder will notify you that your trial is almost over
        </Typography.Paragraph>
      ),
    },
    {
      title: "Day 14",
      description: (
        <Typography.Paragraph className={"steps-description"}>
          After your trial ends, your subscription begins.{" "}
          <Typography.Text className={"steps-link"} onClick={openCancelSubscriptionModal}>
            Cancel anytime.
          </Typography.Text>
        </Typography.Paragraph>
      ),
    },
  ]

  return (
    <>
      <Modal rootClassName={"plans-modal"} centered={isDesktop} open={true} closeIcon={false} onCancel={onClose}>
        {isDesktop ? (
          <Flex justify={"space-between"} align={"center"} className={"discount-wrapper"}>
            <Typography.Paragraph className={"discount-text"}>
              Exclusive Deal.Ai Package • 20% Discount On Your Meetz Plan, For The First 12 Months!
            </Typography.Paragraph>
            <Flex justify={"center"} className={"timer-wrapper"}>
              <Timer />
              <Row className={"gradient1"}></Row>
              <Row className={"gradient2"}></Row>
            </Flex>
          </Flex>
        ) : (
          <Flex vertical={true} align={"center"} className={"discount-wrapper"}>
            <Typography.Paragraph className={"discount-text"}>
              Exclusive Deal.Ai Package • 20% Discount On Your Meetz Plan, For The First 12 Months!
            </Typography.Paragraph>
            <Timer />
            <Flex justify={"center"} className={"timer-wrapper"}>
              <Row className={"gradient1"}></Row>
              <Row className={"gradient2"}></Row>
            </Flex>
          </Flex>
        )}
        <Flex vertical={!isDesktop} className={"header"}>
          <Flex justify={"space-between"} align={"center"} style={{ width: "100%" }}>
            <Typography.Title level={2} className={"main-title"}>
              Plans And Pricing
            </Typography.Title>
            <SvgIcon type={"close"} width={20} height={20} style={{ cursor: "pointer" }} onClick={onClose} />
          </Flex>
          <TabsSwitch
            firstTab={"Meetz Plans"}
            secondTab={"Ai Email Scheduler Plan"}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            className={"tabs"}
            isMediumSize={true}
          />
        </Flex>
        <Row className={"steps-wrapper"}>
          <Steps
            current={currentStep}
            size={"small"}
            direction={isDesktop ? "horizontal" : "vertical"}
            labelPlacement={"vertical"}
            items={stepsItems}
            progressDot={() => <Row className={"steps-dot"}></Row>}
          />
        </Row>
        <Flex vertical={!isDesktop} wrap={isDesktop ? "wrap" : "unset"} gap={24} className={"plans-wrapper"}>
          {plansData.map(plan => (
            <Flex
              vertical={true}
              className={"plan"}
              style={{
                background:
                  plan.name === "Growth"
                    ? "linear-gradient(314deg, rgb(36 235 211 / 16%) -1.63%, rgb(0 173 238 / 16%) 71.91%), #FFF"
                    : "#FFF",
              }}
            >
              <Col className={"plan-header"} style={{ paddingRight: plan.name === "Starter" ? 16 : 24}}>
                <Flex gap={8} className={"plan-title-wrapper"}>
                  <SvgIcon type={plan.iconType} width={20} height={20} />
                  <Typography.Title level={3} className={"plan-title"}>
                    {plan.name}
                  </Typography.Title>
                </Flex>
                <Typography.Paragraph className={"plan-description"}>{plan.description}</Typography.Paragraph>
              </Col>
              <Col className={"plan-main"}>
                <Col className={"plan-price-block"}>
                  <Flex gap={16} justify={"center"} align={"center"}>
                    <Typography.Paragraph className={"previous-price"}>${plan.previousPrice}</Typography.Paragraph>
                    <Typography.Paragraph className={"current-price"}>${plan.currentPrice}/mo.</Typography.Paragraph>
                  </Flex>
                  <Typography.Paragraph className={"plan-discount"}>{plan.discountPercent}% Off</Typography.Paragraph>
                </Col>
                <List
                  dataSource={plan.advantages}
                  renderItem={item => (
                    <List.Item>
                      <SvgIcon type={"checked2"} width={24} height={24} className={"advantage-icon"} />
                      {item}
                    </List.Item>
                  )}
                  style={{ marginTop: 16 }}
                />
              </Col>
              <ConfirmButtons
                className={"confirm-buttons"}
                leftButtonTitle={"14 Day Free Trial"}
                rightButtonTitle={plan.id === currentPlan?.id ? "Current plan" : "Active Trial"}
                handleSubmit={() => handleActivePlanBtnClick(plan)}
                disabled={plan.id === currentPlan?.id}
              />
            </Flex>
          ))}
          {!isDesktop && (
            <Flex
              vertical={true}
              className={"plan"}
              style={{
                background:
                  enterprisePlan.name === "Growth"
                    ? "linear-gradient(314deg, rgb(36 235 211 / 16%) -1.63%, rgb(0 173 238 / 16%) 71.91%), #FFF"
                    : "#FFF",
              }}
            >
              <Col className={"plan-header"} style={{ paddingRight: enterprisePlan.name === "Starter" ? 16 : 24}}>
                <Flex gap={8} className={"plan-title-wrapper"}>
                  <SvgIcon type={enterprisePlan.iconType} width={20} height={20} />
                  <Typography.Title level={3} className={"plan-title"}>
                    {enterprisePlan.name}
                  </Typography.Title>
                </Flex>
                <Typography.Paragraph className={"plan-description"}>{enterprisePlan.description}</Typography.Paragraph>
              </Col>
              <Col className={"plan-main"}>
                <List
                  dataSource={enterprisePlan.advantages}
                  renderItem={item => (
                    <List.Item>
                      <SvgIcon type={"checked2"} width={24} height={24} className={"advantage-icon"} />
                      {item}
                    </List.Item>
                  )}
                />
                <ConfirmButtons
                  rightButtonTitle={"Contact Sales"}
                  handleSubmit={handleContactSalesBtnClick}
                  style={{ width: 238, marginTop: 28 }}
                />
              </Col>
            </Flex>
          )}
          {isDesktop && (
            <Row className={"enterprise-wrapper"}>
              <Col className={"enterprise-left-col"}>
                <Flex gap={8} align={"center"}>
                  <SvgIcon type={"people"} width={20} height={20} />
                  <Typography.Title level={3} className={"enterprise-title"}>
                    Enterprise
                  </Typography.Title>
                </Flex>
                <Typography.Paragraph className={"enterprise-description"}>
                  Have a team that needs more robust lead generation and cross team scheduling functionality?
                </Typography.Paragraph>
              </Col>
              <Flex gap={24} className={"enterprise-right-col"}>
                <List
                  className={"enterprise-list"}
                  dataSource={enterprisePlan.advantages}
                  renderItem={item => (
                    <List.Item>
                      <SvgIcon type={"checked2"} width={16} height={16} className={"advantage-icon"} />
                      {item}
                    </List.Item>
                  )}
                />
                <ConfirmButtons
                  rightButtonTitle={"Contact Sales"}
                  handleSubmit={handleContactSalesBtnClick}
                  style={{ width: 238 }}
                />
              </Flex>
            </Row>
          )}
        </Flex>
      </Modal>
      {isLowerPriorityPlanModalOpened && (
        <LowerPriorityPlanModal
          handleChangePlan={() => handleChangePlan(wantedPlan)}
          onClose={closeLowerPriorityPlanModal}
        />
      )}
      {isSuccessfulActivationModalOpened && (
        <SuccessfulActivationModal currentPlanName={currentPlan?.name} onClose={closeSuccessfulActivationModal} />
      )}
      {isCancelSubscriptionModalOpened && (
        <CancelSubscriptionModal cancelSubscription={handleCancelSubscription} onClose={closeCancelSubscriptionModal} />
      )}
      {isPaymentIssueModalOpened && (
        <PaymentIssueModal
          contactSupport={handleContactSupport}
          checkPaymentDetails={handleCheckPaymentDetails}
          onClose={closePaymentIssueModal}
        />
      )}
      {isPaymentMethodModalOpened && <PaymentMethodModal onClose={closePaymentMethodModal} />}
    </>
  )
}
