import React, { FC, ReactNode, useState } from "react"
import PageLayout from "src/components/layout/PageLayout"
import { ConfirmButtons } from "src/components/ui"
import { CreateCampaignsStepsProgress } from "./ui/StepsProgress"
import { StepTwo, StepThree, StepOne } from "./ui"
import { useNavigate } from "react-router"
import { Row, Typography } from "antd"
import { SvgIcon } from "src/shared/icons"

type Step = {
  key: string
  step: number
  component: ReactNode
}

const steps: Step[] = [
  { key: "contact", step: 0, component: <StepOne /> },
  { key: "business", step: 1, component: <StepTwo /> },
  { key: "financial", step: 2, component: <StepThree /> },
]

export const AIGeneratedSequence: FC = () => {
  const navigate= useNavigate()
  const [step, setStep] = useState<number>(0)

  const onNextStep = () => {
    if (step !== steps?.length) {
      setStep(prevState => prevState + 1)
    }
  }
  const onPreviousStep = () => {
    setStep(prevStep => prevStep - 1)
  }

  const onFinish = () => {
    console.log("Campaign activated ")
    navigate("/campaigns")
  }

  const rightHeaderCreateContent = (
    <ConfirmButtons
      width={step !== 0 ? "233px" : "91px"}
      leftButtonTitle={step !== 0 ? "Back" : null}
      rightButtonTitle={step!== steps.length - 1 ? "Next step" : "Activate Campaign" }
      onCancel={onPreviousStep}
      handleSubmit={step!== steps.length - 1 ? onNextStep : onFinish}
    />
  )

  const pageTitle =
    <Row align={"middle"} style={{gap: 16}}>
      <SvgIcon type={"arrowBack"} onClick={()=>navigate("/campaigns")} style={{cursor: "pointer"}}/>
      <Typography.Title level={2} className={"page-title"}>
        {"Creating AI Generated Sequence"}
      </Typography.Title>
    </Row>


  return (
    <PageLayout
      pageTitle={pageTitle}
      bottomHeaderContent={<CreateCampaignsStepsProgress currentStep={step} />}
      rightHeaderContent={rightHeaderCreateContent}
      isTableContent={false}
    >
      {steps[step].component}
    </PageLayout>
  )
}
