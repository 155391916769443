export const overviewData = [
  {
    date: "22 Dec",
    type: "Ai emails",
    value: 60,
  },
  {
    date: "22 Dec",
    type: "Replied",
    value: 130,
  },
  {
    date: "22 Dec",
    type: "Booked meetings",
    value: 150,
  },
  {
    date: "22 Dec",
    type: "Opened",
    value: 160,
  },
  {
    date: "22 Dec",
    type: "Bounced",
    value: 60,
  },
  {
    date: "23 Dec",
    type: "Ai emails",
    value: 25,
  },
  {
    date: "23 Dec",
    type: "Replied",
    value: 100,
  },
  {
    date: "23 Dec",
    type: "Booked meetings",
    value: 170,
  },
  {
    date: "23 Dec",
    type: "Opened",
    value: 135,
  },
  {
    date: "23 Dec",
    type: "Bounced",
    value: 100,
  },
  {
    date: "24 Dec",
    type: "Ai emails",
    value: 70,
  },
  {
    date: "24 Dec",
    type: "Replied",
    value: 150,
  },
  {
    date: "24 Dec",
    type: "Booked meetings",
    value: 110,
  },
  {
    date: "24 Dec",
    type: "Opened",
    value: 100,
  },
  {
    date: "24 Dec",
    type: "Bounced",
    value: 370,
  },
  {
    date: "25 Dec",
    type: "Ai emails",
    value: 350,
  },
  {
    date: "25 Dec",
    type: "Replied",
    value: 160,
  },
  {
    date: "25 Dec",
    type: "Booked meetings",
    value: 140,
  },
  {
    date: "25 Dec",
    type: "Opened",
    value: 80,
  },
  {
    date: "25 Dec",
    type: "Bounced",
    value: 350,
  },
  {
    date: "26 Dec",
    type: "Ai emails",
    value: 260,
  },
  {
    date: "26 Dec",
    type: "Replied",
    value: 160,
  },
  {
    date: "26 Dec",
    type: "Booked meetings",
    value: 150,
  },
  {
    date: "26 Dec",
    type: "Opened",
    value: 180,
  },
  {
    date: "26 Dec",
    type: "Bounced",
    value: 450,
  },
  {
    date: "27 Dec",
    type: "Ai emails",
    value: 230,
  },
  {
    date: "27 Dec",
    type: "Replied",
    value: 170,
  },
  {
    date: "27 Dec",
    type: "Booked meetings",
    value: 180,
  },
  {
    date: "27 Dec",
    type: "Opened",
    value: 190,
  },
  {
    date: "27 Dec",
    type: "Bounced",
    value: 230,
  },
  {
    date: "28 Dec",
    type: "Ai emails",
    value: 130,
  },
  {
    date: "28 Dec",
    type: "Replied",
    value: 110,
  },
  {
    date: "28 Dec",
    type: "Booked meetings",
    value: 70,
  },
  {
    date: "28 Dec",
    type: "Opened",
    value: 180,
  },
  {
    date: "28 Dec",
    type: "Bounced",
    value: 350,
  },
]

export const overviewPhoneAndSmsData = [
  {
    date: "22 Dec",
    type: "Attempted calls",
    value: 50,
  },
  {
    date: "22 Dec",
    type: "Connected calls",
    value: 100,
  },
  {
    date: "22 Dec",
    type: "Sms Incoming",
    value: 130,
  },
  {
    date: "22 Dec",
    type: "SMS Sent",
    value: 200,
  },
  {
    date: "23 Dec",
    type: "Attempted calls",
    value: 250,
  },
  {
    date: "23 Dec",
    type: "Connected calls",
    value: 130,
  },
  {
    date: "23 Dec",
    type: "Sms Incoming",
    value: 120,
  },
  {
    date: "23 Dec",
    type: "SMS Sent",
    value: 135,
  },
  {
    date: "24 Dec",
    type: "Attempted calls",
    value: 70,
  },
  {
    date: "24 Dec",
    type: "Connected calls",
    value: 150,
  },
  {
    date: "24 Dec",
    type: "Sms Incoming",
    value: 110,
  },
  {
    date: "24 Dec",
    type: "SMS Sent",
    value: 100,
  },
  {
    date: "25 Dec",
    type: "Attempted calls",
    value: 350,
  },
  {
    date: "25 Dec",
    type: "Connected calls",
    value: 160,
  },
  {
    date: "25 Dec",
    type: "Sms Incoming",
    value: 140,
  },
  {
    date: "25 Dec",
    type: "SMS Sent",
    value: 80,
  },
  {
    date: "26 Dec",
    type: "Attempted calls",
    value: 260,
  },
  {
    date: "26 Dec",
    type: "Connected calls",
    value: 160,
  },
  {
    date: "26 Dec",
    type: "Sms Incoming",
    value: 150,
  },
  {
    date: "26 Dec",
    type: "SMS Sent",
    value: 180,
  },
  {
    date: "27 Dec",
    type: "Attempted calls",
    value: 230,
  },
  {
    date: "27 Dec",
    type: "Connected calls",
    value: 170,
  },
  {
    date: "27 Dec",
    type: "Sms Incoming",
    value: 350,
  },
  {
    date: "27 Dec",
    type: "SMS Sent",
    value: 130,
  },
  {
    date: "28 Dec",
    type: "Attempted calls",
    value: 130,
  },
  {
    date: "28 Dec",
    type: "Connected calls",
    value: 110,
  },
  {
    date: "28 Dec",
    type: "Sms Incoming",
    value: 400,
  },
  {
    date: "28 Dec",
    type: "SMS Sent",
    value: 180,
  },
]
