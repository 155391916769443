import { Flex, Typography } from "antd"
import React, { FC, useState } from "react"
import { useNavigate } from "react-router"
import { SvgIcon } from "src/shared/icons"
import { CreateFromSavedModal, CreateFromScratchModal } from "../index"
import "../../index.less"

type CreateCampaignMethodSelectProps = {
  onCancel?: VoidFunction
  isModalContent?: boolean
}

export const CreateCampaignMethodSelect: FC<CreateCampaignMethodSelectProps> = ({
  onCancel,
  isModalContent = true,
}) => {
  const [openFromSavedModal, setOpenFromSavedModal] = useState(false)
  const [openFromScratchModal, setOpenFromScratchModal] = useState(false)
  const navigate = useNavigate()

  const showFromSavedModal = () => {
    setOpenFromSavedModal(true)
  }
  const showFromScratchModal = () => {
    setOpenFromScratchModal(true)
  }

  const closeModal = () => {
    setOpenFromSavedModal(false)
    setOpenFromScratchModal(false)
  }

  const createCampaignItem = [
    {
      icon: (
        <SvgIcon type={"aiGeneratedSequence"} width={!isModalContent ? 28 : 40} height={!isModalContent ? 28 : 40} />
      ),
      title: "AI Generated Sequence",
      subtitle: isModalContent && "Create a simple outbound sequence with AI.",
      onClick: () => {
        navigate("/campaign/ai-generated")
        onCancel && onCancel()
      },
    },
    {
      icon: <SvgIcon type={"fromScratch"} width={!isModalContent ? 28 : 40} height={!isModalContent ? 28 : 40} />,
      title: "From scratch",
      subtitle: isModalContent && "Create a new sequence from scratch.",
      onClick: () => {
        showFromScratchModal()
        onCancel && onCancel()
      },
    },
    {
      icon: <SvgIcon type={"fromSaved"} width={!isModalContent ? 28 : 40} height={!isModalContent ? 28 : 40} />,
      title: "Copy Campaign & Automatically Optimize",
      subtitle: isModalContent && "Create a new sequence from a previous campaign made.",
      onClick: () => {
        showFromSavedModal()
        onCancel && onCancel()
      },
    },
  ]

  return (
    <>
      <Flex vertical gap={8}>
        {createCampaignItem.map((item, index) => (
          <Flex
            key={`${index}-${item.title}`}
            className={isModalContent ? "modal-item" : "actions-item"}
            align={"center"}
            gap={16}
            onClick={item.onClick}
          >
            {item.icon}
            <Flex gap={2} vertical>
              <Typography.Paragraph className={isModalContent ? "item-title-modal" : "item-title"}>
                {item.title}
              </Typography.Paragraph>
              {isModalContent && (
                <Typography.Paragraph className={"item-subtitle"}>{item.subtitle}</Typography.Paragraph>
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
      <CreateFromSavedModal open={openFromSavedModal} onCancel={closeModal} />
      <CreateFromScratchModal open={openFromScratchModal} onCancel={closeModal} />
    </>
  )
}
