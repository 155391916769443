import { Flex, Input, Radio, RadioChangeEvent, Space, Typography } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"
import { UpdatePlanBanner } from "../../../../../UpdatePlanBanner"

const { Text } = Typography

type ExtractionFileModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const ExtractionFileModal: FC<ExtractionFileModalProps> = ({ open, onCancel }) => {
  const [value, setValue] = useState("Winter Wonderland Discounts")

  const closeModal = () => {
    onCancel()
  }

  const onChange = (e: RadioChangeEvent) => {
    console.log("radio checked", e.target.value)
    setValue(e.target.value)
  }

  return (
    <Modal centered open={open} title={"Select extraction file"} onCancel={closeModal}>
      <Flex gap={16} vertical>
        <Space direction={"vertical"}>
          <Text>Files from Meetz database</Text>
          <Input.Search className={"search-input"} placeholder={"Search..."} allowClear enterButton={false} />
        </Space>
        <Flex gap={28} vertical>
          <Radio.Group buttonStyle={"solid"} onChange={onChange} value={value}>
            <Space direction={"vertical"}>
              <Radio value={"Example Prospect Data"}>Example Prospect Data</Radio>
            </Space>
          </Radio.Group>
          <UpdatePlanBanner
            title={"Get real prospect data!"}
            subtitle={"Start your free trial now to access genuine prospect data."}
          />
          <ConfirmButtons onCancel={closeModal} leftButtonTitle={"Back"} rightButtonTitle={"Add"} />
        </Flex>
      </Flex>
    </Modal>
  )
}
