import React, { FC, useContext, useState } from "react";
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext";
import { ApplyFromSavedModal, CheckboxGroupFilterItem, InputFilterItem, ProspectFilterHeader, SaveFiltersModal, SelectFilterItem } from "./ui";
import { contactTypeList, departmentOptions, headcountList, industryOptions, revenueList, seniorityLevelList } from "../../info";
import { ConfirmButtons, FilterTagsList } from "src/components/ui";
import { SvgIcon } from "src/shared/icons";
import { Button, Collapse, Divider, Form, FormInstance, Row, Typography, Cascader, Checkbox, Space, Flex, CollapseProps } from "antd"
import "./index.less";

interface ProspectFilterProps {
  form: FormInstance;
  ref?: any;
  isTourStep?: boolean
}

export const savedFiltersData = [
  {
    id: 1,
    name: "Finance and C-level",
    filterTags: ["Adams", "C-level", "Staff", "$0-1M"],
  },
  {
    id: 2,
    name: "Winter Wonderland Discounts",
    filterTags: ["Crane", "Staff", "$10-50M"],
  },
];

export const totalTagsList = (filterFormData: any) => filterFormData && Object.values(filterFormData).flat().filter(value => value !== undefined) as string[];

export const ProspectFilterCollapse: FC<ProspectFilterProps> = React.forwardRef<HTMLDivElement, ProspectFilterProps>(
  ({ form, isTourStep }, ref) => {
    const [openSaveFilterModal, setOpenSaveFilterModal] = useState(false);
    const [openFromSavedModal, setOpenFromSavedModal] = useState(false);
    const { filterFormData, setFilterFormData } = useContext(ProspectDataContext);

    const totalTagsLength = totalTagsList(filterFormData)?.length || 0;
    const [selectedTags, setSelectedTags] = useState<string[]>(totalTagsList(filterFormData) ?? []);
    const getCount = (data: any) => data?.length ? `(${data?.length})` : "";
    const onIndustryChange = (value: any) => {
      const industryTag = value.flatMap((item: string[]) => item[1]);
      setFilterFormData({ ...filterFormData, industry: industryTag });
    };

    const findField = (propertyName: string, object: any): string | null => {
      for (const field in object) {
        if (Object.prototype.hasOwnProperty.call(object, field)) {
          if (object[field]?.includes(propertyName)) {
            return field;
          }
        }
      }
      return null;
    };

    const handleTagRemove = (removedTag: string) => {
      const fieldToUpdate = findField(removedTag, filterFormData);
      const newSelectedTags = selectedTags.filter(tag => tag !== removedTag);
      setSelectedTags(newSelectedTags);

      const updatedFieldValues = fieldToUpdate === "firstName" ? filterFormData["firstName"] : form.getFieldValue(fieldToUpdate);

      const newFieldValues = updatedFieldValues.filter((value: any) => value !== removedTag);
      if (fieldToUpdate) form.setFieldsValue({ [fieldToUpdate]: newFieldValues });

      setFilterFormData(form.getFieldsValue());
    };

    const handleClearAll = () => {
      form.resetFields();
      setFilterFormData(null);
    };

    const handleClearField = (fieldName: string) => {
      form.setFieldsValue({ [fieldName]: undefined });
      setFilterFormData({ ...filterFormData, [fieldName]: undefined });
    };

    const items: CollapseProps['items'] = [
      ...(totalTagsLength !== 0 ? [{
        key: "0",
        label: `Selected Filters (${totalTagsLength})`,
        extra: <Button className={"link"} onClick={handleClearAll}>Clear all</Button>,
        children:
          <Space direction={"vertical"}>
            <Row justify={"space-between"} align={"middle"}>
              <Typography.Paragraph>{`Selected filters `}</Typography.Paragraph>
            </Row>
            <FilterTagsList data={totalTagsList(filterFormData) ?? []} form={form} onTagRemove={handleTagRemove}/>
          </Space>
      }] : []),
      {
        key: "1",
        label: `First Name ${getCount(filterFormData?.firstName)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("firstName")}>Clear</Button>,
        children: <InputFilterItem
          // data={filterFormData?.firstName}
          form={form} fieldName={"firstName"} placeholder={"John"}/>
      },
      {
        key: "2",
        label: `Last Name ${getCount(filterFormData?.lastName)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("lastName")}>Clear</Button>,
        children: <SelectFilterItem data={filterFormData?.lastName} form={form} fieldName={"lastName"} placeholder={"Smith"}/>
      },
      {
        key: "3",
        label: `Job Title ${getCount(filterFormData?.jobTitle)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("jobTitle")}>Clear</Button>,
        children: <SelectFilterItem data={filterFormData?.jobTitle} form={form} fieldName={"jobTitle"} placeholder={"VP of Product"}/>
      },
      {
        key: "4",
        label: `Department ${getCount(filterFormData?.department)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("department")}>Clear</Button>,
        children: <SelectFilterItem data={filterFormData?.department} form={form} fieldName={"department"} staticOptions={departmentOptions}/>
      },
      {
        key: "5",
        label: `Seniority level ${getCount(filterFormData?.seniorityLevel)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("seniorityLevel")}>Clear</Button>,
        children: <CheckboxGroupFilterItem data={filterFormData?.seniorityLevel} form={form} fieldName={"seniorityLevel"} checkboxList={seniorityLevelList}/>
      },
      {
        key: "6",
        label: `Company name ${getCount(filterFormData?.company)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("company")}>Clear</Button>,
        children: <SelectFilterItem data={filterFormData?.company} form={form} fieldName={"company"} placeholder={"Facebook"}/>
      },
      {
        key: "7",
        label: `Company URL ${getCount(filterFormData?.companyUrl)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("companyUrl")}>Clear</Button>,
        children: <SelectFilterItem data={filterFormData?.companyUrl} form={form} fieldName={"companyUrl"} placeholder={"www.facebook.com"}/>
      },
      {
        key: "8",
        label: `Company headcount ${getCount(filterFormData?.headcount)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("headcount")}>Clear</Button>,
        children: <CheckboxGroupFilterItem data={filterFormData?.headcount} form={form} fieldName={"headcount"} checkboxList={headcountList}/>
      },
      {
        key: "9",
        label: `Revenue ${getCount(filterFormData?.revenue)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("revenue")}>Clear</Button>,
        children: <CheckboxGroupFilterItem data={filterFormData?.revenue} form={form} fieldName={"revenue"} checkboxList={revenueList}/>
      },
      {
        key: "10",
        label: `Industry ${getCount(filterFormData?.industry)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("industry")}>Clear</Button>,
        children:
          <>
            <Form.Item name={"industry"}>
              <Cascader
                style={{ width: "100%" }}
                options={industryOptions}
                onChange={onIndustryChange}
                placement={"bottomRight"}
                tagRender={() => <></>}
                multiple
              />
            </Form.Item>
            <FilterTagsList data={filterFormData?.industry} form={form} fieldToUpdate={"industry"}/>
          </>
      },
      {
        key: "11",
        label: `Technologies used ${getCount(filterFormData?.technologies)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("technologies")}>Clear</Button>,
        children: <SelectFilterItem data={filterFormData?.technologies} form={form} fieldName={"technologies"} placeholder={"Tag Manager"}/>
      },
      {
        key: "12",
        label: `Countries ${getCount(filterFormData?.country)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("country")}>Clear</Button>,
        children:  <SelectFilterItem data={filterFormData?.country} form={form} fieldName={"country"} placeholder={"United States"}/>
      },
      {
        key: "13",
        label: `State ${getCount(filterFormData?.state)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("state")}>Clear</Button>,
        children: <SelectFilterItem data={filterFormData?.state} form={form} fieldName={"state"} placeholder={"New York"}/>
      },
      {
        key: "14",
        label: `Exclude Contacts Extracted  ${getCount(filterFormData?.exclude)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("exclude")}>Clear</Button>,
        children: <>
          <Form.Item name={"exclude"}>
            <Checkbox.Group>
              <Checkbox value={"Exclude"}>Exclude</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <FilterTagsList data={filterFormData?.exclude} form={form} fieldToUpdate={"exclude"}/>
        </>
      },
      {
        key: "15",
        label: `Filter Contacts That Have Phone Numbers (Company/Direct/Mobile) ${getCount(filterFormData?.phoneNumber)}`,
        extra: <Button className={"link"} onClick={()=>handleClearField("phoneNumber")}>Clear</Button>,
        children:
          <CheckboxGroupFilterItem
            className={"large-label-item"}
            data={filterFormData?.phoneNumber}
            form={form}
            fieldName={"phoneNumber"}
            checkboxList={contactTypeList}
            style={{ marginBottom: 0 }}
          />
      },
    ].filter(Boolean)

    const showSaveFilterModal = () => {
      setOpenSaveFilterModal(true);
    };

    const showFromSavedModal = () => {
      setOpenFromSavedModal(true);
    };
    const onCloseModal = () => {
      setOpenSaveFilterModal(false);
      setOpenFromSavedModal(false);
    };
    const onValueChange = (changedValues: any, allValues: any) => {
      if (!changedValues.hasOwnProperty('firstName')) {
        setFilterFormData(allValues);
      }
    };

    const handleFormSubmit = () => {
      console.log("Export test");
    };

    return (
      <>
        <div ref={ref}>
          <Form
            form={form}
            className={"prospect-filter-wrapper"}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            onValuesChange={onValueChange}
            onFinish={handleFormSubmit}
          >
            <ProspectFilterHeader
              totalTagsLength={totalTagsLength}
              openSaveModal={showSaveFilterModal}
              openFromSavedModal={showFromSavedModal}
            />
            <Divider style={{ margin: 0 }} />
            <Collapse
              className={"prospects-filter-collapse"}
              items={items}
              expandIcon={({ isActive }) => (
                <SvgIcon type={"arrowCollapse"} style={{ transform: isActive ? "rotate(0deg)" : "rotate(180deg)" }} />
              )}
              bordered={false}
              collapsible={"header"}
            />
            <Flex style={{ padding: 24 }}>
              <ConfirmButtons rightButtonTitle={"Apply now"} leftButtonTitle={"Reset all"} onCancel={handleClearAll} />
            </Flex>
          </Form>
        </div>
        {!isTourStep && (
          <>
            <ApplyFromSavedModal data={savedFiltersData} open={openFromSavedModal} onCancel={onCloseModal} />
            <SaveFiltersModal data={filterFormData} open={openSaveFilterModal} onCancel={onCloseModal} />
          </>
        )}
      </>
    );
  }
);
