import { Flex, Radio, Row, Typography } from "antd"
import React, { FC, useState } from "react"
import { ConfirmButtons, Modal, DotsMenuDropdown } from "src/components/ui"
import { SaveValueModal } from "../SaveValueModal"

type SelectFromSavedModalProps = {
  open: boolean
  onCancel: VoidFunction
  data: any
}



export const SelectFromSavedModal: FC<SelectFromSavedModalProps> = ({data, open, onCancel }) => {
  const [openSaveValueModal, setOpenSaveValueModal] = useState(false)
  const [selectedValue, setSelectedValue] = useState<any>()

  const showSaveValueModal=()=> {
    setOpenSaveValueModal(true)
  }

  const items = [
    {
      key: "1",
      label: "Edit",
      onClick: showSaveValueModal,
    },
    {
      key: "2",
      label: "Delete",
    },
    {
      key: "3",
      label: "Duplicate",
    },
  ]
  const closeModal = () => {
    onCancel()
  }



  const handleCloseSaveValueModal = () => {
    setOpenSaveValueModal(false)
  }

  const handleFormSubmit = () => {
    console.log("Selected")
    onCancel()
  }

  const handleRadioBtnChange = (e: any) => {
    setSelectedValue(e.target.value)
  }

  return (
  <>
  <Modal width={530} open={open} title={"Select From Saved Value Proposition"} onCancel={closeModal}>
    <Radio.Group
      name={"value"}
      value={selectedValue}
      onChange={handleRadioBtnChange}
      style={{ width: "100%"}}

    >
      <Flex vertical gap={16} style={{marginBottom:28}}>
        {data.map((item: any)=> (
          <Flex key={item.id} vertical gap={8} >
            <Row justify={"space-between"} align={"middle"} wrap={false}>
          <Radio value={item.name} key={item.id}>
                <Typography.Paragraph className={"value-name"}>{item.name}</Typography.Paragraph>
          </Radio>
              <DotsMenuDropdown menuItems={items}/>
            </Row>
          <Typography.Paragraph ellipsis style={{maxWidth:435, marginLeft: 24, color: "#757A89"}}>{item.description}</Typography.Paragraph>
         </Flex>
        ))}
      </Flex>
    </Radio.Group>
    <ConfirmButtons rightButtonTitle={"Select"} leftButtonTitle={"Back"} onCancel={closeModal} handleSubmit={handleFormSubmit}/>
  </Modal>
   <SaveValueModal isEdit open={openSaveValueModal} onCancel={handleCloseSaveValueModal} />
  </>)
}
