import { Button, Flex, Typography } from "antd"
import React, { FC, useState } from "react"
import { SelectStepModal } from "src/components/pages/campaigns/CreateCampaign/ui/SelectStepModal"
import noData from "src/shared/images/noData.png"

export const AddStepPage: FC = () => {
  const [openModal, setOpenModal] = useState(false)
  const showModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Flex vertical gap={16} style={{ padding: "188px 361px" }} align={"center"}>
        <img src={noData} alt={"no data picture"} width={173} />
        <Typography.Paragraph className={"empty-data"} style={{ textAlign: "center" }}>
          You have no steps created. Create one, and the data will appear here.
        </Typography.Paragraph>
        <Button className={"primary"} onClick={showModal} style={{ width: 120 }}>
          Add Step
        </Button>
      </Flex>
      <SelectStepModal open={openModal} onCancel={closeModal} />
    </>
  )
}
