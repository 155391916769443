import { SliderSingleProps } from "antd"

export const shortenEmailOptions: SliderSingleProps["marks"] = {
  0: "Tiny",
  25: "Small",
  50: "Medium",
  75: "Large",
  100: "Extra Large",
}
export const helpingHandOptions: SliderSingleProps["marks"] = {
  0: "Minimal",
  25: "Moderate",
  50: "Substantial",
  75: "Comprehensive",
  100: " Extensive ",
}
export const providingValueOptions: SliderSingleProps["marks"] = {
  0: "Basic",
  25: "Enhanced",
  50: "Significant",
  75: "Maximum",
  100: "Exceptional",
}
export const discussingPainOptions: SliderSingleProps["marks"] = {
  0: "Identifying \n" + "Concerns",
  25: "Recognizing \n" + "Issues",
  50: "Resolving \n" + "Pain Points",
  75: "Complete \n" + "Resolution",
  100: "Total \n" + "Transformation",
}

export const callToActionsOptions = [
  { label: "Is this of interest?", value: "Is this of interest?" },
  { label: "Book Meeting With Meetz Link", value: "Book Meeting With Meetz Link" },
]

export const reactiveMeetingLinkOptions = [{ label: "Intro Meeting", value: "Intro Meeting" }]

export const salesMethodologyOptions = [
  { label: "Consultative", value: "Consultative" },
  { label: "Strong Sales", value: "Strong Sales" },
  { label: "Urgency", value: "Urgency" },
  { label: "Emotional Intelligence", value: "Emotional Intelligence" },
]

export const companyNameOptions = [
  { label: "No Special Characters", value: "No Special Characters" },
  { label: "No Company Initials Ex: Inc..com", value: "No Company Initials Ex: Inc..com" },
  { label: "Urgency", value: "Urgency" },
  { label: "1 Spelling Mistake", value: "1 Spelling Mistake" },
  { label: "1 Lower Case", value: "1 Lower Case" },
]

export const languageOptions = [{ label: "English (Default)", value: "English" }]

export const savedValueProposition  = [
  {
    id: "1",
    name: "Winter Wonderland Discounts",
    description: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
  },
  {
    id: "2",
    name: "Summer Wonderland",
    description: "A design system for enterprise-level products. Create an efficient and enjoyable work experience.",
  }
]