import { Layout } from "antd"
import { FC, Suspense, useContext, useState } from "react"
import { Outlet } from "react-router-dom"
import { SideBarMenu } from "src/components/layout/SideBarMenu"
import { ParallelDialerModal } from "src/components/pages/campaigns/CampaignDetails/ui"
import { CampaignsContext } from "../../app/context/CampaignsContext"
import { Loader } from "../../ui"
import { DefaultHeader } from "../DefaultHeader"
import { HowToUseCard } from "../SideBarMenu/HowToUseCard"
import { WelcomeTour } from "../SideBarMenu/Tour"
import { CommonContext } from "../../app/context/CommonContext"
import { ViewingProcess } from "../../ui/ViewingProcess"
import { useBreakpoints } from "../../../helpers/useBreakpoints"

const { Sider, Header } = Layout

const RegisteredLayout: FC = () => {
  const [collapsed, setCollapsed] = useState(false)
  const { openParallelDialerModal, setOpenParallelDialerModal } = useContext(CampaignsContext)
  const { isTourOpened, isViewingProcess } = useContext(CommonContext)
  const { isDesktop } = useBreakpoints()

  return isDesktop ? isTourOpened ? (
    <WelcomeTour />
  ) : (
    <Layout hasSider style={{ position: "relative", minHeight: "100vh" }}>
      <Sider
        collapsible
        className={"authenticated-slider"}
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        width={280}
        style={{ padding: collapsed ? "28px 14px" : "37px 28px", height: "auto", borderRight: "1px solid #EDEFF3" }}
      >
        <SideBarMenu isLogoLetter={collapsed} />
        <HowToUseCard isCollapsed={collapsed} />
      </Sider>
      <Layout.Content style={{ width: "100%", padding: "0 auto" }}>
        <Header style={{ height: 80 }}>
          <DefaultHeader />
        </Header>
        <Suspense fallback={<Loader size={"large"} style={{ padding: "50vh" }} />}>
          <Outlet />
        </Suspense>
      </Layout.Content>
      {openParallelDialerModal && <ParallelDialerModal onCancel={() => setOpenParallelDialerModal(false)} />}
      {isViewingProcess && <ViewingProcess />}
    </Layout>
  ) : (
    <>
      <Suspense fallback={<Loader size={"large"} style={{ padding: "50vh" }} />}>
        <Outlet />
      </Suspense>
    </>
  )
}

export { RegisteredLayout }
