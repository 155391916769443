import React, { FC, ForwardedRef, forwardRef, useState } from "react"
import PageLayout from "../../components/layout/PageLayout"
import { MeetingLinks } from "../../components/pages/meeting-links"
import { AdditionalAvailabilityDrawer } from "../../components/pages/meeting-links/ui/AdditionalAvailabilityDrawer"
import { DeleteMeetingLinkModal } from "../../components/pages/meeting-links/ui/DeleteMeetingLinkModal"
import { MeetingLinkDrawer } from "../../components/pages/meeting-links/ui/MeetingLinkDrawer"
import { ConfirmButtons } from "../../components/ui"
import { useBreakpoints } from "../../helpers/useBreakpoints"
import { GoToDesktopModal } from "../../components/ui/GoToDesktopModal"

interface MeetingLinksPageProps {
  isTourStep?: boolean;
  meetingLinksCardRef?: ForwardedRef<HTMLDivElement>;
  meetingLinksAddLinkRef?: ForwardedRef<HTMLDivElement>;
  meetingLinksAdditionalAvailabilityRef?: ForwardedRef<HTMLDivElement>;
}

const MeetingLinksPage: FC<MeetingLinksPageProps> = forwardRef<HTMLDivElement, MeetingLinksPageProps>(({ isTourStep, meetingLinksCardRef, meetingLinksAddLinkRef, meetingLinksAdditionalAvailabilityRef }, ref) => {
  const { isDesktop } = useBreakpoints()
  const [isAdditionalDrawerOpened, setIsAdditionalDrawerOpened] = useState( false)
  const [isAddMeetingLinkDrawerOpened, setIsAddMeetingLinkDrawerOpened] = useState(false)
  const [isEditMeetingLinkDrawerOpened, setIsEditMeetingLinkDrawerOpened] = useState(false)
  const [isDeleteMeetingLinkModalOpened, setIsDeleteMeetingLinkModalOpened] = useState(false)

  const openAdditionalDrawer = () => {
    setIsAdditionalDrawerOpened(true)
  }

  const closeAdditionalDrawer = () => {
    setIsAdditionalDrawerOpened(false)
  }

  const openEditMeetingLinkDrawer = () => {
    setIsEditMeetingLinkDrawerOpened(true)
  }

  const closeEditMeetingLinkDrawer = () => {
    setIsEditMeetingLinkDrawerOpened(false)
  }

  const openDeleteMeetingLinkModal = () => {
    setIsDeleteMeetingLinkModalOpened(true)
  }

  const closeDeleteMeetingLinkModal = () => {
    setIsDeleteMeetingLinkModalOpened(false)
  }

  const openAddMeetingLinkModal = () => {
    setIsAddMeetingLinkDrawerOpened(true)
  }

  const closeAddMeetingLinkModal = () => {
    setIsAddMeetingLinkDrawerOpened(false)
  }

  return isDesktop ? (
    <PageLayout
      pageTitle={"Meeting Links"}
      rightHeaderContent={
        <ConfirmButtons
          leftBtnRef={meetingLinksAddLinkRef}
          rightBtnRef={meetingLinksAdditionalAvailabilityRef}
          leftButtonTitle={"Add link"}
          rightButtonTitle={"Additional availability"}
          width={"fit-content"}
          style={{ gap: 18 }}
          handleSubmit={openAdditionalDrawer}
          onCancel={openAddMeetingLinkModal}
        />
      }
      isTableContent={false}
    >
      <MeetingLinks
        isTourStep={isTourStep}
        meetingLinksCardRef={meetingLinksCardRef}
        openEditMeetingLinkDrawer={openEditMeetingLinkDrawer}
        openDeleteMeetingLinkDrawer={openDeleteMeetingLinkModal}
      />
      {!isTourStep && (
        <>
          {isAdditionalDrawerOpened && <AdditionalAvailabilityDrawer onClose={closeAdditionalDrawer} />}
          {isAddMeetingLinkDrawerOpened && <MeetingLinkDrawer isNewMeeting={true} onClose={closeAddMeetingLinkModal} />}
          {isEditMeetingLinkDrawerOpened && <MeetingLinkDrawer onClose={closeEditMeetingLinkDrawer} />}
          {isDeleteMeetingLinkModalOpened && <DeleteMeetingLinkModal onClose={closeDeleteMeetingLinkModal} />}
        </>
      )}
    </PageLayout>
  ) : (
    <GoToDesktopModal />
  )
})

export default MeetingLinksPage
