import { Button, Checkbox, Form, Input, Row, Tag, Tooltip, Typography } from "antd"
import React, { FC } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { updateLimitTooltipText, warmUpTooltipText } from "../EmailSendingLimitsModal"
import "../EmailSendingLimitsModal/index.less"

type EmailSendingWarmUpLimitsProps = {
  emailData: any
  open: boolean
  onCancel: VoidFunction
}

export const EmailSendingWarmUpLimitsModal: FC<EmailSendingWarmUpLimitsProps> = ({ open, onCancel, emailData, ...props }) => {
  const [form] = Form.useForm()

  const closeModal = () => {
    onCancel()
    form.resetFields()
  }

  const handleFormSubmit = () => {
    console.log(" test")
    onCancel()
    form.resetFields()
  }

  return (
    <Modal
      customClassName={"sending-limits-modal"}
      open={open}
      title={"Email Sending Limits"}
      onCancel={closeModal}
      {...props}
    >
      <Tag>{emailData?.email}</Tag>
      <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
        <Row justify={"space-between"} align={"middle"} wrap={false} style={{marginBottom: 8}} >
          <Typography.Title level={4}>Daily Email Limit</Typography.Title>
          <Row align={"middle"}>
            <Button className={"link"}>Update Limit</Button>
            <Tooltip placement={"bottomRight"} title={updateLimitTooltipText}>
              <SvgIcon type={"tooltip"} style={{marginLeft: 11, cursor: "pointer"}}/>
            </Tooltip>
          </Row>
        </Row>
        <Form.Item name={"limit"} initialValue={100}>
          <Input />
        </Form.Item>
        <Form.Item name={"warmUp"} >
          <Checkbox className={"warm-up-checkbox"}>
            Warm Up Email Schedule <Tooltip placement={"bottomRight"} title={warmUpTooltipText}><SvgIcon type={"tooltip"} style={{marginLeft: 8}}/></Tooltip></Checkbox>
        </Form.Item>
      <ConfirmButtons leftButtonTitle={"Back"} rightButtonTitle={"Save"} onCancel={closeModal} />
      </Form>
    </Modal>
  )
}
