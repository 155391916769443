import { Button, Checkbox, Radio, Row, Select, Typography } from "antd"
import { FC, Fragment, useState } from "react"
import { ConfirmButtons, Modal, TableHeader } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { phonesAvailable } from "../../info"
import { CheckAddressModal } from "./CheckAddressModal"
import "./index.less"

export const AddNumberModal: FC<{
  open: boolean
  onCancel: VoidFunction
}> = ({ open, onCancel }) => {
  const [countrySelected, setCountrySelected] = useState("")
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<string | null>(null)
  const [isPhonesAvailable, setIsPhonesAvailable] = useState(true)
  const [openCheckAddressModal, setOpenCheckAddress] = useState(false)
  const countries = ["United States", "Ukraine", "United Arabian Emirates"]

  const closeModal = () => {
    setCountrySelected("")
    setSelectedPhoneNumber(null)
    onCancel()
  }

  const showCheckAddressModal = () => {
    setOpenCheckAddress(true)
  }
  const closeCheckAddressModal = () => {
    setOpenCheckAddress(false)
  }

  const handleCountryChange = (value: string) => {
    setCountrySelected(value)
  }

  const handleSubmit = () => {
    console.log("submit")
    onCancel()
  }

  const isButtonDisabled = !selectedPhoneNumber

  return (
    <Modal
      customClassName={"add-number-modal"}
      open={open}
      title={"Add Phone Number"}
      closeIcon={<SvgIcon type={"close"} />}
      onCancel={closeModal}
    >
      <Typography.Paragraph className={"block-title"}>Select the country and phone number to call</Typography.Paragraph>
      <Select
        placeholder={"Select Country"}
        suffixIcon={<SvgIcon type={"selectArrow"} />}
        onChange={handleCountryChange}
      >
        {countries.map((country, index) => (
          <Select.Option key={index} value={country}>
            {country}
          </Select.Option>
        ))}
      </Select>
      <Checkbox className={"checkbox-sms"}>Display numbers that can send SMS</Checkbox>
      {countrySelected !== "" && (
        <Fragment>
          <TableHeader searchPlaceholder={"Search Area Code..."} hasMaxWidth={false} />
          {isPhonesAvailable ? (
            <Radio.Group
              className={"phones-available-radio-group"}
              value={selectedPhoneNumber}
              onChange={e => setSelectedPhoneNumber(e.target.value)}
            >
              {phonesAvailable.map((phone, index) => (
                <Row key={index} justify={"space-between"}>
                  <Radio value={phone}>{phone}</Radio>
                  <Button className={"link check-address"} onClick={showCheckAddressModal}>
                    Check Address
                  </Button>
                </Row>
              ))}
            </Radio.Group>
          ) : (
            <Typography.Paragraph className={"empty-state"}>
              No Numbers Found. Try a Different Area Code or Check Your Input
            </Typography.Paragraph>
          )}
        </Fragment>
      )}
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Add Number"}
        onCancel={onCancel}
        handleSubmit={handleSubmit}
        disabled={isButtonDisabled}
      />
      <CheckAddressModal open={openCheckAddressModal} onCancel={closeCheckAddressModal} />
    </Modal>
  )
}
