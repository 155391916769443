import { Flex, Row, Tooltip, Typography } from "antd"
import React, { FC, useContext } from "react"
import { CampaignsContext } from "src/components/app/context/CampaignsContext"
import { Modal } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"

type SelectStepModalProps = {
  open: boolean
  onCancel: VoidFunction
  isPersonalizedAiUsed?: boolean
}

export const SelectStepModal: FC<SelectStepModalProps> = ({ open, onCancel, isPersonalizedAiUsed = false }) => {
  const { setSelectedStep, setIsStepsInCampaignPage } = useContext(CampaignsContext)

  const createCampaignItem = [
    {
      key: "Phone calls",
      icon: <SvgIcon type={"phoneCallSelect"} />,
      title: "Phone calls",
      subtitle: "Task is created to call prospect.",
      onClick: () => {
        console.log("Step added")
        onCancel()
      },
    },
    {
      key: "AI Personalized Email",
      icon: <SvgIcon className={isPersonalizedAiUsed ? "disabled" : undefined} type={"personalizedEmailSelect"} />,
      title: (
        <Row align={"middle"} style={{ gap: 4 }}>
          <Typography.Paragraph className={isPersonalizedAiUsed ? "item-title disabled" : undefined}>
            AI Personalized Email
          </Typography.Paragraph>
          <Tooltip
            title={"Personalized Ai emails can only be used once in a sequence."}
            placement={"bottomRight"}
            rootClassName={"tooltip"}
            color={"#ECFBFC"}
          >
            <SvgIcon className={"info-tooltip"} type={"tooltip"} />
          </Tooltip>
        </Row>
      ),
      subtitle: (
        <Typography.Paragraph className={isPersonalizedAiUsed ? "item-subtitle disabled" : "item-subtitle"}>
          Emails are delivered automatically.
        </Typography.Paragraph>
      ),
      onClick: () => {
        if (isPersonalizedAiUsed) return
        console.log("Step added")
        onCancel()
      },
    },
    {
      key: "Manual Emails",
      icon: <SvgIcon type={"emailSelect"} />,
      title: "Manual Emails",
      subtitle: "Task is created to edit and deliver email.",
      onClick: () => {
        console.log("Step added")
        onCancel()
      },
    },
    {
      key: "SMS",
      icon: <SvgIcon type={"smsSelect"} />,
      title: "SMS",
      subtitle: "Task is created to edit and deliver SMS.",
      onClick: () => {
        console.log("Step added")
        onCancel()
      },
    },
    {
      key: "Task",
      icon: <SvgIcon type={"taskSelect"} />,
      title: "Task",
      subtitle: "Create a task for the team.",
      onClick: () => {
        console.log("Step added")
        onCancel()
      },
    },
  ]

  const closeModal = () => {
    onCancel()
  }

  const isAiPersonalizedDisable = (item: any) => {
    if (!item) return false
    return isPersonalizedAiUsed && item === "AI Personalized Email"
  }

  return (
    <Modal width={530} centered open={open} title={"Select a Sequence step"} onCancel={closeModal}>
      <Flex vertical gap={10}>
        {createCampaignItem.map((item, index) => (
          <Flex
            key={`${index}-${item.title}`}
            className={`modal-item ${isAiPersonalizedDisable(item.key) ? "disabled-item" : undefined}`}
            align={"center"}
            gap={20}
            onClick={() => {
              item.onClick()
              setSelectedStep(item.key)
              setIsStepsInCampaignPage(true)
            }}
          >
            {item.icon}
            <Flex gap={3} vertical>
              <Typography.Paragraph className={"item-title"}>{item.title}</Typography.Paragraph>
              <Typography.Paragraph className={"item-subtitle"}>{item.subtitle}</Typography.Paragraph>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Modal>
  )
}
