import { MenuProps } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC } from "react"
import { TableHeader } from "src/components/ui/TableHeader"
import { SvgIcon } from "src/shared/icons"
import { App } from "src/types"
import { exportData } from "../../info"
import { Table } from "../../../../ui"

export const items: MenuProps["items"] = [
  {
    key: "1",
    label: "View details",
  },
  {
    key: "2",
    label: "Add member",
  },
  {
    key: "3",
    danger: true,
    label: "Delete",
  },
]

export const ExportsTable: FC = () => {
  const columns: ColumnsType<App.ExportEntity> = [
    {
      title: "Export Name",
      dataIndex: "name",
      key: "exportName",
    },
    {
      title: "Amount of Verified Prospects",
      dataIndex: "prospectsAmount",
      key: "prospectsAmount",
    },
    {
      title: "Download",
      render: () => (
        <SvgIcon
          type={"download"}
          onClick={() => {
            console.log("Download")
          }}
          style={{ cursor: "pointer" }}
        />
      ),
      className: "download-btn",
      key: "download",
    },
  ]

  return (
    <React.Fragment>
      <TableHeader searchPlaceholder={"Search Prospect data..."} />
      <Table
        rowClassName={"explore-row"}
        rowKey={"id"}
        columns={columns}
        dataSource={exportData}
      />
    </React.Fragment>
  )
}
