import { Button, Flex, List, Row, Typography } from "antd"
import { FC, useEffect, useState } from "react"
import { SignInWithButtons, StatusTag } from "src/components/ui"
import { SvgIcon } from "src/shared/icons"
import { ConnectedEmailsModal, EmailSendingLimitsModal, EmailSendingWarmUpLimitsModal } from "./ui"
import "./index.less"

export const EmailAddressesTab: FC<{ data: any }> = ({ data }) => {
  const [openModal, setOpenModal] = useState(false)
  const [openWarmUpModal, setOpenWarmUpModal] = useState(false)
  const [openConnectedEmailsModal, setOpenConnectedEmailsModal] = useState(false)
  const [selectedEmail, setSelectedEmail] = useState(null)
  const [emailsData, setEmailsData] = useState<any>()

  useEffect(() => {
    if(data) setEmailsData(data)
  }, [data, !openModal])

  const showModal = (emailData: any) => {
    setSelectedEmail(emailData)
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  const showConnectedEmailsModal = () => {
    setOpenConnectedEmailsModal(true)
  }

  const showWarmUpModal = (emailData: any) => {
    setSelectedEmail(emailData)
    setOpenWarmUpModal(true)
  }

  const closeWarmUpModal = () => {
    setOpenWarmUpModal(false)
  }

  const onCancel = () => {
    setOpenModal(false)
    setOpenWarmUpModal(false)
    setOpenConnectedEmailsModal(false)
    setSelectedEmail(null)
  }

  const handleDelete = (itemId: string) => {
    const newData = emailsData?.filter((item: any) => item.id !== itemId)
    setEmailsData(newData)
  }

  return (
    <div className={"emails-addresses-wrapper"}>
      <Row className={"email-details-title-row"} justify={"space-between"} align={"middle"}>
        <Typography.Paragraph className={"emails-addresses-title"}>Email Details</Typography.Paragraph>
        <Button className={"link"} onClick={showConnectedEmailsModal}>
          Manage
        </Button>
      </Row>
      <div className={"emails-addresses-content"}>
        {emailsData?.length ? (
          <List
            dataSource={emailsData}
            renderItem={(item: any) => (
              <List.Item key={item.id}>
                <Flex vertical gap={8}>
                  <Typography.Paragraph className={"email-title"}>{item.email}</Typography.Paragraph>
                  <Typography.Paragraph>
                    {item.warmUpMode ? (
                      <Button className={"link"} style={{ padding: 0 }} onClick={() => showWarmUpModal(item)}>
                        Warm Up Mode
                      </Button>
                    ) : (
                      <Button className={"link"} style={{ padding: 0 }} onClick={() => showModal(item)}>
                        100 Emails
                      </Button>
                    )}
                  </Typography.Paragraph>
                </Flex>
                <Row align={"middle"} wrap={false} style={{ gap: 8 }}>
                  <StatusTag
                    value={item.connectStatus ? "Connected" : "Disconnected"}
                    color={item.connectStatus ? "success" : "error"}
                  />
                  <SvgIcon type={"closeTag"} style={{ cursor: "pointer" }} onClick={() => handleDelete(item.id)} />
                </Row>
              </List.Item>
            )}
          />
        ) : (
          <Typography.Paragraph className={"emails-addresses-empty"}>
            No email is currently connected. Add an email below.
          </Typography.Paragraph>
        )}
      </div>
      <SignInWithButtons title={"Connect Email With"} />
      <EmailSendingWarmUpLimitsModal open={openWarmUpModal} onCancel={closeWarmUpModal} emailData={selectedEmail} />
      <EmailSendingLimitsModal open={openModal} onCancel={closeModal} emailData={selectedEmail} />
      <ConnectedEmailsModal open={openConnectedEmailsModal} onCancel={onCancel} data={data} showWarmUpModal={showWarmUpModal} showModal={showModal}/>
    </div>
  )
}
