import { Row } from "antd"
import Paragraph from "antd/lib/typography/Paragraph"
import { FC } from "react"
import "./index.less"

export const PhonesCell: FC<{ phonesData: string[] }> = ({ phonesData }) => {
  return (
    <Row style={{ flexDirection: "row", gap: 6 }} wrap={false}>
      {phonesData.map((phone, index) => {
        return (
          <Paragraph key={index} className={`phone-title-letter ${phone && "active"}`}>
            {index === 0 ? "C" : index === 1 ? "D" : "M"}
          </Paragraph>
        )
      })}
    </Row>
  )
}
