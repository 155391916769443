import { CheckOutlined, MinusOutlined, MoreOutlined } from "@ant-design/icons"
import { Dropdown, MenuProps, Space, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { FC, useState } from "react"
import { Table, TableHeader } from "src/components/ui"
import { App } from "src/types"
import { forStepTwoData } from "../../CreateCampaign/ui/AIGenerated/ui/StepTwo/stepTwoData"

const items: MenuProps["items"] = [
  {
    key: "1",
    label: "Add To Sequence",
  },
  {
    key: "2",
    label: "Take Off Of Sequence",
  },
]

export const ProspectsTable: FC<{
  handleDetailsModalOpen?: (id: App.ForStepTwoProspectEntity["id"]) => void
}> = ({ handleDetailsModalOpen }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const columns: ColumnsType<App.ForStepTwoProspectEntity> = [
    {
      title: "Seq.",
      dataIndex: "isSequence",
      key: "isSequence",
      render: record => (record ? <CheckOutlined /> : <MinusOutlined />),
    },
    {
      title: "Contacts",
      className: "clickable-table-title",
      key: "contacts",
      align: "right",
      render: record =>
        record.contacts ? (
          <Typography.Paragraph onClick={() => handleDetailsModalOpen && handleDetailsModalOpen(record.id)}>
            {record.contacts}
          </Typography.Paragraph>
        ) : (
          <MinusOutlined />
        ),
    },
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
      align: "right",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      align: "right",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Emailed Already",
      dataIndex: "emailed",
      key: "emailed",
      align: "right",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobTitle",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Upload Name",
      dataIndex: "uploadName",
      key: "uploadName",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Company Url",
      dataIndex: "companyUrl",
      key: "companyUrl",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "LinkedIn Url",
      dataIndex: "linkedin",
      key: "linkedin",
      render: record => (record ? record : <MinusOutlined />),
    },
    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Dropdown placement={"bottomRight"} menu={{ items: items }} trigger={["click"]}>
          <Space style={{ cursor: "pointer" }} onClick={e => e.preventDefault()}>
            <MoreOutlined />
          </Space>
        </Dropdown>
      ),
    },
  ]
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  return (
    <div>
      <TableHeader searchPlaceholder={"Search Prospect data..."} />
      <Table
        columns={columns}
        dataSource={forStepTwoData}
        rowSelection={{ ...rowSelection }}
        rowKey={"id"}
        // style={{ width: "100%" }}
      />
    </div>
  )
}
