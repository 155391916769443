import { ReactNode } from "react"
import NoData from "src/shared/images/noData.png"
import { Flex, Image, Typography } from "antd"
import "./index.less"

interface EmptyStateProps {
  text?: string
  className?: string
  children?: ReactNode
}

export const EmptyState: React.FC<EmptyStateProps> = ({ text, className, children, ...props }) => {
  return (
    <Flex
      vertical={true}
      justify={"center"}
      align={"center"}
      gap={16}
      className={className ? `empty-state-wrapper ${className}` : "empty-state-wrapper"}
      {...props}
    >
      <Image preview={false} src={NoData} alt={"No data"} width={173} height={150} />
      <Typography.Paragraph className={"description"}>{text || "No data"}</Typography.Paragraph>
      {children}
    </Flex>
  )
};