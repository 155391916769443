import React, { createContext, useState, Dispatch, SetStateAction, useEffect } from "react"

interface CommonContextProps {
  children: React.ReactNode;
}

export const CommonContext = createContext<{
  isTourOpened: boolean
  setIsTourOpened: Dispatch<SetStateAction<boolean>>
  currentStep: number
  setCurrentStep: Dispatch<SetStateAction<number>>
  currentView: string
  setCurrentView: Dispatch<SetStateAction<string>>
  currentViewEntity: any
  setCurrentViewEntity: Dispatch<SetStateAction<any>>
  isViewingProcess: boolean
  setIsViewingProcess: Dispatch<SetStateAction<boolean>>
}>({
  isTourOpened: false,
  setIsTourOpened: () => {},
  currentStep: 1,
  setCurrentStep: () => {},
  currentView: "Personal",
  setCurrentView: () => {},
  currentViewEntity: null,
  setCurrentViewEntity: () => {},
  isViewingProcess: false,
  setIsViewingProcess: () => {},
});

export const CommonContextProvider: React.FC<CommonContextProps> = ({ children }) => {
  const [isTourOpened, setIsTourOpened] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isViewingProcess, setIsViewingProcess] = useState(false);
  const [currentView, setCurrentView] = useState<string>("Personal");

  // Viewing person or team data
  const [currentViewEntity, setCurrentViewEntity] = useState(null);

  const contextValue = {
    isTourOpened,
    setIsTourOpened,
    currentStep,
    setCurrentStep,
    currentView,
    setCurrentView,
    currentViewEntity,
    setCurrentViewEntity,
    isViewingProcess,
    setIsViewingProcess,
  };

  return <CommonContext.Provider value={contextValue}>{children}</CommonContext.Provider>;
};
