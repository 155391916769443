import { Row } from "antd"
import React, { FC, useState } from "react"
import { CustomSelect } from "../../../ui"
import { DashboardCard } from "../ui/DashboardCard"
import { OverviewChart } from "./OverviewChart"
import { OverviewEmptyData } from "./OverviewEmptyData"
import "./index.less"

interface OverviewCardProps {
  data?: any
}

export const periodOptions = [
  {
    label: "Days",
    value: "Days",
  },
  {
    label: "Months",
    value: "Months",
  },
]

const overviewItemsOptions = [
  {
    label: "Emails",
    value: "Emails",
  },
  {
    label: "Phone and SMS",
    value: "Phone and SMS",
  },
]

export const OverviewCard: FC<OverviewCardProps> = ({ data }) => {
  const [selectedChartType, setSelectedChartType] = useState("Emails")

  const handleChange = (value: string) => {
    setSelectedChartType(value)
  }

  const headerRightContent = (
    <Row wrap={false} style={{ gap: 18 }}>
      <CustomSelect
        customClassName={"dashboard-select"}
        options={overviewItemsOptions}
        onChange={handleChange}
        defaultValue={"Emails"}
        popupClassName={"dashboard-select-dropdown"}
        style={{ width: 138, height: 26 }}
      />
      <CustomSelect
        options={periodOptions}
        defaultValue={"Days"}
        popupClassName={"dashboard-select-dropdown"}
        style={{ width: 100, height: 26 }}
      />
    </Row>
  )

  return (
    <DashboardCard customClassName={"overview-card-wrapper"} title={"Overview"} headerRightContent={headerRightContent}>
      {data ? <OverviewChart selectedChartType={selectedChartType} /> : <OverviewEmptyData />}
    </DashboardCard>
  )
}
