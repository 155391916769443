import { Col, Row } from "antd"
import { FC } from "react"
import { allActionsData, sequenceData } from "src/components/pages/campaigns/campaignsData"
import { ActionsForTodayCard, StepsSequence } from "src/components/pages/campaigns/ui"

export const NewCampaign: FC = () => {
  return (
    <Row gutter={[24, 0]}>
      <Col span={12}>
        <StepsSequence data={sequenceData} />
      </Col>
      <Col span={12}>
        <ActionsForTodayCard data={allActionsData} />
      </Col>
    </Row>
  )
}
