import React, { FC } from "react"
import { ConfirmButtons, Modal } from "../../../../ui"
import { Col, Flex, Row } from "antd"
import { Link } from "react-router-dom"
import { useBreakpoints } from "../../../../../helpers/useBreakpoints"
import "./index.less"

interface PaymentMethodModalProps {
  onClose: () => void
}

export const PaymentMethodModal: FC<PaymentMethodModalProps> = ({ onClose }) => {
  const { isDesktop } = useBreakpoints()

  const handleUpdateClick = () => {
    onClose()
  }

  return isDesktop ? (
    <Modal rootClassName={"payment-method-modal"} title={"Update Invoice - Meetz"} open={true} onCancel={onClose}>
      <ConfirmButtons
        leftButtonTitle={"Back"}
        rightButtonTitle={"Update Card"}
        handleSubmit={handleUpdateClick}
        onCancel={onClose}
        style={{ marginTop: "auto" }}
      />
    </Modal>
  ) : (
    <Flex className={"payment-method-page-wrapper"} vertical={true}>
      <Row className={"mobile-gradient1"} />
      <Row className={"mobile-gradient2"} />
      <Col className={"box"} style={{ padding: "32px 18px", minHeight: 616 }}>

      </Col>
      <Link to={"/"} className={"meetz-link"}>Meetz.ai</Link>
    </Flex>
  )
}
