import React, { FC } from "react"
import { CustomSelect } from "src/components/ui"
import { periodOptions } from "../OverviewCard"
import { DashboardCard } from "../ui/DashboardCard"
import { AiInitiatedEmailChart, AiInitiatedEmailEmptyData, AiInitiatedEmailTable } from "./ui"
import "./index.less"

interface AiInitiatedEmailCardProps {
  data?: any
}

export const AiInitiatedEmailCard: FC<AiInitiatedEmailCardProps> = ({ data }) => {
  const headerRightContent = (
    <CustomSelect
      options={periodOptions}
      defaultValue={"Days"}
      popupClassName={"dashboard-select-dropdown"}
      style={{ width: 100, height: 26 }}
    />
  )

  return (
    <DashboardCard
      customClassName={"ai-initiated-card-wrapper"}
      title={"AI-Initiated Email Scheduling"}
      headerRightContent={headerRightContent}
    >
      {data ? (
        <>
          <AiInitiatedEmailChart />
          <AiInitiatedEmailTable />
        </>
      ) : (
        <AiInitiatedEmailEmptyData />
      )}
    </DashboardCard>
  )
}
