import type { GetProp } from "antd"
import { Button, Checkbox, Flex, Row, Select, Typography } from "antd"
import TextArea from "antd/es/input/TextArea"
import React,{ FC,useState } from "react"
import { Modal } from "src/components/ui"
import './index.less'


const { Text } = Typography

interface CsvData {
  title: string
  info: string[]
}

type ExtractionFileModalProps = {
  open: boolean
  onCancel: VoidFunction
  csvData?: CsvData[]
}

const options = [
  {
    value: 'Email',
    label: 'Email',
  },
  {
    value: 'First Name',
    label: 'First Name',
  },
  {
    value: 'Last Name',
    label: 'Last Name',
  },
  {
    value: 'Position',
    label: 'Position',
  },
  {
    value: 'Leads LinkedIn URL',
    label: 'Leads LinkedIn URL',
  },
  {
    value: 'Leads Company Name',
    label: 'Leads Company Name',
  },
  {
    value: 'Leads Company\'s Main URL',
    label: 'Leads Company\'s Main URL',
  }
]

export const UploadingFormModal: FC<ExtractionFileModalProps> = ({ open, onCancel, csvData }) => {
  const [openUploadFormModal, setOpenUploadFormModal] = useState(false)

  const closeUploadFormModal = () => {
    setOpenUploadFormModal(false)
  }



  const onChange: GetProp<typeof Checkbox.Group, "onChange"> = checkedValues => {
    console.log("checked = ", checkedValues)
  }

  return (
    <Modal width={790} centered open={open} title={"Upload a CSV for multiple leads"} onCancel={onCancel}>
     <Flex gap={10} vertical className={'form-container'}>
       <Flex justify={"space-between"} align={"center"} className={'form-item column-head'}>
         <Text className={'item-title'}>Column header from file</Text>
         <Text className={'item-info'}>Preview Information</Text>
         <Text className={'item-select'}>Property</Text>
       </Flex>
        {csvData?.map((item, index) =>
          <Flex justify={"space-between"} align={"center"} className={'form-item'} key={index}>
            <Flex className={'item-title'}>{item.title}</Flex>
            <TextArea variant={'filled'} className={'item-info'} value={item.info.map(info => info.length > 35 ? info.slice(0, 35) + '...' : info).join('\n')} />
            <Select allowClear placeholder={'Choose Property'} className={'item-select'} options={options}/>
          </Flex>)}
     </Flex>
      <Row justify={"space-between"} align={"middle"}>
        <Button className={"link"} style={{width: 211}} onClick={onCancel}>Back</Button>
        <Button className={"primary"} style={{width: 211}} onClick={onCancel}>Upload</Button>
      </Row>
    </Modal>
  )
}
