import React, { FC, ReactNode, useState } from "react"
import { StepsSequence } from "src/components/pages/campaigns/ui/StepsSequence"
import { sequenceData } from "src/components/pages/campaigns/campaignsData"
import { Col, Form, Row } from "antd"
import {
  AiPersonalizedEmail,
  ManualEmails,
  PhoneCall,
  Sms,
  Task,
} from "../../../AIGenerated/ui/StepThree/ui"

type Mode = {
  key: string
  component: ReactNode
}

    export const StepsInCampaign: FC<{selectedStep: string}> = ({ selectedStep }) => {
  const [mode, setMode] = useState<string>("")
  const [form] = Form.useForm()

  const modes: Mode[] = [
    { key: "manual", component: <ManualEmails /> },
    { key: "ai", component: <AiPersonalizedEmail/> },
    { key: "task", component: <Task/> },
    { key: "sms", component: <Sms/> },
    { key: "phone", component: <PhoneCall/> },
  ]

const selectedStepObject=sequenceData.find(item => item.mode===selectedStep)
  const currentMode = modes.find(item => item.key === selectedStepObject?.mode.split(" ")[0].toLowerCase())
  const selectedStepData = [selectedStepObject]
      console.log("selectedStepData", selectedStepData)
  return (
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
      <Row gutter={[24, 0]}>
        <Col span={12}>
          <StepsSequence data={selectedStepData} setMode={setMode} />
        </Col>
        <Col span={12}>{currentMode?.component}</Col>
      </Row>
    </Form>
  )
}
