import { MenuProps, Row, Typography, Tooltip, Flex } from "antd"
import React, { FC, useState } from "react";
import { SvgIcon } from "src/shared/icons";
import { DotsMenuDropdown } from "../DotsMenuDropdown"

interface PageTitleProps {
  title: string;
  onClick?: VoidFunction;
  hasAction?: boolean;
  status?: string;
}

export const PageTitle: FC<PageTitleProps> = ({ title, onClick, hasAction = false, status }) => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: "Resume campaign",
    },
    {
      key: "2",
      label: "Rename campaign",
    },
    {
      key: "3",
      label: "Turn on autobooking",
    },
  ];

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleBadgeClick = () => {
    setTooltipVisible(!tooltipVisible);
  };

  return (
    <Row align={"middle"} style={{ gap: 16 }}>
      {onClick ? <SvgIcon type={"arrowBack"} onClick={onClick} style={{ cursor: "pointer" }} /> : null}
      {status ? (
        <Flex align={"center"} gap={8}>
          <Tooltip title={status === "ACTIVE" ? "Active Campaign" : "Paused Campaign "} placement={"bottom"}>
            <Row
              style={{ width: 14, height: 14, borderRadius: "50%", cursor: "pointer", backgroundColor: status === "ACTIVE" ? "#0FCA7A" : "#FAAD14" }}
              onClick={handleBadgeClick}
            />
          </Tooltip>
          <Typography.Title level={2} className={"page-title"} style={{ marginTop: 0 }}>
            {title}
          </Typography.Title>
        </Flex>
      ) : (
        <Typography.Title level={2} className={"page-title"}>
          {title}
        </Typography.Title>
      )}
      {hasAction ? (
        <DotsMenuDropdown menuItems={items} />
      ) : null}
    </Row>
  );
};
