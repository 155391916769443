import React, { useState } from "react"
import EmptyTable from "src/shared/images/manWithDashboard.webp"
import { Flex, Image, Typography } from "antd"
import { ConfirmButtons } from "../../../ui"
import { CreateCampaignsModal } from "../ui"
import "./index.less"

export const CampaignsEmptyTable = () => {
  const [openModal, setOpenModal] = useState(false)

  const showModal = () => {
    setOpenModal(true)
  }

  const closeModal = () => {
    setOpenModal(false)
  }

  return (
    <>
      <Flex className={"campaign-empty-table-wrapper"} vertical={true} justify={"center"} align={"center"}>
        <Flex className={"campaigns-empty-table-content"} vertical={true} align={"center"} gap={16}>
          <Image src={EmptyTable} alt={"Man with a dashboard"} preview={false} width={173} height={150} />
          <Typography.Paragraph className={"description"}>You have no campaigns created. Create one,
            and the data will appear here.</Typography.Paragraph>
          <ConfirmButtons rightButtonTitle={"Create Campaign"} style={{ width: "fit-content" }} handleSubmit={showModal} />
        </Flex>
      </Flex>
      {openModal && <CreateCampaignsModal onCancel={closeModal} open={openModal} />}
    </>
  )
}