import { Form, Input} from "antd"
import React, { FC } from "react"
import { ConfirmButtons, Modal } from "src/components/ui"
import TextArea from "antd/es/input/TextArea"

type SaveValueModalProps = {
  isEdit?: boolean
  open: boolean
  onCancel: VoidFunction
}


export const SaveValueModal: FC<SaveValueModalProps> = ({ open, onCancel, isEdit }) => {
  const [form] = Form.useForm()

  const closeModal = () => {
    onCancel()
  }

  const handleFormSubmit = () => {
    console.log("Saved")
    onCancel()
  }

  return <Modal width={530} open={open} title={isEdit? "Edit Saved Value Proposition " : "Save Value Proposition"} onCancel={closeModal}>
    <Form form={form} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} onFinish={handleFormSubmit}>
      <Form.Item
        name={"name"}
        label={"Name"}
        initialValue={isEdit ? "Finance and C-level" : null }
      >
        <Input placeholder={"Finance and C-level"} />
      </Form.Item>
      <Form.Item  name={"body"} label={"Body"} initialValue={isEdit ? "A design system for enterprise-level products. Create an efficient and enjoyable work experience." : null }>
     <TextArea placeholder={"A design system for enterprise-level products. Create an efficient and enjoyable work experience."}/>
      </Form.Item>
    </Form>
    <ConfirmButtons rightButtonTitle={"Save"} leftButtonTitle={"Back"} onCancel={closeModal} handleSubmit={handleFormSubmit}/>
  </Modal>
}
