import { Flex, Typography } from "antd"
import React, { FC } from "react"
import { cloud } from "src/shared/images"

export const ActivitiesEmptyData: FC = () => {
  return (
    <Flex vertical align={"center"} gap={18} style={{ padding: "63px 0 46px" }}>
      <img src={cloud} alt={"woman with phone"} width={149} />
      <Typography.Paragraph className={"dashboard-empty-text"} style={{ maxWidth: 150 }}>
        Start a new activity to start filling this area!
      </Typography.Paragraph>
    </Flex>
  )
}
