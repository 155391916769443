import { Flex, Row, Typography } from "antd"
import React, { FC } from "react"
import { useNavigate } from "react-router"
import { ConfirmButtons, Modal } from "src/components/ui"
import "./index.less"

type ActivateForAccessModalProps = {
  open: boolean
  onCancel: VoidFunction
}

export const ActivateForAccessModal: FC<ActivateForAccessModalProps> = ({ open, onCancel }) => {
  const navigate = useNavigate()
  const closeModal = () => {
    onCancel()
  }

  return (
    <Modal
      customClassName={"activate-access-wrapper"}
      open={open}
      width={663}
      onCancel={closeModal}
      hasCloseIcon={false}
    >
      <Row className={"background-content-wrapper"}></Row>
      <Flex className={"info-wrapper"} vertical gap={16}>
        <Typography.Paragraph className={"info-title"}>Activate Your Account for Access!</Typography.Paragraph>
        <Typography.Paragraph className={"info-subtitle"}>
          Unlock our 165+ Million real-time verified contact database by activating your account.
        </Typography.Paragraph>
        <ConfirmButtons
          leftButtonTitle={"Remind me next time"}
          rightButtonTitle={"Update Plan"}
          onCancel={closeModal}
          handleSubmit={() => navigate("/billing")}
        />
      </Flex>
    </Modal>
  )
}
