import { Form, FormInstance, FormItemProps } from "antd"
import React, { FC, useContext } from "react"
import { ProspectDataContext } from "src/components/app/context/ProspectDataContext"
import { prospectData } from "src/components/pages/prospect-data/info"
import { CustomSelect, FilterTagsList } from "src/components/ui"

interface Option {
  key: string
  label: string
  value: string
}

interface SelectFilterItem extends FormItemProps {
  data: string[]
  form: FormInstance
  fieldName: string
  placeholder?: string
  staticOptions?: Option[]
}

const options = (fieldName: string): Option[] => {
  const uniqueValues = new Set<string>()

  prospectData.forEach((item: any) => {
    const fieldValue = item[fieldName]
    if (Array.isArray(fieldValue)) {
      fieldValue.forEach(value => uniqueValues.add(value))
    } else {
      uniqueValues.add(fieldValue)
    }
  })

  return Array.from(uniqueValues).map(value => ({
    key: value,
    label: value,
    value: value,
  }))
}

export const SelectFilterItem: FC<SelectFilterItem> = ({ placeholder, data, form, fieldName, staticOptions }) => {
  const { filterFormData, setFilterFormData } = useContext(ProspectDataContext)

  const onChange = (value: any, field: string) => {
    setFilterFormData({ ...filterFormData, [field]: value })
  }

  return (
    <>
      <Form.Item name={fieldName}>
        <CustomSelect
          placeholder={placeholder}
          options={staticOptions ?? options(fieldName)}
          placement={"bottomLeft"}
          tagRender={() => <></>}
          onChange={value => onChange(value, fieldName)}
          showSearch
          isSearchSelect
          mode={"multiple"}
        />
      </Form.Item>
      <FilterTagsList data={data} form={form} fieldToUpdate={fieldName} />
    </>
  )
}
